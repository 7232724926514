import moment from "moment"
export const parseDate = value =>
  value
    ? moment(value)
        .locale("nb-NO")
        .format("YYYY-MM-DD") //Format to be sent to API
    : null

export const parseDateToDatePickerFormat = (value: string) =>
  moment(value)
    .locale("nb-NO")
    .format("YYYY-MM-DD")
