import axios from "axios";
import config from "../config"
import { norkartIdAuth } from "../utils/auth";

const PROFILE = config.planregister.profile;

export const planpratBaseURL = 'https://planprat.api.nkdev.no';

export const planpratAxiosInstance = axios.create({
    baseURL: planpratBaseURL,
    headers: {
        'Content-Type' : 'application/json',
        'X-WAAPI-PROFILE': PROFILE,
    }
});

export const postChat = async (data: any, id: string) => {
    const token = await norkartIdAuth.getToken();

    const requestData = {message: data.message, chat_history: data.chatHistoryFormatted, plans: data.plansFormatted, include_references: data.includeReferences}

    return await planpratAxiosInstance.post(
        planpratBaseURL + '/chat',
        requestData,
        {
            headers: {
                'X-WAAPI-CONTEXT': id,
                Authorization: `Bearer ${token}`,
            }
        },
    )

}