import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography
} from "@mui/material"
import { NkmMapboxMap } from "@norkart/nkm-mapbox-map"
import React, { useEffect } from "react"
import config from "../../config"

type Props = {
  onLocationConfirmed: (coords) => void
  open: boolean
  calculatedPosition: { lat: number; lng: number }
  onCloseDialog: () => void
}

const DialogWrapper = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "20px",
    maxWidth: "80vw",
    width: "80vw",
    height: "90vh"
  }
})

const MapDialogMyPosition = ({
  open,
  calculatedPosition,
  onCloseDialog,
  onLocationConfirmed
}: Props) => {
  const [position, setPosition] = React.useState(calculatedPosition)
  useEffect(() => {
    setPosition(calculatedPosition)
  }, [open])

  const onDragEnd = (coords: { lat: number; lng: number }) => {
    setPosition(coords)
  }
  return (
    <div>
      <DialogWrapper fullWidth={true} open={open}>
        <DialogTitle style={{ textAlign: "center" }}>
          <Typography variant="h2">Er posisjonen din riktig?</Typography>
          <Typography variant="body1">
            Dra i markøren for å endre din posisjon
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "80vw",
              height: "80vh",
              "& .nkm-mapbox-map, .mapboxgl-map": {
                width: "100%"
              }
            }}>
            {/* TODO: Må bytte vekk fra hardkodet accesstoken */}
            <NkmMapboxMap
              accessToken={
                "pk.eyJ1Ijoia2Vpbm8iLCJhIjoiOE5oc094SSJ9.DHxjhFy2Ef33iP8yqIm5cA"
              }
              styleUrl={config.mapbox.styleUrl}
              centerCoords={calculatedPosition}
              zoomLevel={16}
              useNorkartAttribution={true}
              navigationControls={{ show: true, options: "bottom-left" }}
              marker={{
                coords: position,
                draggable: true,
                onDragEnd: onDragEnd
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20
          }}>
          <Button
            onClick={onCloseDialog}
            variant="contained"
            color="grey"
            style={{ marginLeft: 10, marginRight: 10, width: 200 }}>
            Avbryt
          </Button>
          <Button
            onClick={() => onLocationConfirmed(position)}
            variant="contained"
            color="secondary"
            style={{ marginLeft: 10, marginRight: 10, width: 200 }}>
            OK
          </Button>
        </DialogActions>
      </DialogWrapper>
    </div>
  )
}
export default MapDialogMyPosition
