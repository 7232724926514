import React from "react"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import useCookieConsent from "./hooks/cookies/useCookieConsent"
import config from "./config"
import { useNavigate } from "react-router-dom"
import { createBrowserHistory } from "history"

function ApplicationInsightsInitializer(
  userId: string | undefined,
  kundeId: string | undefined
) {
  const history = createBrowserHistory()
  const cookieConsent = useCookieConsent()

  var reactPlugin = new ReactPlugin()
  var appInsights = new ApplicationInsights({
    config: {
      connectionString: config.applicationInsightsConnectionString,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history }
      },
      disableFetchTracking: true,
      disableAjaxTracking: true,
      namePrefix: "Arealplaner",
      enableAutoRouteTracking: false,
      disableCookiesUsage: cookieConsent.statistic ? false : true,
      disableTelemetry: cookieConsent.statistic ? false : true
    }
  })
  appInsights.loadAppInsights()
  appInsights.appInsights.addTelemetryInitializer(envelope => {
    if (envelope.baseType === "EventData" && envelope.data) {
      if (Object.keys(envelope.data).length === 0) return false
      if (Object.prototype.hasOwnProperty.call(envelope.data, "button") && !envelope.data?.button) {
        return false
      }
      if (Object.prototype.hasOwnProperty.call(envelope.data, "event") && !envelope.data?.event) {
        return false
      }
    }
    if (envelope && envelope.data && envelope.baseType !== "EventData") {
      envelope.data.vendorname = (window as any).location
      envelope.data.referrer = document.referrer
    }
  })

  appInsights.setAuthenticatedUserContext(
    userId ? userId : "",
    kundeId ? kundeId : "",
    cookieConsent.statistic ? true : false
  )

  return reactPlugin
}

export default ApplicationInsightsInitializer
