import * as React from "react"
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box
} from "@mui/material"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { hideDialog } from "../../../store/dialog/actions"
import CloseIcon from "@mui/icons-material/Close"
import { ConnectedReduxProps } from "../../../store"
import useUpdateArealplan from "../../../hooks/arealplaner/useUpdateArealplan"
import { useState } from "react"
import Editor from "../../../components/TextEditor/Editor"

export interface PlanDescriptionDialogProps extends ConnectedReduxProps {
  plan: Arealplan
  open: boolean
}

const PlanDescriptionDialog = ({
  plan,
  open,
  dispatch
}: PlanDescriptionDialogProps) => {
  const [description, setDescription] = useState<string>(
    plan.planBeskrivelse ?? ""
  )
  const { mutateAsync: updateArealplan } = useUpdateArealplan()

  const handleEditorState = (newEditorHTML: string) => {
    if (newEditorHTML === '<p><br></p>') {
      setDescription('');
    } else {
      setDescription(newEditorHTML);
    }
  };

  const onCancel = () => {
    hideDialog(dispatch)
    setDescription(plan.planBeskrivelse ?? "")
  }

  const onSave = async () => {
    hideDialog(dispatch)
    const data = {
      ...plan,
      planBeskrivelse: description
    } as Arealplan
    const res = await updateArealplan(data)
  }

  return (
    <Dialog
      open={open}
      disableScrollLock
      maxWidth={"md"}
      PaperProps={{
        style: {
          backgroundColor: "#F6F6F6",
          boxShadow: "none"
        }
      }}>
      <DialogTitle id="form-dialog-title">
        <Grid
          container={true}
          justifyContent="space-between"
          spacing={8}
          alignItems="center">
          <Grid item={true}>Beskrivelse av planen</Grid>
          <Grid item={true}>
            <IconButton onClick={onCancel}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          display={"flex"}
          flexDirection={"column"}
          pb={1}>
          <Box sx={{
            minHeight: 300,
            height: 300,
            maxHeight: 1000,
            minWidth: 400,
            width: 400,
            resize: "both",
            overflow: "auto"
          }}>
            <Editor
              initialEditorContent={description ? description : ''}
              toolbarOptions={['bold', 'italic', 'list', 'undo', 'redo', 'link']}
              currentEditorState={handleEditorState}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={3}>
            <Button variant="contained" color="secondary" onClick={onSave}>
              Lagre
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              Avbryt
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PlanDescriptionDialog
