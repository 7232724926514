import * as React from "react"
import LoadingSpinner from "../../../components/LoadingSpinner"
import CustomerSelectorItem from "./CustomerSelectorItem"
import { Typography, MenuList, styled } from "@mui/material"
import useKunder from "../../../hooks/kunder/useKunder"

function CustomerSelector() {
  const [searchTerm, setSearchTerm] = React.useState<string>("")
  const { data: customers, isLoading, isFetched } = useKunder({searchTerm: searchTerm})

  const SearchInput = styled("input")(({ theme }) => ({
    height: "58px",
    width: "350px",
    padding: "18.5px 14px",
    marginBottom: "10px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    "&:focus-visible": {
      borderColor: theme.palette.secondary.main,
      borderWidth: "2px",
      borderStyle: "solid",
      outline: "none"
    }
  }))

  const handleSearch = e => {
    const searchTerm = e.target.value
    setSearchTerm(searchTerm)
  }

  const handleKeyDown = e => {
    if (e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 9)
      e.stopPropagation()
  }

  const renderCustomerList = () =>
    customers.map(customer => (
      <CustomerSelectorItem key={customer.id} customer={customer} />
    ))

  if (isLoading || !isFetched) return <LoadingSpinner />
  return (
    <div
      style={{
        width: "350px"
      }}>
      <MenuList>
        <SearchInput
          // eslint-disable-next-line
          autoFocus={true}
          onKeyDown={handleKeyDown}
          type="seach"
          placeholder="Søk etter kommune..."
          value={searchTerm}
          onChange={handleSearch}
        />
        {customers.length > 0 ? (
          renderCustomerList()
        ) : (
          <Typography>Ingen treff på søk</Typography>
        )}
      </MenuList>
    </div>
  )
}

export default CustomerSelector
