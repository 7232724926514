import { Autocomplete, Grid } from "@mui/material"
import * as React from "react"
import DateRangePickerMui, {
  StartOrEndDate
} from "../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui"
import { ToiLikeStyledTextField } from "../../../../components/TextField/ToiLikeStyledTextField"
import {
  Behandlingstyper,
  useKodelisterBehandlingstyper
} from "../../../../hooks/kodelister/useKodelisterBehandlingstyper"
import { Planstatus, Plantype } from "../../../../hooks/meta/types"
import { StyledMuiTextFieldSearch } from "../ReportTools/StyledComponents/StyledMuiTextFieldSearch"
import useMeta from "../../../../hooks/meta/useMeta"
import { BehandlingReportFilters } from "../../../../hooks/behandlinger/useBehandlingerReport"
import { format } from "date-fns"

type BehandlingerFilterProps = {
  filterValues?: BehandlingReportFilters
  setFilterValues: React.Dispatch<BehandlingReportFilters>
  clearFilter: boolean
}

type SelectedFiltersProps = {
  behandlingstype?: Behandlingstyper[]
  planstatus?: Planstatus[]
  plantype?: Plantype[]
  behandlingBeskrivelse?: string
  fraDatoStart?: Date
  fraDatoEnd?: Date
  tilDatoStart?: Date
  tilDatoEnd?: Date
}

export function BehandlingerFilter({
  filterValues,
  setFilterValues,
  clearFilter
}: BehandlingerFilterProps) {
  const [selectedFilters, setSelectedFilters] = React.useState<
    SelectedFiltersProps | undefined
  >()

  const {
    data: { plantyper, planstatuser }
  } = useMeta()
  const behandlingstyper = useKodelisterBehandlingstyper()

  const handleDatoChange = (
    dates?: StartOrEndDate,
    fromOrTo?: "from" | "to"
  ) => {
    if (fromOrTo === "from") {
      setSelectedFilters({
        ...selectedFilters,
        fraDatoEnd: dates?.end,
        fraDatoStart: dates?.start
      })
    }
    if (fromOrTo === "to") {
      setSelectedFilters({
        ...selectedFilters,
        tilDatoEnd: dates?.end,
        tilDatoStart: dates?.start
      })
    }
  }

  //Cleare beskrivelse feltet
  const clearSearch = () => {
    setSelectedFilters({ ...selectedFilters, behandlingBeskrivelse: "" })
  }

  React.useEffect(() => {
    if (clearFilter) setSelectedFilters(undefined)
  }, [clearFilter])

  const handleChange = (e, newValue) => {
    setSelectedFilters({
      ...selectedFilters,
      behandlingstype: newValue
    })
  }

  React.useEffect(() => {
    console.log(selectedFilters)
    let newFilterValues = {
      behandlingstypeId: selectedFilters?.behandlingstype?.length
        ? selectedFilters?.behandlingstype?.flatMap(b => b.id).join(",")
        : null,
      plantypeId: selectedFilters?.plantype?.length
        ? selectedFilters?.plantype?.flatMap(b => b.id).join(",")
        : null,
      planstatusId: selectedFilters?.planstatus?.length
        ? selectedFilters?.planstatus?.flatMap(b => b.id).join(",")
        : null,
      beskrivelse: selectedFilters?.behandlingBeskrivelse,
      fraDatoStart:
        selectedFilters?.fraDatoStart &&
        format(selectedFilters?.fraDatoStart, "yyyy-MM-dd"),
      fraDatoEnd:
        selectedFilters?.fraDatoEnd &&
        format(selectedFilters?.fraDatoEnd, "yyyy-MM-dd"),
      tilDatoStart:
        selectedFilters?.tilDatoStart &&
        format(selectedFilters?.tilDatoStart, "yyyy-MM-dd"),
      tilDatoEnd:
        selectedFilters?.tilDatoEnd &&
        format(selectedFilters?.tilDatoEnd, "yyyy-MM-dd")
    } as BehandlingReportFilters
    setFilterValues(newFilterValues)
  }, [selectedFilters])

  return (
    <div style={{ marginTop: 10 }}>
      <Grid direction="row" container={true}>
        <Grid item={true}>
          <Autocomplete
            size="small"
            options={behandlingstyper.data}
            sx={{
              width: 400
            }}
            value={
              selectedFilters?.behandlingstype
                ? selectedFilters.behandlingstype
                : []
            }
            ChipProps={{
              color: "primary",
              variant: "outlined",
              sx: { borderRadius: 1 }
            }}
            componentsProps={{
              popper: {
                color: "secondary",
                sx: { paddingTop: 5, boxShadow: 3 }
              }
            }}
            fullWidth={false}
            limitTags={1}
            color="secondary"
            multiple={true}
            getOptionLabel={option => `${option.id} - ${option?.beskrivelse}`}
            onChange={handleChange}
            renderInput={params => (
              <ToiLikeStyledTextField
                {...params}
                size="small"
                label="Behandlingstype"
                variant="outlined"
                color="secondary"
              />
            )}
          />
          <Autocomplete
            size="small"
            options={planstatuser ? planstatuser : []}
            sx={{
              width: 400
            }}
            value={
              selectedFilters?.planstatus ? selectedFilters.planstatus : []
            }
            ChipProps={{
              color: "primary",
              variant: "outlined",
              sx: { borderRadius: 1 }
            }}
            componentsProps={{
              popper: {
                color: "secondary",
                sx: { paddingTop: 5, boxShadow: 3 }
              }
            }}
            limitTags={1}
            color="secondary"
            multiple={true}
            getOptionLabel={option => `${option.id} - ${option?.beskrivelse}`}
            onChange={(event, newValue) =>
              setSelectedFilters({
                ...selectedFilters,
                planstatus: newValue
              })
            }
            renderInput={params => (
              <ToiLikeStyledTextField
                {...params}
                size="small"
                label="Planstatus"
                variant="outlined"
                color="secondary"
              />
            )}
          />
          <Autocomplete
            size="small"
            options={plantyper ? plantyper : []}
            sx={{
              width: 400
            }}
            limitTags={1}
            ChipProps={{
              color: "primary",
              variant: "outlined",
              sx: { borderRadius: 1 }
            }}
            componentsProps={{
              popper: {
                color: "secondary",
                sx: { paddingTop: 5, boxShadow: 3 }
              }
            }}
            value={selectedFilters?.plantype ? selectedFilters.plantype : []}
            color="secondary"
            multiple={true}
            fullWidth={true}
            getOptionLabel={option => `${option.id} - ${option?.beskrivelse}`}
            onChange={(event, newValue) =>
              setSelectedFilters({
                ...selectedFilters,
                plantype: newValue
              })
            }
            renderInput={params => (
              <ToiLikeStyledTextField
                {...params}
                size="small"
                fullWidth={true}
                label="Plantype"
                variant="outlined"
                color="secondary"
              />
            )}
          />
        </Grid>
        <Grid item={true} sx={{ paddingLeft: 5 }}>
          <StyledMuiTextFieldSearch
            color="secondary"
            fullWidth={true}
            sx={{ paddingLeft: 3, width: 400 }}
            variant="outlined"
            label="Beskrivelse"
            size="small"
            handleClearSearch={() => clearSearch()}
            active={selectedFilters?.behandlingBeskrivelse ? true : false}
            onChange={e =>
              setSelectedFilters({
                ...selectedFilters,
                behandlingBeskrivelse: e.target.value
              })
            }
            onKeyUp={e => {
              if (e.key === "Escape")
                setSelectedFilters({
                  ...selectedFilters,
                  behandlingBeskrivelse: ""
                })
            }}
            value={
              selectedFilters?.behandlingBeskrivelse
                ? selectedFilters.behandlingBeskrivelse
                : ""
            }
          />
          <div style={{ paddingLeft: 6 }}>
            <DateRangePickerMui
              onChange={d => {
                handleDatoChange(d, "from")
              }}
              label="Fra dato"
              startDate={selectedFilters?.fraDatoStart}
              endDate={selectedFilters?.fraDatoEnd}
            />
          </div>
          <div style={{ paddingLeft: 6 }}>
            <DateRangePickerMui
              onChange={d => {
                handleDatoChange(d, "to")
              }}
              label="Til dato"
              startDate={selectedFilters?.tilDatoStart}
              endDate={selectedFilters?.tilDatoEnd}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default BehandlingerFilter
