import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import * as React from "react"
import Main from "./main"
import * as serviceWorker from "./registerServiceWorker"
import { createRoot } from "react-dom/client"

declare global {
  const CookieInformation: {
    getConsentGivenFor: (params: string) => boolean
  }
  const CookieConsent: {
    renew: () => any
    dialog: {
      submitConsent: () => any
    }
  }
}

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(<Main />)

serviceWorker.unregister()
