import * as React from "react"
import { Field } from "react-final-form"
import Error from "./Error"

export interface FormErrorProps {
  name: string
}

export const FormError: React.FC<FormErrorProps> = ({
  name
}: FormErrorProps) => (
  <Field
    name={name}
    subscription={{
      touched: true,
      error: true,
      submitError: true,
      dirtySinceLastSubmit: true
    }}
    render={({ meta: { touched, error, submitError, dirtySinceLastSubmit } }) =>
      touched && !dirtySinceLastSubmit && (error || submitError) ? (
        <Error error={error || submitError} />
      ) : null
    }
  />
)

export default FormError
