import { useMutation, useQueryClient } from "react-query"
import { deleteDispensasjon } from "../../services/api"
import useSelectedArealplanId from "../arealplaner/useSelectedArealplanId"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"

export default function useDeleteDispensasjon() {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()
  const arealplanId = useSelectedArealplanId()
  const { notify } = useErrorHandler()

  return useMutation((id: number) => deleteDispensasjon(id), {
    onError: notify,
    onSuccess: () =>
      queryClient.invalidateQueries([
        "dispensasjoner",
        { kundeId, arealplanId }
      ])
  })
}
