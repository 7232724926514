import { useQuery } from "react-query"
import { fetchArealplanBehandlinger } from "../../services/api"
import { ArealplanId } from "../arealplaner/types"
import { Behandling } from "./types"
import useSelectedArealplanId from "../arealplaner/useSelectedArealplanId"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { useAuth } from "../../utils/auth"

const getBehandlinger = async (arealplanId: ArealplanId) => {
  const { data } = await fetchArealplanBehandlinger(arealplanId)
  return data
}

export default function useBehandlinger() {
  const kundeId = useSelectedKundeId()
  const arealplanId = useSelectedArealplanId()
  const isAuthorized = useAuth().authorization.isAuthorized

  const query = useQuery<Behandling[]>(
    ["behandlinger", { kundeId, arealplanId, isAuthorized }],
    () => getBehandlinger(arealplanId!),
    { enabled: !!arealplanId }
  )

  return { ...query, data: query?.data || [] }
}
