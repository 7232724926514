import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material"
import { searchIsActive } from "../../../../store/search/actions"
import { useFilter } from "./useFilter"
import useSearch from "../../../../hooks/search/useSearch"

export const SearchTermFilter = () => {
  const filter = useFilter("name_id")
  const filterValue = filter.get() || ""
  const search = useSearch()
  const loading = search.isFetching

  const handleChange = e => {
    const value: string = e.target.value
    if (value.length > 200) {
      alert("Teksten inneholder for mange tegn til å gjennomføre søket")
      return
    }
    filter.set(value)
  }

  const onFocus = () => {
    //set active search in store
    searchIsActive(true)
  }
  const onBlur = e => {
    searchIsActive(false)
  }

  const onClearInput = () => {
    filter.clear()
    const inputField = document.getElementById("plan-search")
    inputField && inputField.focus()
  }

  return (
    <TextField
      variant={"outlined"}
      size="small"
      sx={{
        height: "62px",
        width: "100%",
        marginY: "0px",
        outline: "none",
        "& .MuiOutlinedInput-input": {
          padding: "18px 20px",
          lineHeight: "20px",
          width: "100%",
          fontSize: "1.1428571428571428rem",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 300
        }
      }}
      id="plan-search"
      type={"search"}
      placeholder={"Søk på plannavn eller planid..."}
      value={filterValue}
      onChange={handleChange}
      onFocus={onFocus}
      onMouseLeave={onBlur} //Have to use onMouseLeave to prevent event to stop propagation https://github.com/facebook/react/issues/4210
      //Ser google også har det på søkefeltet, må da være greit
      // eslint-disable-next-line
      autoFocus={true}
      margin="normal"
      InputProps={{
        "aria-label": "søkefelt",
        endAdornment: (
          <InputAdornment position="end">
            {filterValue.length > 0 && (
              <IconButton
                id={"clear_name_id_filter"}
                onClick={onClearInput}
                aria-label="Fjern søkeord">
                <CloseIcon />
              </IconButton>
            )}
            {loading && (
              <IconButton>
                <CircularProgress size={27.5} />
              </IconButton>
            )}
            {!loading && (
              <IconButton type="submit" aria-label="Utfør søk">
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        )
      }}
    />
  )
}
