import * as React from "react"

import { Grid } from "@mui/material"
import { connect } from "react-redux"
import DialogRoot from "../components/DialogRoot"
import IEError from "../components/Error/IEError/IEError"
import ServerError from "../components/Error/ServerError"
import SnackbarMessage from "../components/SnackbarMessage"
import StatusMelding from "../components/StatusMelding/StatusMelding"
import Topbar from "../components/Topbar/Topbar.connector"
import CustomerSelector from "../features/kunde/MunicipalitySelector"
import Nyheter from "../features/NyhetsTjeneste/Nyheter"
import useCookieConsent from "../hooks/cookies/useCookieConsent"
import {
  ApplicationState,
  ConnectedReduxProps,
  getGeneralErrors,
  searchIsActive
} from "../store"
import CustomerRoutes from "./CustomerRoutes"
import "./main.css"
import { browserIsIE } from "./utils"
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { Routes, Route } from "react-router-dom"
import About from "../features/about/About"

export interface Props extends ConnectedReduxProps {
  errors?: string
  hideTopbar: boolean
}

const AppRoutes = ({ errors, hideTopbar, dispatch, ...props }: Props) => {
  const [showIEError, setShowIEError] = React.useState<boolean | undefined>(
    undefined
  )
  const cookieConsentStatus = useCookieConsent()
  const appInsight = useAppInsightsContext()

  const submit = document.getElementsByClassName("coi-button-group")
  for (var i = 0; i < submit.length; i++) {
    submit[i].addEventListener("click", function () {
      cookieConsentStatus.refetch()
    })
  }

  if (showIEError === undefined) {
    setShowIEError(browserIsIE())
  }

  if (errors) return <ServerError />
  if (showIEError) return <IEError />
  return (
    <Grid container={true} direction="column" wrap="nowrap">
      <Routes>
        <Route path="/*" element={<SnackbarMessage />} />
      </Routes>
      <Grid item={true}>
        <Routes>
          <Route path="/*" element={<Topbar mapFullscreen={false} />} />
        </Routes>
      </Grid>
      <Grid
        item={true}
        sx={theme => ({
          ...theme.mixins.toolbar,
          [theme.breakpoints.down("sm")]: {
            ...(hideTopbar && { display: "none" })
          }
        })}
      />
      <Grid item={true}>
        <Routes>
          <Route path="/*" element={<StatusMelding />} />
        </Routes>
      </Grid>
      <Grid
        item={true}
        sx={theme => ({
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100vw !important",
            margin: "0 auto"
            //marginBottom: 40
          },
          flexGrow: 1
        })}>
        <Routes>
          <Route path="/nyheter" element={<Nyheter />} />
          <Route path="/om-arealplaner" element={<About/>} />
          <Route path="/:customerId/*" element={<CustomerRoutes />} />
          <Route path="/" element={<CustomerSelector />} />
        </Routes>
        <DialogRoot />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  errors: getGeneralErrors(state),
  hideTopbar: searchIsActive(state)
})

export default connect(mapStateToProps)(AppRoutes)
