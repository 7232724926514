import * as React from "react"
import { useEffect, useState } from "react"
import {
  ChatArea,
  ChatField,
  QuestionInput,
  ReferenceChatItemType,
  ResponseToUser
} from "@norkart/nora-components"
import { ToiBox, ToiStack, ToiTypography } from "@norkart/toi-components"
import { planprat, PlanpratMetadata, PlanpratResponseObject } from "./types"
import { errorText } from "../../config/const"
import {
  cacheNewChatHistoryItem,
  getCachedChatHistory
} from "../../cache/chatHistory"
import ResetButton from "./ResetButton"
import { ToiThemeProvider } from "@norkart/toi-theme"
import { postChat } from "../../services/apiPlanprat"
import { formatMultiplePlans } from "../../utils/formatArealplan"
import { Arealplan } from "../../hooks/arealplaner/types"
import usePlanerForEiendom from "../../hooks/plananalyse/usePlanerForEiendom"
import { useFilters } from "../../features/search/Search/filters"
import { PlanFilter } from "./PlanFilter/PlanFilter"



interface ChatProps {
  plans: Arealplan[]
  kundeId: string
}


const Chat = ({plans, kundeId}: ChatProps) => {
  const [exampleQuestion, setExampleQuestion] = useState("")
  const [isFetching, setIsFetching] = useState(false)
  const [chatHistory, setChatHistory] = useState(getCachedChatHistory())

  const [selectedMetadata, setSelectedMetadata] = useState<PlanpratMetadata[]>([])

  //Consider removing activeSearchIndices and hard code as there is no selector in this component
  const [activeSearchIndices, setActiveSearchIndices] = useState<string[]>([])
  const filters = useFilters()
  const knr = filters.get("knr")
  const gnr = Number(filters.get("gnr"))
  const bnr = Number(filters.get("bnr"))
  const fnr = Number(filters.get("fnr"))
  const snr = Number(filters.get("snr"))
  const planerForEiendom = usePlanerForEiendom({ knr, gnr, bnr, fnr, snr })

  const formatChatHistory = () => {
    return chatHistory.map((item) => {

      if (item.reference) {
        return {
          content: item.reference.map((ref) => ref.contentText).join(" "),
          type: item.type
        }
      }

      return {
        content: item.content,
        type: item.type
      }
    })
  }


  useEffect(() => {
    setSelectedMetadata(getMetadata());
  }, [planerForEiendom.data]);

  const getMetadata = (): PlanpratMetadata[] => {
    const planData = planerForEiendom.data

    if (!planData) {
      return []
    }
    const planTyperToConsider = [planData.Kommunedelplaner, planData.Reguleringsplaner, planData.Kommuneplaner]
    const metadatas = planTyperToConsider.map(planer => {

      if (!planer) {
        return []
      }

      return planer.map(plan => {
        return {
          planId: plan.PlanId,
          planNavn: plan.Navn,
          selected: true,
          egenskaperFraDelareal: plan.Delareal?.map(d => {
            return {
              egenskaper: d.Egenskaper?.filter(e => e.Navn !== 'Delareal'), 
              selected: true
            }
          })
        } as PlanpratMetadata
      })
    })


    return metadatas.flat()
  }

  const updateHistory = (content?: string, ref?: ReferenceChatItemType[], type?: 'human' | 'ai' | 'system') => {
    const chatHistory: ResponseToUser = {content: content, reference: ref, type: type};
    cacheNewChatHistoryItem({...chatHistory, searchIndices: activeSearchIndices});
    setChatHistory(getCachedChatHistory);
  };

  const resetHistory = () => {
    setChatHistory([])
  }

  const generateAnswer = (content: PlanpratResponseObject[]): ReferenceChatItemType[] | string => {

    if (content.length === 1 && !content[0].content_from) {
      return content[0].answer;
    }

    return content.map((obj) => {
      const corresponsingPlan = plans.find((plan) => {
        return plan.planId === obj.content_from?.plan_id;
      });

      const planName = corresponsingPlan ? `${corresponsingPlan.planNavn} - ${corresponsingPlan.planType}` : 'Planprat';

      const chunks = obj.content_from ? obj.content_from.chunks : [];

      return {
        title: planName,
        contentText: obj.answer,
        reference: {
          chunks: chunks,
        },
      };
    });
  };

  const sendQuestion = async (message: string) => {
    setIsFetching(true)
    updateHistory(message, undefined, "human")
    const plansFormatted = formatMultiplePlans(plans, selectedMetadata)
    const includeReferences = true
    const chatHistoryFormatted = formatChatHistory()
    try {
      const res = await postChat({ message, chatHistoryFormatted, plansFormatted, includeReferences }, kundeId)
      const answer = generateAnswer(res.data.content)
      if (typeof answer === 'string') {
        updateHistory(answer, undefined, "ai")
      }
      else {
        updateHistory(undefined, answer, "ai")
      }
      setIsFetching(false)
    } catch (error) {
      updateHistory("Det skjedde en feil. Vennligst prøv igjen", undefined, "ai")
      setIsFetching(false)
    }
  }

  return (
    <ToiThemeProvider>
      <ToiStack sx={{ margin: "auto" }}>
        <ChatArea>
          <ToiTypography mt={4} mb={2} variant="h2">
            Planprat - Spør planroboten om det du lurer på
          </ToiTypography>
          <ChatField
            botName="Planprat"
            hasError={false}
            selectedConversation={planprat}
            conversationConfig={[]}
            chatHistory={chatHistory}
            isFetching={isFetching}
            errorText={errorText}
            setExampleQuestion={setExampleQuestion}
          />
        </ChatArea>
        <ToiBox m={"auto"} width="80vw" maxWidth={"1100px"}>
          <PlanFilter selectedMetadata={selectedMetadata} setSelectedMetadata={setSelectedMetadata}/>
          <QuestionInput
            disabled={false}
            clearOnSend={true}
            onSendChat={sendQuestion}
            exampleQuestion={exampleQuestion}
            setExampleQuestion={setExampleQuestion}
            isLoading={false}
            conversationType={planprat}
            onAbortMutation={function (): void {
              throw new Error("Function not implemented.")
            }}
          />
          <ResetButton resetChatHistory={resetHistory} />
        </ToiBox>
      </ToiStack>
    </ToiThemeProvider>
  )
}

export default Chat


