import { CircularProgress, Grid } from "@mui/material"
import { format } from "date-fns"
import * as React from "react"
import { Waypoint } from "react-waypoint"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import ReactTable from "../../../../components/ReactTable"
import { planforholdReportQuery } from "../../../../hooks/planforhold/planforholdQueries"
import usePlanforholdGraphQL from "../../../../hooks/planforhold/usePlanforholdGraphQL"
import { usePlanforholdQLCsv } from "../../../../hooks/planforhold/usePlanforholdQLCsv"
import { setTitle } from "../../../../utils/setTitle"
import { exportToCsv } from "../ReportTools/ClientsideCsv"
import { ReportSortState } from "../ReportTools/CommonTypes"
import { PlanforholdQueryVariables } from "./Planforhold"
import PlanforholdColumns from "./PlanforholdColumns"

type DispensasjonerReportProps = {
  variables: PlanforholdQueryVariables
  kundeId: string
  kommunenummer: string
  setVars: React.Dispatch<React.SetStateAction<PlanforholdQueryVariables>>
}

export default function PlanforholdReport({
  variables,
  kundeId,
  kommunenummer,
  setVars
}: DispensasjonerReportProps) {
  setTitle("Rapporter > Planforhold")
  const csv = usePlanforholdQLCsv(variables)
  const columns = PlanforholdColumns(kundeId, kommunenummer)
  const planforhold = usePlanforholdGraphQL(planforholdReportQuery, variables)

  const handleSort = ({ property, direction }: ReportSortState) => {
    if (!direction && variables.sort) {
      setVars({ ...variables, sort: undefined })
      return
    }

    if (!direction && property && !variables.sort) return
    let sorting: any
    switch (property) {
      case "planIdforloper":
        sorting = [{ planIdFraNavigation: { planId: direction } }]
        break
      case "fraDato":
        sorting = [{ planIdFraNavigation: { iKraft: direction } }]
        break
      case "tilDato":
        sorting = [{ planIdTilNavigation: { iKraft: direction } }]
        break
      case "planNavn":
        sorting = [{ planIdFraNavigation: { planNavn: direction } }]
        break
      case "planStatus":
        sorting = [{ planIdFraNavigation: { planStatus: direction } }]
        break
      case "planType":
        sorting = [{ planIdFraNavigation: { planType: direction } }]
        break
      case "Planforhold":
        sorting = [
          { planforholdType: { beskrivelseEtterkommer: direction } },
          { planIdTilNavigation: { planId: direction } }
        ]
        break
      default:
        return undefined
    }

    setVars({ ...variables, sort: sorting })
  }

  React.useEffect(() => {
    if (csv.csvData && csv.isSuccess) {
      exportToCsv(
        `planforhold_${format(new Date(), "ddMMyyyy")}`,
        csv.csvData,
        csv.headers
      )
      csv.remove()
    }
  }, [csv.csvData])

  const handleDownloadClick = () => {
    csv.refetch()
  }

  if (planforhold.isLoading && !variables.sort) return <LoadingSpinner />
  return (
    <Grid item={true}>
      <ReactTable
        textSize="small"
        customSort={(property, direction) =>
          handleSort({ property, direction })
        }
        columns={columns}
        data={planforhold.planforhold ? planforhold.planforhold : []}
        toolbar={{
          exportCsv: {
            handleDownloadClick: () => handleDownloadClick(),
            loadingRequest: csv.isLoading
          },
          dataInfo: planforhold.isFetching ? (
            <CircularProgress
              color="primary"
              size={20}
              title="Oppdaterer resultater"
            />
          ) : (
            <p>{`${
              planforhold.totalCount ? planforhold.totalCount : 0
            }  planforhold funnet`}</p>
          )
        }}
      />
      {planforhold.isFetchingNextPage && <LoadingSpinner />}
      {planforhold?.hasNextPage && (
        <Waypoint
          onEnter={() => planforhold.fetchNextPage()}
          bottomOffset="-400px"
        />
      )}
    </Grid>
  )
}
