import React from "react"
import { connect, ConnectedProps } from "react-redux"
import { Dispatch } from "redux"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { Journal } from "../../../hooks/journaler/types"
import useJournalDokumenter from "../../../hooks/journaler/useJournalDokumenter"
import { ApplicationState, getFilesById } from "../../../store"
import ImportDocumentFromArchiveSelectFiles from "./ImportDocumentFromArchiveSelectFilesList"

type Props = ConnectedProps<typeof connector> & {
  journal: Journal
}
function ImportDocumentFromArchiveDetailPanel({ journal, ...props }: Props) {
  const { data: documents, isLoading, isError } = useJournalDokumenter(journal)

  if (isLoading) return <LoadingSpinner />
  if (isError) return <div>Ingen dokumenter funnet</div>
  return <ImportDocumentFromArchiveSelectFiles documents={documents} />
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedDocumentsById: getFilesById(state)
})
const mapDispatchToProps = (dispatch: Dispatch) => ({})
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ImportDocumentFromArchiveDetailPanel)
