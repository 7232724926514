import { CircularProgress, Grid } from "@mui/material"
import * as React from "react"
import { Waypoint } from "react-waypoint"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import ReactTable from "../../../../components/ReactTable"
import useBehandlingerReport, {
  BehandlingReportFilters,
  BehandlingReportSorting
} from "../../../../hooks/behandlinger/useBehandlingerReport"
import { setTitle } from "../../../../utils/setTitle"
import BehandlingerColumns from "./BehandlingerColumns"
import { format } from "date-fns"
import { fetchBehandlingerReportCsv } from "../../../../services/api"

type BehandlingerReportProps = {
  filterValues?: BehandlingReportFilters
}

export default function BehandlingerReport({
  filterValues
}: BehandlingerReportProps) {
  setTitle("Rapporter > Behandlinger")
  const [csv, setCsv] = React.useState<any | undefined>()
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false)

  const columns = BehandlingerColumns()

  const [sorting, setSorting] = React.useState<
    BehandlingReportSorting | undefined
  >({ orderBy: "planId", order: "asc" })

  const behandlinger = useBehandlingerReport({
    filters: filterValues,
    sorting: sorting
  })

  type HandleSortProps = {
    property?: string | number
    direction?: "ASC" | "DESC"
  }
  const handleSort = ({ property, direction }: HandleSortProps) => {
    let orderBy = property?.toString() ?? "planId"
    if (orderBy == "behandlingBeskrivelse") orderBy = "beskrivelse"
    setSorting({
      orderBy,
      order: direction ?? "asc"
    })
  }

  React.useEffect(() => {
    if (csv) {
      const blob = new Blob(["\ufeff", csv], {
        type: "data:text/csv; charset=utf-8"
      })

      const a = document.createElement("a")
      a.download = `behandlinger_${format(new Date(), "ddMMyyyy")}.csv`
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
      })
      a.dispatchEvent(clickEvt)
      a.remove()

      setCsv(undefined)
      setIsDownloading(false)
    }
  }, [csv])

  const handleDownloadClick = () => {
    setIsDownloading(true)
    fetchBehandlingerReportCsv(
      sorting?.order,
      sorting?.orderBy,
      filterValues?.behandlingstypeId,
      filterValues?.plantypeId,
      filterValues?.planstatusId,
      filterValues?.beskrivelse,
      filterValues?.fraDatoStart,
      filterValues?.fraDatoEnd,
      filterValues?.tilDatoStart,
      filterValues?.tilDatoEnd
    )
      .then(res => res.data)
      .then(r => setCsv(r))
      .catch(e => {
        if (e.response.status === 404) return
        else return e
      })
  }

  if (behandlinger.isLoading) return <LoadingSpinner />

  return (
    <Grid item={true}>
      <ReactTable
        customSort={(property, direction) =>
          handleSort({ property, direction })
        }
        columns={columns}
        data={behandlinger.results ? behandlinger.results : []}
        textSize="small"
        toolbar={{
          exportCsv: {
            handleDownloadClick: () => handleDownloadClick(),
            loadingRequest: isDownloading
          },
          dataInfo: behandlinger.isFetching ? (
            <CircularProgress
              color="primary"
              size={20}
              title="Oppdaterer resultater"
            />
          ) : (
            <p>
              {`${
                behandlinger.totalCount ? behandlinger.totalCount : 0
              } behandlinger funnet`}
            </p>
          )
        }}
      />
      {behandlinger.isFetchingNextPage && <LoadingSpinner />}
      {behandlinger?.hasNextPage && (
        <Waypoint
          onEnter={() => behandlinger.fetchNextPage()}
          bottomOffset="-400px"
        />
      )}
    </Grid>
  )
}
