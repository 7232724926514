import * as React from "react"
import { useQuery } from "react-query"
import { graphQLRequest } from "../../services/api"
import { PlanstatusLovreferanseReport } from "./types"

import { planstatusLovreferanseQuery } from "./customReportQueries"

export function usePlanstatusLovreferaseGraphQLCsv() {
  let headers = [
    "LovreferanseId",
    "Lovreferanse",
    "Gjeldende",
    "Planer under arbeid",
    "Ubestemt",
    "Utgått"
  ]

  const reactQuery = useQuery<any>(
    ["planstatusLovreferanseReportGraphQLCsv"],
    () => graphQLRequest(planstatusLovreferanseQuery),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
      keepPreviousData: false
    }
  )

  const csvArray = React.useMemo(() => {
    if (reactQuery.data) {
      const planstatusLovreferanseReport: PlanstatusLovreferanseReport[] =
        reactQuery.data.planstatusLovreferanseReport

      const newLines = planstatusLovreferanseReport.reduce((acc: any, d) => {
        acc.push(
          [
            d.lovreferanse.id,
            d.lovreferanse.beskrivelse,
            d.gjeldende,
            d.underArbeid,
            d.ubestemt,
            d.utgatt
          ].join(";")
        )
        return acc
      }, [])

      return newLines
    }
  }, [reactQuery.data])

  return { ...reactQuery, csvData: csvArray, headers: headers }
}
