import _ from "lodash-es"
import { useQuery } from "react-query"
import { fetchMeta } from "../../services/api"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { LagKategorier, Meta } from "./types"
import * as types from "./types"
import { Vertikalniva } from "../../store/shared/types"
import { regelsettConst } from "../../features/map/helpers/constants"
import { useNavigate } from "react-router-dom"
import useErrorHandler from "../errors/useErrorHandler"
import { useDispatch } from "react-redux"
import { reset as resetKunde } from "../../store/kunder/actions"

const getMeta = async () => {
  const { data } = await fetchMeta()

  let key,
    keys = Object.keys(data)
  let n = keys.length
  let res = {} as Partial<Meta>
  while (n--) {
    key = keys[n]
    res[key.toLowerCase()] = data[key]
  }
  const {
    innstillinger,
    moduler,
    featuretoggles,
    kartlaginnstillinger,
    ...listData
  } = res
  return {
    innstillinger: _.mapKeys(innstillinger, "id"),
    moduler: _.mapKeys(moduler, "id"),
    featuretoggles: _.mapKeys(featuretoggles, "id"),
    kartlaginnstillinger: parseKartlagInnstillinger(kartlaginnstillinger),
    ...listData
  } as Meta
}

function parseKartlagInnstillinger(kartlaginnstillinger) {
  return kartlaginnstillinger?.map(innstilling => {
    return {
      ...innstilling,
      kartlagRegelsett: innstilling.kartlagRegelsett.map(
        rs => rs.kartlagRegelsettId
      )
    }
  })
}

const placeholder = {
  innstillinger: {},
  moduler: {},
  featuretoggles: {},
  formater: [] as types.Format[],
  forslagsstillertyper: [] as types.Forslagsstillertype[],
  planstatuser: [] as types.Planstatus[],
  plantyper: [] as types.Plantype[],
  planbestemmelser: [] as types.Planbestemmelse[],
  vertikalniva: [] as Vertikalniva[],
  kommuner: [] as types.Kommune[],
  lovreferanser: [] as types.Lovreferanse[],
  myndigheter: [] as types.Myndighet[],
  behandlingstyper: [] as types.Behandlingstype[],
  dispensasjonstyper: [] as types.Dispensasjonstype[],
  vedtakstyper: [] as types.Vedtakstype[],
  endringstyper: [] as types.Endringstype[],
  dokumenttyper: [] as types.Dokumenttype[],
  tilgangstyper: [] as types.Tilgang[],
  arkiver: [] as types.Arkiv[],
  planforholdtyper: [] as types.Planforholdtype[],
  kartlaginnstillinger: {} as types.KartlagKonfig
} as Meta

export default function useMeta() {
  const navigate = useNavigate()
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()
  const dispatch = useDispatch()

  const query = useQuery<Meta>(["meta", { kundeId }], getMeta, {
    onError: e => {
      navigate("/")
      dispatch(resetKunde())
      notify(e)
    },
    enabled: !!kundeId
  })

  const getInnstilling = (id: string) => {
    return query.data?.innstillinger[id].verdi
  }
  const komnr = getInnstilling("komnr") || ""
  const referansesystemKode = getInnstilling("referansesystem_kode")
  const aktivtArkiv = parseInt(getInnstilling("aktivt_arkiv_id") || "1")
  const visplanUrl = getInnstilling("visplan_url")
  const visplanTekst = getInnstilling("visplan_tekst")
  const visplanInternUrl = getInnstilling("visplan_intern_url")
  const visplanInternTekst = getInnstilling("visplan_intern_tekst")
  const relevanteLinker = getInnstilling("relevante_linker")
  const sporsmaalUrl = getInnstilling("sporsmaal_url")
  const sporsmaalInnhold = getInnstilling("sporsmaal_innhold")
  const brukerveiledningUrl = getInnstilling("brukerveiledning_url")
  const tilbakemeldingEgetSkjemaUrl =
    getInnstilling("tilbakemelding_eget_skjema_url") || ""
  const konsistenssjekkScheduler =
    getInnstilling("konsistenssjekk_scheduler_cron") || ""
  const tilgjengelighetUrl = getInnstilling("tilgjengelighet_url") || ""

  const hasModul = (id: string) => {
    return Boolean(query.data?.moduler[id].verdi)
  }

  const hasArkivIntegrasjon = () => {
    return hasModul("arkivintegrasjon")
  }
  const hasPlandialog = () => {
    return hasModul("plandialog")
  }
  const hasApiTilgang = () => {
    return hasModul("api_tilgang")
  }

  const hasPlanprat = () => {
    return hasModul("planprat")
  }

  const hasMittInnspill = () => {
    return hasModul("mittinnspill")
  }

  const hasTilbakemelding = () =>
    hasModul("tilbakemelding") &&
    Boolean(getInnstilling("tilbakemelding_epost"))

  const hasTilbakemeldingEgetSkjema = () =>
    hasModul("tilbakemelding_eget_skjema") &&
    Boolean(getInnstilling("tilbakemelding_eget_skjema_url"))

  const hasFeature = (id: string) => {
    return Boolean(query.data?.featuretoggles[id].verdi)
  }

  const hasTilbakemeldingBegrensetHoringsperiode = () =>
    hasFeature("tilbakemelding_begrenset_horingsperiode")

  const getPlanstatus = (PlanStatusId: number | string): string => {
    for (let i = 0; i < meta.data.planstatuser.length; i++) {
      const planstatus = meta.data.planstatuser[i]
      if (planstatus.id == Number(PlanStatusId)) return planstatus.beskrivelse
    }
    return ""
  }

  const getPlanforholdstypeForloper = (id: number): string => {
    for (let i = 0; i < meta.data.planforholdtyper.length; i++) {
      const planforholdstype = meta.data.planforholdtyper[i]
      if (planforholdstype.id == Number(id))
        return planforholdstype.beskrivelseForloper
    }
    return ""
  }

  const getPlanforholdstypeEtterkommer = (id: number): string => {
    for (let i = 0; i < meta.data.planforholdtyper.length; i++) {
      const planforholdstype = meta.data.planforholdtyper[i]
      if (planforholdstype.id == Number(id))
        return planforholdstype.beskrivelseEtterkommer
    }
    return ""
  }

  const lagKategorier: LagKategorier = {
    vertikalMapping: {
      1: regelsettConst.RegUnderGrunnen, // Under grunnen (tunnel),
      2: regelsettConst.RegPaaGrunnen, //"På grunnen/vannoverflaten"
      3: regelsettConst.RegOverGrunnen, //"Over grunnen (bru)"
      4: regelsettConst.RegPaaBunnen, //På bunnen (vann/sjø),
      5: regelsettConst.RegIVannsoylen //I vannsøylen
    }
  }

  const meta = {
    ...query,
    data: query?.data || placeholder,
    getInnstilling,
    komnr,
    kundeId,
    referansesystemKode,
    aktivtArkiv,
    hasModul,
    hasArkivIntegrasjon,
    hasPlandialog,
    hasTilbakemelding,
    hasApiTilgang,
    hasPlanprat,
    hasMittInnspill,
    hasTilbakemeldingEgetSkjema,
    hasTilbakemeldingBegrensetHoringsperiode,
    hasFeature,
    konsistenssjekkScheduler,
    visplanUrl,
    visplanTekst,
    visplanInternUrl,
    visplanInternTekst,
    relevanteLinker,
    sporsmaalUrl,
    sporsmaalInnhold,
    brukerveiledningUrl,
    tilbakemeldingEgetSkjemaUrl,
    getPlanstatus,
    getPlanforholdstypeForloper,
    getPlanforholdstypeEtterkommer,
    tilgjengelighetUrl,
    lagKategorier
  }
  return meta
}
