import { useQuery } from "react-query"
import { GraphQLUnpagedResponse } from "../../graphql/types"
import { graphQLRequest } from "../../services/api"

export default function usePlanstatusPlantypeGraphQL(graphqlQuery: string) {
  
  const query = useQuery<GraphQLUnpagedResponse>(
    ["planstatusPlantypeReportGraphQL"],
    () => graphQLRequest(graphqlQuery),
    {
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
    },)
    
    return { ...query,tilstandPlantype: query.data?.planstatusPlantypeReport || []}
  }