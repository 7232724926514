import { Typography } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import * as React from "react"
import { PlanstatusPlantypeReport } from "../../../../hooks/customReports/types"
import { PlanforholdReport } from "../../../../hooks/planforhold/types"

export default function TilstandPlantypeColumns() {
  return React.useMemo<ColumnDef<PlanstatusPlantypeReport>[]>(
    () => [
      {
        id: "plantype",
        cell: r => (
          <Typography fontWeight={500} fontSize="0.85rem" component="span">
            {r.row.original.plantype.beskrivelse}
          </Typography>
        ),
        header: () => "Plantype",
        accessorFn: r => r.plantype.beskrivelse,
        enableSorting: true,
        size: 300,
        minSize: 300
      },
      {
        id: "gjeldendePlaner",
        accessorFn: r => r.gjeldende,
        header: () => "Gjeldende planer",
        enableSorting: true,
        size: 50
      },
      {
        id: "planerUnderArbeid",
        accessorFn: r => r.underArbeid,
        header: () => "Planer under arbeid",
        enableSorting: true,
        size: 50
      },
      {
        id: "ubestemt",
        accessorFn: r => r.ubestemt,
        header: () => "Ubestemt",
        enableSorting: true,
        size: 50
      },
      {
        id: "utgatt",
        accessorFn: r => r.utgatt,
        header: () => "Utgått",
        enableSorting: true,
        size: 50
      }
    ],
    []
  )
}
