import * as actions from "../../store/map/actions"

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { Coords } from "./types"
import BaseMap from "./BaseMap"
import LoadingSpinner from "../../components/LoadingSpinner"
import PlanensKartlag from "./Components/Menus/Kartlag/PlanensKartlag"
import { Posisjon } from "../../hooks/dispensasjoner/types"
import PositionMenu from "./Components/Menus/PositionMenu"
import React from "react"
import { getMapState } from "../../store"
import { toLatLng } from "../behandling/BehandlingFormDialog/helpers"
import useMeta from "../../hooks/meta/useMeta"
import { Arealplan } from "../../hooks/arealplaner/types"

const DialogWrapper = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: "80vw",
    width: "80vw",
    height: "90vh"
  }
})

type Props = {
  onCloseMap: () => void
  position: Posisjon
  vertikalnivaId?: number
  title?: string
  plan?: Arealplan
}

const DisplayPosInMap = (props: Props) => {
  const meta = useMeta()
  const mapState = useSelector(getMapState)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (props.vertikalnivaId && mapState.wmsLayerState.availableWmsLayers) {
      const correspondingWmsLayerName =
        meta.lagKategorier.vertikalMapping[props.vertikalnivaId]
      const layer = mapState.wmsLayerState.availableWmsLayers.find(
        b => b.ruleset == correspondingWmsLayerName
      )
      if (layer) {
        dispatch(actions.addWmsLayer(layer))
      }
    }
  }, [props.vertikalnivaId])

  const latLngCoords: Coords = toLatLng(
    props.position,
    meta.referansesystemKode
  )

  return (
    <DialogWrapper fullWidth={true} open={true}>
      <DialogTitle style={{ textAlign: "center" }}>
        <Typography style={{ fontSize: 22 }}>
          {props.title || "Posisjonen"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {mapState.wmsLayerState.loading || !mapState.borderLayers?.combined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Box
              sx={{
                maxHeight: "95vh",
                minWidth: "252px",
                position: "absolute",
                zIndex: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column" as any,
                height: "auto",
                right: "30px",
                top: "95px",
                "& > .section": {
                  marginTop: "10px"
                }
              }}>
              <PlanensKartlag defaultClosed={false} />
              <PositionMenu
                selectedPosition={{ x: props.position.x, y: props.position.y }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                top: 0,
                left: 0,
                "& .nkm-mapbox-map, .mapboxgl-map": {
                  width: "100%",
                  height: "100%"
                }
              }}>
              <BaseMap
                plan={props.plan}
                displaySearchedPropertyGeom={false}
                displaySurroundingPlanWms={false}
                mapboxMarker={latLngCoords}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20
        }}>
        <Button
          onClick={props.onCloseMap}
          variant="contained"
          color="secondary"
          style={{ marginLeft: 10, marginRight: 10, width: 200 }}>
          Lukk
        </Button>
      </DialogActions>
    </DialogWrapper>
  )
}

export default DisplayPosInMap
