import { combineReducers, Reducer } from "@reduxjs/toolkit"
import { SharedStateActions as actions, SharedState } from "./types"

const errors: Reducer = (state = null, action) => {
  switch (action.type) {
    case actions.GENERAL_ERROR:
      return action.payload

    default:
      return state
  }
}

export const sharedReducer = combineReducers({
  errors
})

export const getGeneralErrors = (state: SharedState) => state.errors
