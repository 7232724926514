import { useQuery } from "react-query"
import { fetchJournalDocuments } from "../../services/api"
import { FetchJournalDocumentsProps, Journal, JournalDocument } from "./types"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { doc } from "prettier"
import { useMemo } from "react"

const getJournalDokumenter = async (props: FetchJournalDocumentsProps) => {
  const { data } = await fetchJournalDocuments(props)
  return data
}

export default function useJournalerDokumenter(journal: Journal) {
  const kundeId = useSelectedKundeId()
  const props = {
    sakId: journal.sakId,
    journalpostAar: journal.journalpostAar,
    journalpostSekvensnr: journal.journalpostSekvensnr
  } as FetchJournalDocumentsProps

  const query = useQuery<JournalDocument[]>(
    ["journaldokumenter", { kundeId, ...props }],
    () => getJournalDokumenter(props),
    {
      enabled: !!journal.sakId,
      retry: 0,
      staleTime: 1000 * 60 * 60, // 1h
      refetchOnMount: false
    }
  )

  const mappedData = useMemo(
    () =>
      query.data &&
      query.data.map(document => {
        return {
          ...document,
          journal
        }
      }),
    [query.data, journal.id]
  )

  return { ...query, data: mappedData || [] }
}
