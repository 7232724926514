import { Link } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import * as React from "react"
import useMeta from "../../../../hooks/meta/useMeta"
import { format } from "date-fns"
import { SaksbehandlingstidReport } from "../../../../hooks/customReports/types"

const baseUrl = window.location.origin

export default function SaksbehandlingstidColumns(
  kundeId: string,
  kommunenummer: string
) {
  const meta = useMeta()
  return React.useMemo<ColumnDef<SaksbehandlingstidReport>[]>(
    () => [
      {
        id: "planId",
        header: () => "PlanId",
        cell: r => (
          <Link
            key={r.row.id + "link"}
            href={`${baseUrl}/${kundeId}/gi?funksjon=VisPlan&planidentifikasjon=${r.row.original?.planId}&kommunenummer=${kommunenummer}`}
            target="_blank"
            variant="body1"
            underline="none"
            color="secondary">
            {r.row.original?.planId ? r.row.original?.planId : null}
          </Link>
        ),
        accessorFn: r => r.planId,
        enableSorting: true,
        size: 70
      },
      {
        id: "fraDato",
        cell: r => (
          <>
            {r.row.original?.fraDato
              ? format(new Date(r.row.original.fraDato), "dd.MM.yyyy")
              : ""}
          </>
        ),
        accessorFn: (r, i) => r.fraDato && new Date(r.fraDato),
        header: () => "Fra dato",
        enableSorting: true,
        sortingFn: "datetime",
        size: 50
      },
      {
        id: "behandlingstypeFra",
        accessorFn: (r, i) => r.behandlingFra.behandlingstype,
        header: () => "Behandlingstype fra",
        enableSorting: true,
        size: 70
      },
      {
        id: "tilDato",
        cell: r => (
          <>
            {r.row.original?.tilDato
              ? format(new Date(r.row.original.tilDato), "dd.MM.yyyy")
              : ""}
          </>
        ),
        accessorFn: (r, i) => r.tilDato && new Date(r.tilDato),
        header: () => "Til dato",
        enableSorting: true,
        sortingFn: "datetime",
        size: 50
      },
      {
        id: "behandlingstypeTil",
        accessorFn: (r, i) => r.behandlingTil.behandlingstype,
        header: () => "Behandlingstype til",
        enableSorting: true,
        size: 70
      },
      {
        id: "antallDager",
        accessorFn: (r, i) => r.antallDager,
        header: () => "Antall dager",
        enableSorting: true,
        size: 70
      },
      {
        id: "plantype",
        accessorFn: (r, i) => r.plantype.beskrivelse,
        header: () => "Plantype",
        enableSorting: true,
        size: 70
      }
    ],
    []
  )
}
