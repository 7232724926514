import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from "@mui/material"
import {
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import * as React from "react"
import { Waypoint } from "react-waypoint"
import { LogLines } from "../../../../hooks/konsistenssjekk/types"
import Toolbar from "./Toolbar"
import KonsistenssjekkColumns from "./KonsistenssjekkColumns"

interface KonsistenssjekkTableProps {
  kundeId: string
  kommuneNummer: string
  showRunningReport: boolean
  konsistenssjekk: any
  infiniteScrollData: LogLines[] | undefined
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  handleInfiniteScroll: (displayedData: LogLines[] | undefined) => void
}

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}

export default function KonsistenssjekkTable({
  kundeId,
  kommuneNummer,
  showRunningReport,
  konsistenssjekk,
  infiniteScrollData,
  searchText,
  handleInfiniteScroll,
  setSearchText
}: KonsistenssjekkTableProps) {
  const columns = KonsistenssjekkColumns(kundeId, kommuneNummer)
  const [filteredData, setFilteredData] = React.useState<
    LogLines[] | undefined
  >()
  const [sorting, setSorting] = React.useState<SortingState>([])

  //Debouncing filter
  React.useEffect(() => {
    if (searchText === "") {
      filteredData && setFilteredData(undefined)
      return
    }
    if (konsistenssjekk.loglines || konsistenssjekk.tempLoglines) {
      const filterTimeout = setTimeout(() => {
        const searchRegex = new RegExp(escapeRegExp(searchText), "i")
        const filteredRows = (
          !showRunningReport
            ? konsistenssjekk.loglines
            : konsistenssjekk.tempLoglines
        )?.filter(row => {
          return Object.keys(row).some(field => {
            return searchRegex.test(row[field] ? row[field].toString() : "")
          })
        })

        setFilteredData(filteredRows)
      }, 500)

      return () => clearTimeout(filterTimeout)
    }
  }, [searchText])

  const tableData = React.useMemo(() => {
    if (filteredData) return filteredData
    else if (infiniteScrollData) return infiniteScrollData
    else return [] as LogLines[]
  }, [infiniteScrollData, filteredData])

  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <div>
      <Toolbar
        value={searchText}
        onChange={event => setSearchText(event.target.value)}
        clearSearch={() => setSearchText("")}
        onKeyUp={e => {
          if (e.key === "Escape") setSearchText("")
        }}
        showRunningReport={showRunningReport}
        dataInfo={
          konsistenssjekk.isRunning ? (
            <CircularProgress size={20} color={"primary"} />
          ) : (
            <Typography fontSize={"0.85rem"}>
              {`${
                konsistenssjekk?.loglines ? konsistenssjekk.loglines.length : 0
              } avvik funnet`}
            </Typography>
          )
        }
      />
      <TableContainer>
        <Table
          sx={{
            "& tbody tr:last-child td": {
              border: 0
            },
            columnSpan: "all"
          }}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell
                    variant="head"
                    onClick={
                      header.column.getCanSort()
                        ? header.column.getToggleSortingHandler()
                        : undefined
                    }
                    sx={{
                      minWidth: header.column.getSize(),
                      fontSize: "1rem",
                      cursor: header.column.getCanSort() ? "pointer" : "auto"
                    }}
                    key={header.id}>
                    <TableSortLabel
                      sx={{ width: "100%" }}
                      active={sorting.find(s => s)?.id === header.column.id}
                      direction={
                        header.column.getIsSorted() === "asc"
                          ? "asc"
                          : header.column.getIsSorted() === "desc"
                          ? "desc"
                          : undefined
                      }>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                sx={{
                  fontSize: "1rem"
                }}
                hover={true}
                key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    sx={{
                      fontSize: "1rem"
                    }}
                    key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!showRunningReport && (
        <Waypoint
          onEnter={() => handleInfiniteScroll(konsistenssjekk.loglines)}
          bottomOffset="-400px"
        />
      )}
    </div>
  )
}
