import React, { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import useMeta from "../../../../hooks/meta/useMeta"
import { usePropertySearch } from "./PropertySearchFilter/usePropertySearch"
import qs from "query-string"

export type FilterType =
  | "saksnummer"
  | "name_id"
  | "property"
  | "planstatus"
  | "plantype"
  | "fromDate"
  | "toDate"
  | "behandlingstype"
  | "advanced"
  | "opprinneligAdministrativEnhet"

type SearchFilter = {
  type: FilterType
  label: string
  searchText?: string | string[] | null
  urlSearchParamKey?: string
  urlSearchParamValue?: string
}

export const mapFilterTypeToParamKey = {
  planstatus: "planStatusId",
  plantype: "planTypeId",
  saksnummer: "saksnummer",
  fromDate: "fromDate",
  toDate: "toDate",
  behandlingstype: "behandlingstypeId",
  opprinneligAdministrativEnhet: "opprinneligAdministrativEnhet",
  name_id: "term",
  knr: "knr",
  gnr: "gnr",
  bnr: "bnr",
  fnr: "fnr",
  snr: "snr",
  teigid: "teigid"
}

export const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const propertySearch = usePropertySearch()
  const meta = useMeta()

  const setQueryParam = (filterKey: string, value: string) => {
    setSearchParams(searchParams => {
      searchParams.set(filterKey, value)
      return searchParams
    })
  }

  const clearQueryParam = (filterKey: string) => {
    setSearchParams(searchParams => {
      searchParams.delete(filterKey)
      return searchParams
    })
  }

  const getQueryParam = (filterKey: string): string | null => {
    return searchParams.get(filterKey)
  }

  const getFilterKey = (filterType: string) => {
    return mapFilterTypeToParamKey[filterType]
  }

  const get = (filterType: string): string | null => {
    return getQueryParam(getFilterKey(filterType))
  }

  const set = (filterType: string, params: string) => {
    const filterKey = getFilterKey(filterType)
    if (params === "") {
      clear(filterType)
    } else {
      setQueryParam(filterKey, params)
    }
  }

  const remove = (filterType: string, paramToRemove: string) => {
    const filterKey = getFilterKey(filterType)
    const current = getQueryParam(filterKey)
    // Might be array
    if (current?.includes(",")) {
      const params = current
        .split(",")
        .filter(p => p !== paramToRemove)
        .join(",")
      setQueryParam(filterKey, params)
    } else {
      clear(filterType)
    }
  }

  const clear = (filterType: string) => {
    clearQueryParam(getFilterKey(filterType))
  }

  const clearAll = () => {
    setSearchParams(searchParams => {
      Object.values(mapFilterTypeToParamKey).map(filterKey =>
        searchParams.delete(filterKey)
      )
      return searchParams
    })
    propertySearch.clearSearchInput()
  }

  const getAll = () => {
    const url = new URL(window.location.toString())
    url.searchParams.set("page", "1")
    return url.searchParams.toString()
  }

  const activeFiltersFromUrl = useMemo<SearchFilter[]>(() => {
    const {
      plantyper: plantypelist,
      behandlingstyper: behandlingstypeList
    } = meta.data

    //getting active search from url
    const filters: SearchFilter[] = []
    const {
      term = "",
      planStatusId,
      planTypeId,
      saksnummer,
      fromDate,
      toDate,
      behandlingstypeId,
      opprinneligAdministrativEnhet
    } = qs.parse(window.location.search)
    if (term && term.length > 0 && typeof term === "string") {
      const filter: SearchFilter = {
        type: "name_id",
        label: term,
        urlSearchParamValue: term
      }
      filters.push(filter)
    }
    if (planStatusId) {
      const planStatusIdsList =
        typeof planStatusId == "string" ? planStatusId.split(",") : []
      planStatusIdsList.forEach(planStatusId => {
        const label = meta.getPlanstatus(planStatusId)
        const filter: SearchFilter = {
          type: "planstatus",
          label: "Planstatus: " + label,
          searchText: planStatusId,
          urlSearchParamKey: "planStatusId"
        }
        filters.push(filter)
      })
    }
    if (planTypeId) {
      const planTypeIdsList =
        typeof planTypeId == "string" ? planTypeId.split(",") : []
      planTypeIdsList.forEach(planTypeId => {
        const label = plantypelist.find(typ => String(typ.id) == planTypeId)
        const filter: SearchFilter = {
          type: "plantype",
          label: "Plantype: " + label?.beskrivelse,
          searchText: planTypeId,
          urlSearchParamKey: "planTypeId"
        }
        filters.push(filter)
      })
    }
    if (behandlingstypeId) {
      const behTypeList =
        typeof behandlingstypeId === "string"
          ? behandlingstypeId.split(",")
          : [behandlingstypeId]
      behTypeList.forEach(behId => {
        const label = behandlingstypeList.find(
          typ => String(typ.behandlingstypeId) == behId
        )
        const filter: SearchFilter = {
          type: "behandlingstype",
          label: "Behandlingstype: " + label?.beskrivelse,
          searchText: behId,
          urlSearchParamKey: "behandlingstypeId"
        }
        filters.push(filter)
      })
    }
    if (saksnummer) {
      const filter: SearchFilter = {
        type: "saksnummer",
        searchText: saksnummer,
        label: "Saksnummer: " + saksnummer,
        urlSearchParamKey: "saksnummer"
      }
      filters.push(filter)
    }
    if (fromDate) {
      const filter: SearchFilter = {
        type: "fromDate",
        searchText: String(fromDate),
        label: "Behandlingsperiode fra: " + fromDate,
        urlSearchParamKey: "fromDate"
      }
      filters.push(filter)
    }
    if (toDate) {
      const filter: SearchFilter = {
        type: "toDate",
        searchText: String(toDate),
        label: "Behandlingsperiode til: " + toDate,
        urlSearchParamKey: "toDate"
      }
      filters.push(filter)
    }
    if (opprinneligAdministrativEnhet) {
      const filter: SearchFilter = {
        type: "opprinneligAdministrativEnhet",
        searchText: opprinneligAdministrativEnhet,
        label:
          "Opprinnelig administrativ enhet: " + opprinneligAdministrativEnhet,
        urlSearchParamKey: "opprinneligAdministrativEnhet"
      }
      filters.push(filter)
    }
    return filters
  }, [location.search, meta.isFetched])

  return { get, getAll, set, clear, remove, clearAll, activeFiltersFromUrl }
}
