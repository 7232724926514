import { Box } from "@mui/material"
import React, { useState } from "react"
import InfoButton from "../home/infoButtons/InfoButton"
import RoomIcon from "@mui/icons-material/Room"
import SwapHorizontal from "@mui/icons-material/SwapHorizontalCircle"
import {MapPolygonIcon} from '@norkart/toi-icons';
import MapDialogMyPosition from "../MapDialogMyPosition/MapDialogMyPosition"
import { findMyLocation } from "../home/infoButtons/utils/myLocation"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import useMeta from "../../hooks/meta/useMeta"
import theme from "../../theme"
import useTrackArealplanerEvent from "../../hooks/appinsights/useTrackArealplanerEvent"
import LoadingSpinner from "../../components/LoadingSpinner"
import { useFilter } from "../search/Search/filters/useFilter"
import { usePropertySearch } from "../search/Search/filters/PropertySearchFilter/usePropertySearch"
import { openSnackbarMessage } from "../../components/SnackbarMessage"

export default function PredefinedFilters({}) {
  const meta = useMeta()
  const propertySearch = usePropertySearch()
  const plantypeFilter = useFilter("plantype")
  const planstatusFilter = useFilter("planstatus")
  const trackEvent = useTrackArealplanerEvent(
    "forside_Filter",
    "button",
    undefined,
    true
  )

  const [displayMapDialog, setDisplayMapDialog] = useState(false)
  const [myPosition, setMyPosition] = useState<
    { lat: number; lng: number } | undefined
  >(undefined)

  const getPlanForMyLocation = async () => {
    trackEvent("Filter_MinPosisjon")
    findMyLocation(async (position: { lat: number; lng: number }) => {
      setMyPosition(position)
      setDisplayMapDialog(true)
    })
  }

  const getPlanerUnderArbeid = () => {
    trackEvent("Filter_PlanerUnderArbeid")
    planstatusFilter.set("0,1,2,6")
  }

  const getKommuneplan = () => {
    trackEvent("Filter_Kommuneplan")
    plantypeFilter.set("20,21,22")
  }

  const getGjeldendePlaner = () => {
    trackEvent("Filter_GjeldendePlaner")
    planstatusFilter.set("3")
  }

  if (meta.isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Box
        sx={theme => ({
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: 10
        })}>
        {myPosition && (
          <MapDialogMyPosition
            onCloseDialog={() => {
              setDisplayMapDialog(false)
            }}
            onLocationConfirmed={(coords: { lat: number; lng: number }) => {
              propertySearch.activatePropertyFilterFromPosition(coords, error =>
                openSnackbarMessage({ message: error, variant: "error" })
              )
              setDisplayMapDialog(false)
            }}
            open={displayMapDialog}
            calculatedPosition={myPosition}
          />
        )}

        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <InfoButton
            header="Planer for min posisjon"
            content="Vi finner eiendommen som er nærmest din posisjon og viser deg planer for eiendommen."
            Icon={<RoomIcon />}
            onClick={getPlanForMyLocation}
          />
          <InfoButton
            header="Kommuneplan"
            content="Se kommuneplan/kommunedelplaner, kommunens overordnede strategiske styringsdokument."
            Icon={<MapPolygonIcon />}
            onClick={getKommuneplan}
          />
        </Box>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <InfoButton
            header="Planer under arbeid"
            content="Se planer som er under arbeid. Dette kan være planer som er igangsatt eller planforslag."
            Icon={
              <SwapHorizontal style={{ fill: theme.palette.warning.main }} />
            }
            onClick={getPlanerUnderArbeid}
          />
          <InfoButton
            header="Gjeldende planer"
            content="Se gjeldende arealplaner i kommunen. Dette er planer som er endelig vedtatt."
            Icon={<CheckCircleIcon color="secondary" />}
            onClick={getGjeldendePlaner}
          />
        </Box>
      </Box>
      <Box
        sx={theme => ({
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: 10
        })}
      />
    </>
  )
}
