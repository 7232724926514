import { useQuery } from "react-query"
import { fetchToken } from "../../services/api"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { Token } from "./types"

const getToken = async (id: number) => {
  const { data } = await fetchToken(id)
  return data
}

export default function useToken(id: number) {
  const { notify } = useErrorHandler()
  const kundeId = useSelectedKundeId()

  const query = useQuery<Token>(
    ["tokens", { kundeId, id }],
    () => getToken(id),
    {
      onError: notify,
      enabled: !!kundeId,
      staleTime: undefined,
      cacheTime: undefined
    }
  )

  return {
    ...query,
    data: query.data || ({} as Token)
  }
}
