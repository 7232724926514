import * as React from "react"
import { Grid, Typography } from "@mui/material"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import useMeta from "../../../../hooks/meta/useMeta"
import { setTitle } from "../../../../utils/setTitle"
import TilstandLovreferanseReport from "./TilstandLovreferanseReport"
import TilstandPlantypeReport from "./TilstandPlantypeReport"

export default function Tilstand() {
  setTitle("Rapporter > Tilstand")
  const meta = useMeta()

  if (meta.isLoading) return <LoadingSpinner />
  return (
    <Grid xs={6} item={true}>
      <Typography variant="h2" fontSize={27.5}>
        Antall planobjekt per planstatus og lovreferanse
      </Typography>
      <Grid sx={{ marginBottom: 40 }} item={true}>
        <TilstandLovreferanseReport />
      </Grid>
      <Typography variant="h2" fontSize={27.5}>
        Antall planobjekt per planstatus og plantype
      </Typography>
      <Grid item={true}>
        <TilstandPlantypeReport />
      </Grid>
    </Grid>
  )
}
