import * as React from "react"

import { AppBar, Toolbar } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { setSearchedPropertyGeom } from "../../store/search/actions"

import Crest from "../Crest"
import DesktopContainer from "../../containers/DesktopContainer"
import MobileContainer from "../../containers/MobileContainer"
import Typography from "@mui/material/Typography"
import UserStatus from "./components/UserStatus"
import { connect } from "react-redux"
import { Route, Routes } from "react-router-dom"
import useIsDemoVersion from "../../hooks/useIsDemoVersion"

export interface TopbarProps {
  selectedCustomer: string
  displayTopbar: boolean
  mapFullscreen: boolean
  removeAllFilters: () => void
}

const Topbar = (props: TopbarProps) => {
  const location = useLocation()

  const { displayTopbar, selectedCustomer, removeAllFilters } = props
  const isDemoVersion = useIsDemoVersion()

  const removeFiltersAndClearInput = () => {
    removeAllFilters()
    document.getElementById("eraseBtn") &&
      (document.getElementById("eraseBtn") as any).click()
    document.getElementById("clear_name_id_filter") &&
      (document.getElementById("clear_name_id_filter") as any).click()
  }

  return (
    <AppBar
      position="fixed"
      id="topbar"
      sx={theme => ({
        zIndex: theme.zIndex.drawer + 1,
        padding: "0 !important",
        background: "#ffffff",
        ...(isDemoVersion && {
          background: "rgba(4, 4, 4, 0.75)",
          "& p": {
            color: "white"
          }
        }),
        ...(!displayTopbar && {
          [theme.breakpoints.down("sm")]: { display: "none" }
        })
      })}>
      <Toolbar>
        <Routes>
          <Route
            path="/:customerId/*"
            element={
              <>
                <DesktopContainer>
                  <Crest style={{ marginRight: "20px" }} />
                </DesktopContainer>
                <MobileContainer>
                  <Crest style={{ marginRight: "4px" }} hideLabel={true} />
                </MobileContainer>
              </>
            }
          />
        </Routes>

        <Link
          onClick={removeFiltersAndClearInput}
          to={selectedCustomer ? `/${selectedCustomer}` : "/"}
          aria-label="Hjem">
          <Typography
            variant="h1"
            color={isDemoVersion ? "textSecondary" : "secondary"}
            style={
              isDemoVersion
                ? {
                    fontWeight: 300,
                    whiteSpace: "nowrap",
                    color: "#fff"
                  }
                : {
                    fontWeight: 300,
                    whiteSpace: "nowrap"
                  }
            }>
            {isDemoVersion ? "Demo arealplaner.no" : "arealplaner.no"}
          </Typography>
        </Link>
        <div style={{ flexGrow: 1 }} />
        <UserStatus />
      </Toolbar>
    </AppBar>
  )
}

export default connect(null, dispatch => ({
  removeAllFilters: () => {
    dispatch(setSearchedPropertyGeom(undefined))
  }
}))(Topbar)
