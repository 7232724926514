import * as React from "react"
import DocumentListItem from "./DocumentListItem"
import { Dokument } from "../../hooks/dokumenter/types"

export interface DocumentListProps {
  documents: Dokument[]
  bgColor?: string
}

export function DocumentList({ documents, bgColor }: DocumentListProps) {
  return (
    <>
      {documents &&
        documents.map(document => {
          return (
            <DocumentListItem
              key={document.id}
              document={document}
              bgColor={bgColor || "default"}
            />
          )
        })}
    </>
  )
}

export default DocumentList
