import * as React from "react"

import { ApplicationState, ConnectedReduxProps } from "../../../store"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@mui/material"
import { hideDialog, showDialog } from "../../../store/dialog/actions"

import { Arealplan } from "../../../hooks/arealplaner/types"
import { Behandling } from "../../../hooks/behandlinger/types"
import BehandlingForm from "../BehandlingForm"
import CloseIcon from "@mui/icons-material/Close"
import { MapboxLayer } from "@norkart/nkm-mapbox-map"
import SelectMapPosition from "../../map/SelectMapPosition"
import { connect } from "react-redux"
import useCreateBehandling from "../../../hooks/behandlinger/useCreateBehandling"
import useUpdateBehandling from "../../../hooks/behandlinger/useUpdateBehandling"

export interface BehandlingFormDialogProps extends ConnectedReduxProps {
  behandling: Behandling
  planAreaLayer: MapboxLayer | undefined
  plan: Arealplan
  open: boolean
  focusRow: (id: string) => void
  onError: (errors) => void
}

const BehandlingFormDialog = ({
  behandling,
  plan,
  open,
  onError,
  focusRow,
  dispatch,
  planAreaLayer
}: BehandlingFormDialogProps) => {
  const getInitCoords = () => {
    return behandling.posisjon
      ? {
          x: behandling.posisjon.x,
          y: behandling.posisjon.y
        }
      : undefined
  }

  let behandlingensInitCoords = getInitCoords()

  const [behandlingCoords, setBehandlingCoords] = React.useState<
    | {
        x: number
        y: number
      }
    | undefined
  >()

  React.useEffect(() => {
    setBehandlingCoords(getInitCoords())
    behandlingensInitCoords = getInitCoords()
  }, [behandling])

  const [displaySelectMapPosition, setDisplaySelectMapPosition] =
    React.useState(false)

  const onClose = () => {
    hideDialog(dispatch)
  }

  const onDelete = () =>
    hideDialog(dispatch).then(() =>
      dispatch(
        showDialog({
          dialogType: "DELETE_BEHANDLING",
          dialogProps: { behandling, plan }
        })
      )
    )

  const onDeleteCoords = () => {
    setBehandlingCoords(undefined)
  }

  const onPositionSelected = (coords: { x: number; y: number } | undefined) => {
    setBehandlingCoords(coords)
  }

  return (
    <>
      {displaySelectMapPosition && (
        <SelectMapPosition
          plan={plan}
          title="Velg posisjon for behandlingen"
          initCoords={behandlingCoords}
          onPositionSelected={onPositionSelected}
          setDisplaySelectMapPosition={setDisplaySelectMapPosition}
        />
      )}
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        PaperProps={{ style: { overflowY: "visible" } }}
        disableAutoFocus={true}
        disableScrollLock={true}>
        <DialogTitle id="form-dialog-title">
          <Grid
            container={true}
            justifyContent="space-between"
            spacing={8}
            alignItems="center">
            <Grid item={true}>
              {behandling.id ? "Redigere behandling" : "Ny behandling"}
            </Grid>
            <Grid item={true}>
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <BehandlingForm
            onDeleteCoords={onDeleteCoords}
            setDisplaySelectMapPosition={setDisplaySelectMapPosition}
            behandling={behandling}
            plan={plan}
            onCancel={onClose}
            onDelete={onDelete}
            onClose={onClose}
            focusRow={focusRow}
            planAreaLayer={planAreaLayer}
            coords={behandlingCoords}
            setCoords={setBehandlingCoords}
            coordsHasChanged={behandlingensInitCoords !== behandlingCoords}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
export default connect((state: ApplicationState) => ({
  planAreaLayer: state.map.borderLayers?.combined
}))(BehandlingFormDialog)
