import _ from "lodash-es"
import { useQuery } from "react-query"
import { fetchKunde } from "../../services/api"
import { Kunde } from "./types"
import useSelectedKundeId from "./useSelectedKundeId"
import useErrorHandler from "../errors/useErrorHandler"

const getKunde = async (kundeId: string) => {
  return await fetchKunde(kundeId)
}

export default function useKunde() {
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()

  const query = useQuery<Kunde>(
    ["kundeStatus", { kundeId }],
    () => getKunde(kundeId),
    {
      enabled: !!kundeId,
      onError: notify,
      keepPreviousData: !!kundeId // hvis vi går fra en kunde til en annen så behold gammel data til ny data kommer inn
    }
  )
  return { ...query, data: query?.data, kunde: query?.data }
}
