import { useMutation, useQueryClient } from "react-query"
import { Token, TokenType } from "./types"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { updateToken } from "../../services/api"

type Props = { id: number; description: string }

export default function useUpdateToken(tokenType: TokenType) {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()

  return useMutation(
    async ({ id, description }: Props): Promise<Token> => {
      const { data } = await updateToken(id, description)
      return data
    },
    {
      onError: notify,
      onSuccess: () => {
        queryClient.invalidateQueries(["tokens", { kundeId, tokenType }])
      }
    }
  )
}
