import * as React from "react"
import { FieldRenderProps } from "react-final-form"
import StyledCheckbox from "./StyledCheckbox"

export default ({
  input: { checked, name, onChange, ...restInput },
  meta,
  ...rest
}: FieldRenderProps<any, HTMLElement>) => {
  return (
    <StyledCheckbox
      {...rest}
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={!!checked}
    />
  )
}
