import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import CloseIcon from "@mui/icons-material/Close"
import {AppUserProfileIcon, AppLogOutIcon} from '@norkart/toi-icons';
import React from "react"
import DesktopContainer from "../../../containers/DesktopContainer"
import MobileContainer from "../../../containers/MobileContainer"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import useCan from "../../../utils/auth/useCan"
import TopbarMenu from "./TopbarMenu"
import { useAuth } from "../../../utils/auth"
import { useSelector } from "react-redux"
import { getAuthorizationIsLoading } from "../../../store"
import useIsDemoVersion from "../../../hooks/useIsDemoVersion"

export function UserStatus() {
  const auth = useAuth()
  const can = useCan()
  const kundeId = useSelectedKundeId()
  const isLoading = useSelector(getAuthorizationIsLoading)
  const [openSignInDialog, setopenSignInDialog] = React.useState<boolean>(false)
  const isDemoVersion = useIsDemoVersion()

  const handleClose = () => {
    setopenSignInDialog(false)
  }
  const openDialog = () => {
    setopenSignInDialog(true)
  }

  const handleLogout = () => {
    setopenSignInDialog(false)
    auth.logout()
  }

  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px"
  }
  const renderUserName = () => (
    <Typography
      noWrap={true}
      style={{ marginRight: "10px" }}
      color={isDemoVersion ? "textSecondary" : "textPrimary"}>
      {auth.getUserName()}
    </Typography>
  )
  const renderLogInButton = () => (
    <Button
      onClick={openDialog}
      variant="outlined"
      style={
        isDemoVersion
          ? {
              whiteSpace: "nowrap",
              borderColor: "#ffffff",
              color: "#ffffff"
            }
          : { whiteSpace: "nowrap" }
      }
      startIcon={
        <AppUserProfileIcon blackWhite={isDemoVersion ? true : false} size={1} />
      }
      aria-label="Logg inn">
      Logg inn
    </Button>
  )
  const renderLogOutButton = () => (
    <Button
      onClick={handleLogout}
      variant="outlined"
      style={
        isDemoVersion
          ? {
              whiteSpace: "nowrap",
              color: "#ffffff",
              borderColor: "#ffffff",
              fontWeight: 300
            }
          : {
              whiteSpace: "nowrap",
              fontWeight: 400
            }
      }
      startIcon={
        <AppLogOutIcon blackWhite={isDemoVersion ? true : false} size={1} />
      }
      aria-label="Logg ut">
      Logg ut
    </Button>
  )

  const renderDialog = () => (
    <Dialog open={openSignInDialog} onClose={handleClose}>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <AppUserProfileIcon size={1} /> Intern bruker
          </div>
          <div>
            <IconButton
              onClick={e => {
                handleClose()
                e.stopPropagation()
              }}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={centerStyle}>
          <Typography style={{ maxWidth: "80ch" }}>
            Logg inn for å få innsyns- og/eller redigeringstilgang.
          </Typography>
        </div>
        <div style={centerStyle}>
          <Button
            color={"secondary"}
            variant="outlined"
            style={{ whiteSpace: "nowrap", width: "200px", height: "60px" }}
            onClick={auth.login}>
            Logg inn
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )

  if (isLoading) {
    return <CircularProgress />
  }
  return auth.isLoggedIn ? (
    <>
      <DesktopContainer>
        {renderUserName()}
        {can.view && kundeId ? (
          <TopbarMenu handleSignOut={handleLogout} />
        ) : (
          renderLogOutButton()
        )}
      </DesktopContainer>
      <MobileContainer>{renderLogOutButton()}</MobileContainer>
    </>
  ) : (
    <>
      {renderLogInButton()}
      {renderDialog()}
    </>
  )
}

export default UserStatus
