import { BBox2d, Coords, PlanLayerState } from "../types"
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon, Point, Polygon, Position } from "geojson"
import { FitBounds, MapboxLayer } from "@norkart/nkm-mapbox-map"
import {
  VertikalLevel,
  geom,
  getArealPlanVertnivAreas
} from "../../../services/apiPlanomraader"

import { Arealplan } from "../../../hooks/arealplaner/types"
import { WmsGeometry } from "../../../services/apiTjenestekatalogen"
import { getMapboxLayer } from "./getMapboxLayer"
import { mapConstants } from "./constants"
import { multiPolygon, polygon, feature as turfFeature } from "@turf/helpers"
import bbox from "@turf/bbox"
import pointsWithinPolygon from "@turf/points-within-polygon"

export async function fetchPlanLayerAsync(
  plan: Arealplan,
  customerId
): Promise<PlanLayerState> {
  try {
    const vertikalLevels: VertikalLevel[] | undefined =
      await getArealPlanVertnivAreas(plan.komnr, plan.planId, customerId)

    if (vertikalLevels?.length) {
      const planVertnivLayers = getVertnivLayers(vertikalLevels)
      const planAreaLayers = createPlanLayers(planVertnivLayers)

      return {
        layerNotFound: false,
        loading: false,
        error: false,
        planAreaLayers: planAreaLayers
      }
    } else {
      return {
        layerNotFound: true,
        error: !!vertikalLevels,
        loading: false
      }
    }
  } catch (e) {
    console.error(e)
    return {
      layerNotFound: true,
      error: true,
      loading: false
    }
  }
}

export const getPolygonsFromLayer = (
  feature: Feature<Polygon | MultiPolygon>
): Feature<Polygon>[] => {
  if (feature.geometry.type === "MultiPolygon") {
    const polygonFeatures: Feature<Polygon>[] = []

    feature.geometry.coordinates.forEach(coords => {
      polygonFeatures.push(
        turfFeature({
          type: "Polygon",
          coordinates: coords
        })
      ) 
    })
    return polygonFeatures

  } else if(feature.geometry.type === "Polygon") {
    return [feature as Feature<Polygon>]

  } else {
    throw new Error("Unsupported geometry type");
  }
}

export function createMapboxGeojsonLayer(
  id,
  data: Feature<MultiPolygon | Polygon>,
  fillColor,
  outlineColor,
  opacity = 0.8
): MapboxLayer {
  const paint: MapboxLayer["paint"] = {
    "fill-color": fillColor,
    "fill-opacity": opacity,
    "fill-outline-color": outlineColor
  }
  return {
    id: id,
    type: "fill",
    source: {
      type: "geojson",
      data: data as any
    },
    paint: paint,
    properties: {
      beforeLayerId: mapConstants.defaultBeforeLayerId
    }
  }
}

export function mapGeomToWmsGeometry(geom: geom): WmsGeometry {
  return {
    Positions: geom?.positions.map(pos => {
      return { X: pos.x, Y: pos.y }
    })
  }
}

export function getVertnivLayers(
  vertikalLevels: VertikalLevel[]
): MapboxLayer[] {
  return vertikalLevels.map((vertikalLevel, index) => {
    const { interiors, exterior } = vertikalLevel
    const mappedExterior = exterior ? mapGeomToWmsGeometry(exterior) : undefined
    const mappedInteriors = interiors
      ? interiors.map(ext => mapGeomToWmsGeometry(ext))
      : undefined

    const vertNiv = vertikalLevel.vertikalnivaa

    let mapboxLayer: MapboxLayer = getMapboxLayer(
      mappedExterior,
      mappedInteriors,
      "vertniv_" + vertNiv + "_" + index,
      {
        "fill-color": "#ffffff",
        "fill-opacity": 1
      },
      mapConstants.defaultBeforeLayerId
    )

    mapboxLayer = {
      ...mapboxLayer,
      properties: { ...mapboxLayer.properties, vertNiv: vertNiv } as any
    }

    return mapboxLayer
  })
}

export function createPlanLayers(vertikalLayers: MapboxLayer[]) {
  let combinedLayer: Position[][][] = []
  let vertNivLayer1: Position[][][] = []
  let vertNivLayer2: Position[][][] = []
  let vertNivLayer3: Position[][][] = []
  let vertNivLayer4: Position[][][] = []
  let vertNivLayer5: Position[][][] = []

  vertikalLayers.forEach(vertikalLayer => {
    const feature = (vertikalLayer.source as mapboxgl.GeoJSONSourceRaw)
      .data! as Feature<Polygon, GeoJsonProperties>

    combinedLayer.push(feature.geometry.coordinates)
    if (vertikalLayer.id.startsWith("vertniv_1")) {
      vertNivLayer1.push(feature.geometry.coordinates)
    } else if (vertikalLayer.id.startsWith("vertniv_2")) {
      vertNivLayer2.push(feature.geometry.coordinates)
    } else if (vertikalLayer.id.startsWith("vertniv_3")) {
      vertNivLayer3.push(feature.geometry.coordinates)
    } else if (vertikalLayer.id.startsWith("vertniv_4")) {
      vertNivLayer3.push(feature.geometry.coordinates)
    } else if (vertikalLayer.id.startsWith("vertniv_5")) {
      vertNivLayer3.push(feature.geometry.coordinates)
    }
  })

  let vertniv1: Feature<MultiPolygon | Polygon> | undefined = undefined
  let vertniv2: Feature<MultiPolygon | Polygon> | undefined = undefined
  let vertniv3: Feature<MultiPolygon | Polygon> | undefined = undefined
  const combined: Feature<MultiPolygon | Polygon> =
    combinedLayer.length > 1
      ? multiPolygon(combinedLayer)
      : polygon(combinedLayer[0])

  if (vertNivLayer1.length) {
    vertniv1 =
      vertNivLayer1.length > 1
        ? multiPolygon(vertNivLayer1)
        : polygon(vertNivLayer1[0])
  }
  if (vertNivLayer2.length) {
    vertniv2 =
      vertNivLayer2.length > 1
        ? multiPolygon(vertNivLayer2)
        : polygon(vertNivLayer2[0])
  }
  if (vertNivLayer3.length) {
    vertniv3 =
      vertNivLayer3.length > 1
        ? multiPolygon(vertNivLayer3)
        : polygon(vertNivLayer3[0])
  }

  return {
    combined,
    vertniv1,
    vertniv2,
    vertniv3,
    vertNivLayer4,
    vertNivLayer5
  }
}

export function createFitBoundsForFeature(planLayer: Feature<MultiPolygon | Polygon>) {
  const planAreaBbox = bbox(planLayer)

  return {
    bounds: planAreaBbox as BBox2d,
    options: { padding: {left:10,right:10,top:10,bottom:10}, linear: true, maxZoom: 20 }
  }
}

export function isWithinLayer(point: Coords, layer: MapboxLayer | undefined) {
  if (!layer) return false
  
  const featureCollection: FeatureCollection<Point> = {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [point.lng, point.lat]
      },
      properties: {}
    }]
  };

  const feature = (layer.source as mapboxgl.GeoJSONSourceRaw).data! as Feature<
    Polygon,
    GeoJsonProperties
  >

  const layerPolygons = getPolygonsFromLayer(feature)

  return layerPolygons?.some(layer => pointsWithinPolygon (featureCollection, layer))
}

export function isWithinMultiPolygon(
  point: Coords,
  feature: Feature<MultiPolygon>
) {
  const featureCollection: FeatureCollection<Point> = {
    type: "FeatureCollection",
    features: [{
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [point.lng, point.lat]
      },
      properties: {}
    }]
  };

  const layerPolygons = getPolygonsFromLayer(feature)

  return layerPolygons?.some(layer => pointsWithinPolygon (featureCollection, layer))
}
