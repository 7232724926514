import { useMutation, useQueryClient } from "react-query"
import { Token, TokenType } from "./types"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { createToken } from "../../services/api"

type Props = { description: string }

export default function useCreateToken(tokenType: TokenType) {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()

  return useMutation(
    async ({ description }: Props): Promise<Token> => {
      const { data } = await createToken(description, tokenType)
      return data
    },
    {
      onError: notify,
      onSuccess: () => {
        queryClient.invalidateQueries(["tokens", { kundeId, tokenType }])
      }
    }
  )
}
