import {
  Button,
  ColorObject,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconProps,
  PaletteColor,
  ThemedProps,
  Typography
} from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import { ColorPartial, CommonColors } from "@mui/material/styles/createPalette"
import React from "react"

type DialogGenericErrorProps = {
  open: boolean
  title: string
  content: string
  typeOfMessage: "warning" | "error" | "info" | "success"
  handleClose: () => void
}

function DialogGenericMessage({ ...props }: DialogGenericErrorProps) {
  return (
    <Dialog open={props.open}>
      <DialogTitle sx={{ gap: 1, display: "flex", alignItems: "center" }}>
        <ErrorIcon color={props.typeOfMessage} fontSize="large" />
        <Typography variant="h5">{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ paddingLeft: 1 }}>{props.content}</Typography>
      </DialogContent>
      <DialogActions
        sx={{
          paddingRight: "24px",
          paddingBottom: "16px"
        }}>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => props.handleClose()}>
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogGenericMessage
