import * as React from "react"
import { MenuItem, styled } from "@mui/material"
import norkart_logo from "../../../assets/images/logo_norkart.png"
import { ChevronRight } from "@mui/icons-material"
import { Kunde } from "../../../hooks/kunder/types"
import { useNavigate } from "react-router-dom"

interface CustomerSelectorItemProps {
  customer: Kunde
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  height: "58px",
  marginBottom: "10px",
  padding: "5px 15px",
  border: "1px solid",
  borderColor: "rgba(0, 0, 0, 0.23)",
  "&:hover": {
    borderColor: theme.palette.secondary.main,
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  },
  "&:focus-visible": {
    borderColor: theme.palette.secondary.main,
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  }
}))

const StartIcon = styled("img")({
  width: "26,66px",
  height: "32px",
  alignItems: "flex-start",
  marginLeft: -4,
  marginRight: 8
})

const EndIcon = styled(ChevronRight)({ marginLeft: "auto" })

function CustomerSelectorItem({ customer }: CustomerSelectorItemProps) {
  const navigate = useNavigate()
  function handleClick() {
    navigate(`/${customer.id}`)
  }
  return (
    <StyledMenuItem tabIndex={0} onClick={handleClick}>
      <StartIcon
        src={customer.crest != null ? customer.crest : norkart_logo}
        alt="kommunevåpen"
      />
      {customer.navn}
      <EndIcon />
    </StyledMenuItem>
  )
}

export default CustomerSelectorItem
