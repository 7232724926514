import {
  useAppInsightsContext,
  useTrackEvent
} from "@microsoft/applicationinsights-react-js"
import * as React from "react"

export default function useTrackArealplanerEvent(
  component: string,
  eventType: "button" | "event",
  eventData?: string,
  noState: boolean = false
) {
  const appInsight = useAppInsightsContext()
  const trackEvent = useTrackEvent(appInsight, component, {}, true)

  return (evData: String) => {
    if (evData !== "") {
      if (noState) {
        appInsight.trackEvent({ name: component }, { [eventType]: evData })
      } else trackEvent({ [eventType]: evData })
    }
  }
}
