import * as React from 'react';
import { PlanFilterButton } from './PlanFilterButton';
import { PlanpratMetadata } from '../types';

type Props = {
  selectedMetadata: PlanpratMetadata[];
  setSelectedMetadata: (planpratMetadata: PlanpratMetadata[]) => void;
};

export const PlanFilter = ({ selectedMetadata, setSelectedMetadata }: Props) => {
  return (
    // <PlanFilterButton selectedMetadata={selectedMetadata} setSelectedMetadata={setSelectedMetadata} />
    null
  );
};
