import { Feature } from "geojson"
import { MapboxLayer } from "@norkart/nkm-mapbox-map"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import { formatDate } from "../../../utils/formatDate"
import { toLatLng } from "../../behandling/BehandlingFormDialog/helpers"

export function getDispLayers(
  dispensasjoner: Dispensasjon[],
  referansesystemKode
) {
  const negativeLayer = getLayer(
    dispensasjoner.filter(d => d.vedtakId == 9),
    "negative-marker",
    "negative-marker",
    referansesystemKode
  )
  const positiveLayer = getLayer(
    dispensasjoner.filter(d => d.vedtakId == 1 || d.vedtakId == 2),
    "positive-marker",
    "positive-marker",
    referansesystemKode
  )
  const otherLayer = getLayer(
    dispensasjoner.filter(
      d => d.vedtakId != 1 && d.vedtakId != 2 && d.vedtakId != 9
    ),
    "neutral-marker",
    "neutral-marker",
    referansesystemKode
  )
  return [negativeLayer, positiveLayer, otherLayer]
}

function getLayer(
  disps: Dispensasjon[],
  icon: string,
  layerId: string,
  referansesystemKode
) {
  const features: Feature[] = []
  disps.forEach(p => {
    if (p.posisjon?.x && p.posisjon?.y) {
      const coords = toLatLng(
        { x: p.posisjon.x, y: p.posisjon.y },
        referansesystemKode
      )
      if (coords) {
        const feature: Feature = {
          id: p.id,
          type: "Feature",
          properties: {
            description:
              "<strong>" +
              formatDate(p.vedtaksdato) +
              "</strong><p><strong>Dispensasjonstype: </strong>" +
              p.dispensasjonType +
              "<br />" +
              (p.beskrivelse
                ? "<strong>Beskrivelse: </strong>" + p.beskrivelse
                : "") +
              "</p>"
          },
          geometry: {
            type: "Point",
            coordinates: [coords.lng, coords.lat]
          }
        }

        features.push(feature)
      }
    }
  })

  const layout = {
    "icon-image": icon,
    "icon-allow-overlap": true,
    "icon-anchor": "bottom"
  } as any

  const layer: MapboxLayer = {
    id: layerId,
    type: "symbol",
    source: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: features
      }
    },
    layout: layout
  }

  return layer
}
