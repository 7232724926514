import * as React from "react"
import MobileContainer from "../../../containers/MobileContainer"
import { Box, Button, Grid, Typography } from "@mui/material"
import HelpText from "../../../components/HelpText/HelpText"
import DesktopContainer from "../../../containers/DesktopContainer"
import { helpObject } from "../../../help/helpObject"
import { OpenInNew } from "@mui/icons-material"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useCan from "../../../utils/auth/useCan"
import { useDispatch } from "react-redux"
import { showDialog } from "../../../store/dialog/actions"
import ExpansionPanel from "../../../components/ExpansionPanel"
import sanitizeHtml from "sanitize-html"

const PlanDescription = () => {
  const { data: plan } = useArealplan()
  const dispatch = useDispatch()
  const can = useCan()

  return (
    <>
      <MobileContainer>
        <ExpansionPanel title={"Beskrivelse"}>
          <Typography whiteSpace={"pre-wrap"}>
            {plan.planBeskrivelse}
          </Typography>
        </ExpansionPanel>
      </MobileContainer>
      <DesktopContainer>
        {plan.planBeskrivelse && plan.planBeskrivelse.length > 0 ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={10}
            maxWidth={1000}
            marginBottom={40}>
            {can.edit && (
              <Button
                variant="outlined"
                aria-label={"Endre beskrivelse"}
                sx={{ width: "fit-content" }}
                onClick={() =>
                  dispatch(
                    showDialog({
                      dialogType: "EDIT_DESCRIPTION",
                      dialogProps: { plan }
                    })
                  )
                }>
                <>
                  <OpenInNew /> Endre beskrivelse
                </>
              </Button>
            )}
              <Box
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(plan.planBeskrivelse) }}
          />
          </Box>
        ) : (
          <Grid
            container={true}
            direction={"column"}
            style={{ display: "flex", marginBottom: 40 }}>
            <HelpText
              style={{ marginRight: 50 }}
              helpText={helpObject.Faner.Beskrivelse.hovedtekst}
            />
            <Button
              variant="outlined"
              aria-label={"Legg til beskrivelse"}
              sx={{ width: "fit-content" }}
              onClick={() =>
                dispatch(
                  showDialog({
                    dialogType: "EDIT_DESCRIPTION",
                    dialogProps: { plan }
                  })
                )
              }>
              <>
                <OpenInNew /> Legg til beskrivelse
              </>
            </Button>
          </Grid>
        )}
      </DesktopContainer>
    </>
  )
}

export default PlanDescription
