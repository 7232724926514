import React from "react"
import { Box } from "@mui/material"
import DatePicker from "../../../../components/DatePicker"
import { parseDateToDatePickerFormat } from "../../../../utils/parseDate"
import { format } from "date-fns"
import { useFilter } from "./useFilter"

export const DateFilter = () => {
  const fromDateFilter = useFilter("fromDate" )
  const toDateFilter = useFilter("toDate")

  const fromFilterValue = fromDateFilter.get() || ""
  const toFilterValue = toDateFilter.get() || ""

  const handleFromChange = (date: Date | null) => {
    date ? fromDateFilter.set(format(date, "yyyy-MM-dd")) : fromDateFilter.clear()
  }

  const handleToChange = (date: Date | null) => {
    date ? toDateFilter.set(format(date, "yyyy-MM-dd")) : toDateFilter.clear()
  }

  const getMinDate = (): Date | undefined => {
    if (fromFilterValue) {
      return new Date(parseDateToDatePickerFormat(fromFilterValue))
    }
  }

  const getMaxDate = (): Date | undefined => {
    if (toFilterValue) {
      return new Date(parseDateToDatePickerFormat(toFilterValue))
    }
  }

  return (
    <Box sx={{ display: "flex", gap:5, flexWrap:'wrap'}}>
      <DatePicker
        input={{
          name: "Start dato",
          value: fromFilterValue ? parseDateToDatePickerFormat(fromFilterValue) : "",
          onChange: handleFromChange
        }}
        maxDate={getMaxDate()}
        label={"Fra dato"}
        onClear={() => fromDateFilter.clear()}
      />
      <DatePicker
        input={{
          name: "Start dato",
          value: toFilterValue ? parseDateToDatePickerFormat(toFilterValue) : "",
          onChange: handleToChange
        }}
        minDate={getMinDate()}
        label={"Til dato"}
        onClear={() => toDateFilter.clear()}
      />
    </Box>
  )
}
