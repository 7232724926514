import React from "react"
import HelpButton from "../../../../components/HelpButton/HelpButton"
import { helpObject } from "../../../../help/helpObject"
import CheckboxSectionAlt from "./CheckboxSection"
import useSearchHelpers from "../useSearchHelpers"
import { useFilter } from "./useFilter"
import FilterLabel from "./FilterLabel"
import _ from "lodash-es"

export const PlantypeFilter = () => {
  const helpers = useSearchHelpers()
  const options = helpers.plantyperCheckboxList
  const filter = useFilter("plantype")

  const getSelectedFilters = (): string[] => {
    return filter.get()?.split(",") || []
  }

  const handleChange = (value: string) => {
    filter.set(value)
  }

  return (
    <>
      <FilterLabel>
        Velg plantype
        <HelpButton
          helpText={helpObject.AvansertFilter.Plantype}
          type="PlantypeFilter"
        />
      </FilterLabel>
      <CheckboxSectionAlt
        options={options}
        selectedOptions={getSelectedFilters()}
        keyCol="label"
        valueCol="value"
        handleChange={handleChange}
      />
    </>
  )
}
