import React, { useEffect, useState } from "react"
import MenuButton from "../MenuButton"
import {MapLayersIcon} from '@norkart/toi-icons';
import DesktopContainer from "../../../../../containers/DesktopContainer"
import { handleLayerClick, mapLayerMenuStyle } from "./helper"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getMapState, getSearchState } from "../../../../../store"
import { AvailableWmsLayer } from "../../../types"
import { addWmsLayer, removeWmsLayer } from "../../../../../store/map/actions"
import { SearchState } from "../../../../../store/search/types"
import KartlagMenuItem from "./KartlagMenuItem"
import { usePropertySearch } from "../../../../search/Search/filters/PropertySearchFilter/usePropertySearch"

type Props = {
  defaultClosed?: boolean
  setDisplaySearchedPropertyGeom: (boolean) => void
  displaySearchedPropertyGeom: boolean
  showAllLayers?: boolean
}

const AndreKartlag = ({
  defaultClosed,
  setDisplaySearchedPropertyGeom,
  displaySearchedPropertyGeom,
  showAllLayers = false
}: Props) => {
  const mapState = useSelector(getMapState)
  const searchState = useSelector(getSearchState)
  const dispatch = useDispatch()
  const [mapLayersMenuVisible, setMapLayersMenuVisible] = useState<boolean>(
    defaultClosed ? false : true
  )
  const propertySearch = usePropertySearch()
  const [searchedPropertyText, setSearchedPropertyText] =
    useState<string>("Valgt eiendom")

  const otherWmsLayers = mapState.wmsLayerState.otherWmsLayers
  const drawnWmsLayers =
    otherWmsLayers && otherWmsLayers.filter(lay => lay.isVisible)

  useEffect(() => {
    setSearchedPropertyText(getSearchPropertyText(searchState))
  }, [searchState])

  useEffect(() => {
    if (!showAllLayers) {
      otherWmsLayers?.forEach(layer => dispatch(removeWmsLayer(layer)))
    }
  }, [showAllLayers])

  const handlePropertyLayerClick = () => {
    setDisplaySearchedPropertyGeom(!displaySearchedPropertyGeom)
  }

  function getSearchPropertyText(searchState: SearchState) {
    const propertyLabel = propertySearch.getPropertyLabel()
    if (propertyLabel) {
      return propertyLabel
    } else {
      return "Valgt eiendom"
    }
  }

  return searchState.searchedPropertyGeom || showAllLayers ? (
    <div className="Kartlag section">
      <MenuButton
        name={`Kartlag for naboplaner`}
        Icon={<MapLayersIcon />}
        onClick={() => setMapLayersMenuVisible(!mapLayersMenuVisible)}
        isOpen={mapLayersMenuVisible}
      />
      {mapLayersMenuVisible && (
        <Box sx={theme => mapLayerMenuStyle(theme)}>
          {searchState.searchedPropertyGeom && searchedPropertyText && (
            <KartlagMenuItem
              visible={displaySearchedPropertyGeom}
              text={
                searchedPropertyText.length > 25
                  ? "Eiendom: " + searchedPropertyText.substring(0, 25) + ".."
                  : "Eiendom: " + searchedPropertyText
              }
              handleToggle={handlePropertyLayerClick}
              key="property"
            />
          )}
          {showAllLayers &&
            otherWmsLayers?.map((layer, index) => (
              <KartlagMenuItem
                visible={!!drawnWmsLayers?.includes(layer)}
                text={layer.displayName}
                handleToggle={() =>
                  handleLayerClick(
                    layer,
                    otherWmsLayers,
                    (layer: AvailableWmsLayer) => {
                      dispatch(addWmsLayer(layer))
                    },
                    (layer: AvailableWmsLayer) => {
                      dispatch(removeWmsLayer(layer))
                    }
                  )
                }
                key={index}
              />
            ))}
        </Box>
      )}
    </div>
  ) : (
    <></>
  )
}
export default AndreKartlag
