import * as React from "react"
import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import Application from "./application"
import "./bodystyle.css"
import theme from "./theme"
import { norkartIdAuthData } from "./utils/auth"
import { BrowserRouter } from "react-router-dom"
import { NkMsalProvider } from "@norkart/nk-msal"
import { Provider } from "react-redux"
import { ApplicationState } from "./store"
import setupStore from "./setupStore"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, // 10 min
      cacheTime: 1000 * 60 * 30 //  30 min
    }
  }
})

const initialState: ApplicationState = window["initialReduxState"]
export const store = setupStore(initialState)

function Main() {
  return (
    <NkMsalProvider norkartIdAuthData={norkartIdAuthData}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <BrowserRouter>
              <Application />
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </NkMsalProvider>
  )
}

export default Main
