import * as React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography
} from "@mui/material"
import {
  Planforhold,
  Arealplan,
  ArealplanId
} from "../../../hooks/arealplaner/types"
import { ConnectedReduxProps } from "../../../store"
import { hideDialog } from "../../../store/dialog/actions"
import axios, { AxiosPromise } from "axios"
import { updatePartialArealplan } from "../../../services/api"
import { openSnackbarMessage } from "../../../components/SnackbarMessage"
import SpinnerButton from "../../../components/SpinnerButton"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import useMeta from "../../../hooks/meta/useMeta"

export interface PlanRelationshipConfirmUpdatePlanDialogProps
  extends ConnectedReduxProps {
  open: boolean
  planforhold: Planforhold[]
  plan: Arealplan
}

export interface PlanforholdExtended extends Planforhold {
  shouldUpdate: boolean
  newPlanStatusId: number
  newPlanStatus: string
}

const PlanRelationshipConfirmUpdatePlanDialog: React.FC<PlanRelationshipConfirmUpdatePlanDialogProps> = ({
  open,
  dispatch,
  ...props
}: PlanRelationshipConfirmUpdatePlanDialogProps) => {
  const meta = useMeta()
  const { data: plan, refetch } = useArealplan()
  const [planforhold, setPlanforhold] = React.useState(
    [] as PlanforholdExtended[]
  )

  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    setPlanforhold(
      props.planforhold.map(pf => {
        const newPlanStatusId = pf.id == 2 ? 8 : 5
        return {
          ...pf,
          shouldUpdate: true,
          newPlanStatusId: newPlanStatusId,
          newPlanStatus: meta.getPlanstatus(newPlanStatusId)
        }
      })
    )
  }, [props.planforhold])

  function handleClose() {
    hideDialog(dispatch)
  }

  function updateArealplanStatus(
    ArealplanId: ArealplanId,
    PlanStatusId: number
  ): AxiosPromise {
    return updatePartialArealplan({
      ArealplanId,
      PlanStatusId
    })
  }

  function handleSave() {
    setSubmitting(true)
    let reqs = [] as AxiosPromise[]
    planforhold
      .filter(pf => pf.shouldUpdate == true)
      .map(p =>
        reqs.push(updateArealplanStatus(p.arealplanId, p.newPlanStatusId))
      )
    axios
      .all(reqs)
      .catch(err =>
        openSnackbarMessage({
          message: "Noe gikk galt under oppdateringen",
          variant: "error"
        })
      )
      .finally(() => {
        handleClose()
        setSubmitting(false)
        refetch()
      })
  }

  function toggleShouldUpdate(pf: PlanforholdExtended) {
    let updatedPlanforhold = [...planforhold]
    const index = planforhold.indexOf(pf)
    let updatedPf = {
      ...updatedPlanforhold[index],
      shouldUpdate: !updatedPlanforhold[index].shouldUpdate
    }
    updatedPlanforhold[index] = updatedPf
    setPlanforhold(updatedPlanforhold)
  }

  function renderPlanforholdRow(pf: PlanforholdExtended) {
    return (
      <TableRow key={`${pf.arealplanId}-${pf.id}`}>
        <TableCell>{meta.getPlanforholdstypeForloper(pf.id)}</TableCell>
        <TableCell>{pf.planId}</TableCell>
        <TableCell>{pf.planNavn}</TableCell>
        <TableCell>{pf.planType}</TableCell>
        <TableCell>{pf.planStatus}</TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <span>{pf.newPlanStatus}</span>
            <Checkbox
              name="shouldUpdate"
              checked={pf.shouldUpdate}
              onChange={() => toggleShouldUpdate(pf)}
            />
          </div>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>Endre planstatus</DialogTitle>
      <DialogContent>
        <Typography>Kryss av hvis du ønsker å endre planstatus</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type forhold</TableCell>
              <TableCell>PlanId</TableCell>
              <TableCell>Plannavn</TableCell>
              <TableCell>Plantype</TableCell>
              <TableCell>Eksisterende planstatus</TableCell>
              <TableCell>Aksepter ny planstatus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planforhold.map(pf => renderPlanforholdRow(pf))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <SpinnerButton
          onClick={handleSave}
          loading={submitting}
          disabled={submitting}
          label="Ok"
        />
      </DialogActions>
    </Dialog>
  )
}

export default PlanRelationshipConfirmUpdatePlanDialog
