import * as React from 'react'
import { useQuery } from 'react-query'
import { fetchInnstillinger } from '../../services/api'
import useErrorHandler from '../errors/useErrorHandler'
import { Innstilling } from '../meta/types'

const getInnstillinger = async () => {
    const { data } = await fetchInnstillinger()
    return data
  }

export default function useFrontendSettings() {
    const { notify } = useErrorHandler()
    const query = useQuery<Innstilling[] | undefined>(
        ["frontendSettings"],
        getInnstillinger,
        {
        enabled: true,
        onError: notify,
        staleTime: undefined,
        cacheTime: undefined,
        refetchInterval:30000
        }
    )

    const innstillinger = React.useMemo(() => {
        if(query.data)  return query.data
    },[query.data])

    const kundeInstillinger = React.useMemo(() => {
        if(query.data)  {
            const kundeInstillingerExists = query.data.filter( i => i.kundeinnstilling).length > 0 ? query.data.filter( i => i.kundeinnstilling) : undefined 
        
            return kundeInstillingerExists
        }
    },[query.data])

    return {
        ...query,
        data:  innstillinger,
        kundeInstillinger: kundeInstillinger
    }
      

}