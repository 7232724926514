import { Typography } from "@mui/material"
import classNames from "classnames"
import React, { useEffect } from "react"
import { Field, FieldProps } from "react-final-form"
import FormError from "../FormError"
import FilterableDropdown from "./FilterableDropdown"

export interface FilterableDropdownFieldProps
  extends FieldProps<any, any, HTMLElement> {
  name: string
  options: Object[]
  keyCol: string
  valueCol: string
  title?: string
  optional?: boolean
  disabled?: boolean
  displayId?: boolean
  autoFocus?: boolean
  nullOption?: boolean
  width?: number | string
  hideMinWidth?: boolean
  onBlur?: () => void
}

function FilterableDropdownField({
  name,
  options,
  keyCol,
  valueCol,
  title,
  width,
  onBlur,
  hideMinWidth,
  optional = false,
  disabled = false,
  displayId = true,
  autoFocus = false,
  nullOption = true,
  fieldProps = {},
  ...rest
}: FilterableDropdownFieldProps) {
  const dropdownId = `${name}-dropdown`
  useEffect(() => {
    if (autoFocus) document?.getElementById(dropdownId)?.focus()
  }, [autoFocus, name])

  return (
    <Field
      name={name}
      subscription={{ value: true, touched: true, error: true, initial: true }}
      {...fieldProps}
      {...rest}
      render={({ input, meta }) => (
        <div>
          {title && (
            <Typography
              className={classNames({
                importantFormLabel: !(optional || disabled)
              })}>
              <label htmlFor={input.name}>{title}</label>
            </Typography>
          )}
          <FilterableDropdown
            id={dropdownId}
            {...input}
            disabled={disabled}
            error={meta.error && meta.touched}
            onBlur={onBlur}
            nullOption={nullOption}
            keyCol={keyCol}
            valueCol={valueCol}
            displayId={displayId}
            options={options}
          />
          <FormError name={name} />
        </div>
      )}
    />
  )
}

export default FilterableDropdownField
