import { Button, Grid } from "@mui/material"
import LinearProgressWithLabel from "./LinearProgressWithLabel"
import * as React from "react"

interface KonsistenssjekkIsRunningProps {
  currentPage: number | undefined
  totalPages: number | undefined
  showRunningReport: boolean
  setShowRunningReport: React.Dispatch<React.SetStateAction<boolean>>
}

const calculateProgress = (current, total) => {
  const calculatedProgress = (current / total) * 100
  return calculatedProgress
}

export default function KonsistenssjekkIsRunning({
  currentPage,
  totalPages,
  showRunningReport,
  ...props
}: KonsistenssjekkIsRunningProps) {
  const [reportProgress, setReportProgress] = React.useState(0)

  React.useEffect(() => {
    if (!currentPage && !totalPages) return
    else setReportProgress(calculateProgress(currentPage, totalPages))
  }, [currentPage])

  return (
    <Grid>
      <LinearProgressWithLabel value={reportProgress} />
      {!showRunningReport ? (
        <Button
          style={{ marginBottom: 10 }}
          variant="outlined"
          color="secondary"
          onClick={() => props.setShowRunningReport(!showRunningReport)}>
          Se midlertidig rapport
        </Button>
      ) : (
        <Button
          style={{ marginBottom: 10 }}
          variant="outlined"
          color="secondary"
          onClick={() => props.setShowRunningReport(!showRunningReport)}>
          Se siste ferdige rapport
        </Button>
      )}
    </Grid>
  )
}
