import { Drawer, Link } from "@mui/material"
import React from "react"
import { Arealplan } from "../../hooks/arealplaner/types"
import { useFilters } from "../search/Search/filters"
import { usePropertySearch } from "../search/Search/filters/PropertySearchFilter/usePropertySearch"
import Search from "../search/Search/Search"
import PredefinedFilters from "./PredefinedFilters"
import SearchResultBrowse from "./SearchResultBrowse"

type Props = {
  handlePlanClick: (plan: Arealplan) => void
  handlechangeToList: () => void
}

export default function SidebarSearch({
  handlePlanClick,
  handlechangeToList
}: Props) {
  const filters = useFilters()
  const propertySearch = usePropertySearch()

  return (
    <Drawer
      open={true}
      variant="persistent"
      sx={theme => ({
        "& .MuiDrawer-paper": {
          marginTop: "64px", // spacing to ensure drawer content is not covered by topbar
          paddingX: "20px",
          boxSizing: "border-box",
          backgroundColor: theme.palette.background.default,
          height: "calc(100% - 64px)"
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          "& .MuiDrawer-paper": {
            width: "100%"
          }
        },
        [theme.breakpoints.up("md")]: {
          width: "23%",
          minWidth: "400px",
          "& .MuiDrawer-paper": {
            width: "23%",
            minWidth: "400px"
          }
        }
      })}>
      <Link
        sx={{
          cursor: "pointer",
          width: 200,
          marginTop: 15,
          marginBottom: 15
        }}
        onClick={handlechangeToList}>
        Bytt til listesøk
      </Link>
      <Search />

      {filters.activeFiltersFromUrl.length || propertySearch.get() ? (
        <SearchResultBrowse
          //selectedPlan={selectedPlan}
          handlePlanClick={handlePlanClick}
        />
      ) : (
        <PredefinedFilters />
      )}
    </Drawer>
  )
}
