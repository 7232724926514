import { Autocomplete, Grid } from "@mui/material"
import * as React from "react"
import DateRangePickerMui, {
  StartOrEndDate
} from "../../../../components/DatePicker/DateRangePickerMui/DateRangePickerMui"
import { ToiLikeStyledTextField } from "../../../../components/TextField/ToiLikeStyledTextField"
import { Planforholdtype } from "../../../../hooks/meta/types"
import { useFilterQL } from "../ReportTools/FilterQL"

type FilterPlanforholdProps = {
  setFilterValues: React.Dispatch<any>
  planforholdtyper?: Planforholdtype[]
  clearFilter: boolean
}

type SelectedFiltersProps = {
  planforhold?: Planforholdtype[]
  ikraftforloperFra?: Date
  ikraftforloperTil?: Date
}

export function PlanforholdFilter({
  setFilterValues,
  planforholdtyper,
  clearFilter
}: FilterPlanforholdProps) {
  const filterQl = useFilterQL()
  const [selectedFilters, setSelectedFilters] = React.useState<
    SelectedFiltersProps | undefined
  >()

  React.useEffect(() => {
    if (planforholdtyper)
      setSelectedFilters({
        planforhold: planforholdtyper.filter(f => f.id === 4 || f.id === 5)
      })
  }, [])

  const handleDatoChange = (dates: StartOrEndDate | undefined) => {
    setSelectedFilters({
      ...selectedFilters,
      ikraftforloperTil: dates?.end,
      ikraftforloperFra: dates?.start
    })
  }

  //Lager array av objekter som skal brukes som filtere
  const handleFiltering = React.useCallback(
    (filters: SelectedFiltersProps | undefined) => {
      let newFilter: any[] = []
      filters?.ikraftforloperFra &&
        newFilter.push({
          planIdTilNavigation: {
            iKraft: {
              gte: filters.ikraftforloperFra.toDateString()
            }
          }
        })

      filters?.ikraftforloperTil &&
        newFilter.push({
          planIdTilNavigation: {
            iKraft: {
              lte: filters.ikraftforloperTil.toDateString()
            }
          }
        })

      newFilter.push({
        or: filters?.planforhold?.map(pf => ({
          planforholdTypeId: {
            eq: pf.id
          }
        }))
      })
      if (newFilter.length === 0) filterQl.updateFilter(undefined)
      else filterQl.updateFilter(newFilter)
    },
    [selectedFilters]
  )

  React.useMemo(() => {
    handleFiltering(selectedFilters)
  }, [
    selectedFilters?.ikraftforloperFra,
    selectedFilters?.ikraftforloperTil,
    selectedFilters?.planforhold
  ])

  //Setter oppdaterte filterverdier så det kan brukes som variabler i graphql request
  React.useEffect(() => {
    setFilterValues(filterQl.filterReturn)
  }, [filterQl.filterReturn])

  React.useEffect(() => {
    if (clearFilter)
      setSelectedFilters({
        planforhold: planforholdtyper?.filter(pf => pf.id === 4 || pf.id === 5)
      })
  }, [clearFilter])

  return (
    <div style={{ marginTop: 10 }}>
      <Grid direction="row" container={true}>
        <Autocomplete
          size="small"
          defaultChecked={true}
          value={
            selectedFilters?.planforhold ? selectedFilters.planforhold : []
          }
          options={planforholdtyper ? planforholdtyper : []}
          sx={{
            width: 400
          }}
          limitTags={1}
          ChipProps={{
            color: "primary",
            variant: "outlined",
            sx: { borderRadius: 1 }
          }}
          componentsProps={{
            popper: {
              color: "secondary",
              sx: { paddingTop: 5, boxShadow: 3 }
            }
          }}
          color="secondary"
          multiple={true}
          getOptionLabel={option =>
            `${option.id} - ${option?.beskrivelseEtterkommer}`
          }
          onChange={(event, newValue) =>
            setSelectedFilters({
              ...selectedFilters,
              planforhold: newValue
            })
          }
          renderInput={params => (
            <ToiLikeStyledTextField
              {...params}
              size="small"
              fullWidth={true}
              label="Planforhold type"
              variant="outlined"
              color="secondary"
            />
          )}
        />

        <div style={{ paddingLeft: 16 }}>
          <DateRangePickerMui
            textFieldProps={{ sx: { width: 400 } }}
            onChange={d => {
              handleDatoChange(d)
            }}
            label="Ikraft etterkommer"
            startDate={selectedFilters?.ikraftforloperFra}
            endDate={selectedFilters?.ikraftforloperTil}
          />
        </div>
      </Grid>
    </div>
  )
}
