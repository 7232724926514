import moment from "moment"
import { ArealplanFormValues } from "./PlanForm"

export const isIKraftRequired = PlanStatusId => {
  return [3, 8].includes(parseInt(PlanStatusId))
}

export const isIKraftEnabled = PlanStatusId => {
  return [3, 4, 5, 6, 8].includes(parseInt(PlanStatusId))
}

export const isLovRefRequired = PlanTypeId => {
  return [1, 2, 3, 4, 10, 11, 12, 20, 21, 22, 30, 31, 32, 33, 34, 35].includes(
    parseInt(PlanTypeId)
  )
}

export const isLovRefDefault = PlanTypeId => {
  return [1, 2, 3, 4, 12, 34, 35].includes(parseInt(PlanTypeId))
}

export const isLovRefLimited = PlanTypeId => {
  return [30, 32, 33].includes(parseInt(PlanTypeId))
}

export const isForslagsstillerAllowed = PlanTypeId => {
  return parseInt(PlanTypeId) === 35
}

export const isVertNivaRequired = (PlanTypeId, PlanStatusId) => {
  return parseInt(PlanTypeId) >= 30 && [3, 8].includes(parseInt(PlanStatusId))
}

export const isVertNivaEnabled = (PlanTypeId, PlanStatusId) => {
  return parseInt(PlanTypeId) >= 30
}

export const isPlanBestemmelserSuggested = PlanStatusId => {
  return [3, 6].includes(parseInt(PlanStatusId))
}

export const isUbehandletEnabled = PlanStatusId => {
  return [6].includes(parseInt(PlanStatusId))
}

const planTypeIdsWithoutKlagefrist: number[] = [20, 21]
export const shouldDisplayKlagefrist = (values: ArealplanFormValues) => {
  if (values.planTypeId) {
    if (typeof values.planTypeId == "number") {
      return (
        !planTypeIdsWithoutKlagefrist.includes(values.planTypeId) &&
        values.planStatusId == 3
      )
    } else {
      return (
        !planTypeIdsWithoutKlagefrist.includes(parseInt(values.planTypeId)) &&
        values.planStatusId == 3
      )
    }
  } else {
    return values.planStatusId == 3
  }
}

export const shouldDisplayHoringsfrist = (
  values: ArealplanFormValues
): boolean => {
  // if (!values.horingsfrist) return false
  // return moment(values.horingsfrist).isAfter()
  return true
}
