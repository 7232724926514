import { Box } from "@mui/material"
import React from "react"
import { Waypoint } from "react-waypoint"
import LoadingSpinner from "../../components/LoadingSpinner"
import { Arealplan } from "../../hooks/arealplaner/types"
import useSelectedKundeId from "../../hooks/kunder/useSelectedKundeId"
import useSearch from "../../hooks/search/useSearch"
import PlanCard from "./AlternativeHomeRelated/PlanCard"

type Props = { handlePlanClick: (Arealplan) => void; selectedPlan?: Arealplan }

export default function SearchResultBrowse({
  handlePlanClick,
  selectedPlan
}: Props) {
  const customerId = useSelectedKundeId()
  const search = useSearch()

  const handleLoadMore = () => {
    search.fetchNextPage()
  }

  const mapResults = () => {
    if (search.isFetched) {
      if (search.results.length > 0) {
        return (
          <>
            {search.totalCount || 0} planer funnet
            {search.results.map((plan: Arealplan) => (
              <PlanCard
                plan={plan}
                customerId={customerId}
                handlePlanClick={plan => handlePlanClick(plan)}
                key={plan.id}
                highlight={selectedPlan?.id === plan.id}
              />
            ))}
            {search.isFetchingNextPage && (
              <div style={{ marginTop: "-60px" }}>
                <LoadingSpinner />
              </div>
            )}
          </>
        )
      } else {
        return "Aktive filtre ga ingen resultat. Endre filter for å se aktuelle planer."
      }
    } else {
      return <LoadingSpinner />
    }
  }
  return (
    <Box>
      {mapResults()}
      <Waypoint onEnter={handleLoadMore} bottomOffset="-400px" />
    </Box>
  )
}
