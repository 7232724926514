import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { UrlSearchParams } from "../../../../../store/search/types"
import qs from "qs"
import {
  getAddress,
  getPropertyGeometry
} from "../../../../../services/matrikkelkart"
import {
  setPropertySearchIsAdressSearch,
  setPropertySearchText,
  setSearchedPropertyGeom
} from "../../../../../store/search/actions"
import { Feature, MultiPolygon, Polygon } from "geojson"
import { getSearchState } from "../../../../../store"
import { useSearchParams } from "react-router-dom"
import useKommuneInfo from "../../../../../hooks/kommuneinfo/useKommuneInfo"
import { Coords } from "@norkart/nkm-mapbox-map"
import { multiPolygon, point } from "@turf/helpers"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"

export type FormattedMnr = {
  knr?: string
  bnr?: number
  gnr?: number
  snr?: number
  fnr?: number
}

export const usePropertySearch = () => {
  const dispatch = useDispatch()
  const searchState = useSelector(getSearchState)
  const [searchParams, setSearchParams] = useSearchParams()
  const kommuneInfo = useKommuneInfo()

  const toMatrikkelId = ({ knr, gnr, bnr, fnr, snr }: FormattedMnr): string => {
    function addPrefixPadding(
      text: string,
      wantedLength: number,
      paddingValue?: string
    ) {
      var i
      paddingValue = paddingValue || "0"
      text = (text || "").toString()
      var textWithPadding = text

      for (i = 0; i < wantedLength - text.length; i++) {
        textWithPadding = paddingValue + textWithPadding
      }

      return textWithPadding
    }
    return (
      addPrefixPadding(knr as string, 4) +
      addPrefixPadding(gnr as any, 5) +
      addPrefixPadding(bnr as any, 4) +
      addPrefixPadding(fnr as any, 4) +
      addPrefixPadding(snr as any, 3)
    )
  }

  const getPropertyLabel = (): string | null => {
    const mnr = get()?.mnr
    const searchText = searchState.propertySearchText
    const isAdressSearch = searchState.propertySearchIsAdressSearch

    if (!mnr) {
      if (searchState.urlSearchParams?.lnglat) {
        return "Søk på punkt"
      } else {
        return null
      }
    }
    if (isAdressSearch && searchText) {
      return `Eiendom: ${
        searchText.length > 22 ? searchText.substring(0, 22) + ".." : searchText
      }`
    } else {
      var label = `Eiendom: ${mnr.gnr || "0"}/${mnr.bnr || "0"}`
      if (mnr.fnr || mnr.snr) {
        label += "/" + (mnr.fnr || "0")
      }
      if (mnr.snr) {
        label += "/" + (mnr.snr || "0")
      }
      return label
    }
  }

  const toFormatedMnr = (mnr: string): FormattedMnr => {
    var fnr = parseInt(mnr.substring(13, 17), 10)
    var snr = parseInt(mnr.substring(17), 10)
    return {
      knr: JSON.stringify(parseInt(mnr.substring(0, 4), 10)),
      gnr: parseInt(mnr.substring(4, 9), 10),
      bnr: parseInt(mnr.substring(9, 13), 10),
      fnr: fnr !== 0 ? fnr : 0,
      snr: snr !== 0 ? snr : 0
    }
  }

  const setMatrikkelId = () => {
    let { gnr, bnr, fnr, snr, knr, teigid } = qs.parse(
      window.location.search
    ,{ ignoreQueryPrefix: true }) as Partial<UrlSearchParams>

    if (gnr && bnr) {
      const mnr: FormattedMnr = { knr, gnr, bnr, fnr, snr }
      let id = toMatrikkelId(mnr)
      setSearchedPropertyGeometry(id, teigid)
    } else {
      setSearchedPropertyGeometry(undefined)
    }
  }

  const setSearchedPropertyGeometry = async (
    mnr: string | undefined,
    teigId?: number | undefined
  ) => {
    //Save property geometry to center map around
    if (mnr) {
      const res = await getPropertyGeometry(mnr)
      if (res === "error") {
        dispatch(setSearchedPropertyGeom(undefined))
      } else {
        let geom:
          | undefined
          | Feature<MultiPolygon>
          | Feature<Polygon> = undefined
        if (teigId) {
          let teig = res.Teiger.find(teig => teig.Id == teigId)
          if (teig) {
            geom = {
              type: "Feature",
              properties: {},
              geometry: JSON.parse(teig.Geometri) as Polygon
            }
            geom.geometry.type = "Polygon"
          }
        } else {
          geom =
            res &&
            getMultiPolygon(
              res.Teiger.map(teig => JSON.parse(teig.Geometri) as Polygon)
            )
        }
        dispatch(setSearchedPropertyGeom(geom || undefined))
      }
    } else {
      // Only reset if state has value
      if (searchState.searchedPropertyGeom)
        dispatch(setSearchedPropertyGeom(undefined))
    }
  }

  const getMultiPolygon = (
    geoms: Polygon[]
  ): Feature<MultiPolygon> => {
    return multiPolygon(geoms.map(geom => geom.coordinates)) as Feature<
      MultiPolygon
    >
  }

  const set = (mnr: FormattedMnr, teigid?: number) => {
    const filtersToSet = {
      knr: mnr.knr,
      gnr: mnr.gnr?.toString(),
      bnr: mnr.bnr?.toString(),
      ...(mnr.snr !== 0 && { snr: mnr.snr?.toString() }),
      ...(mnr.fnr !== 0 && { fnr: mnr.fnr?.toString() }),
      ...(teigid && { teigid: teigid?.toString() })
    }
    setSearchParams(params => {
      for (const [key, value] of Object.entries(filtersToSet)) {
        if (value && typeof value === "string") params.set(key, value)
      }
      return params
    })
  }

  const clear = () => {
    setSearchParams(params => {
      params.delete("knr")
      params.delete("gnr")
      params.delete("bnr")
      params.delete("snr")
      params.delete("fnr")
      params.delete("teigid")
      params.delete("lnglat")
      return params
    })
    clearSearchInput()
  }

  const get = () => {
    if (!searchState.urlSearchParams) return null

    const { knr, gnr, bnr, fnr, snr, teigid, lnglat } =
      searchState.urlSearchParams
    if (gnr && bnr) {
      const mnr: FormattedMnr = { knr, gnr, bnr, fnr, snr }
      return { mnr, teigid }
    } else if (lnglat) {
      return { lnglat }
    }
    return null
  }

  const planSearchByArea = (point: Coords) => {
    setSearchParams(params => {
      params.set("lnglat", point.lng.toString() + "," + point.lat.toString())
      return params
    })
  }

  const activatePropertyFilterFromPosition = async (
    position: {
      lat: number
      lng: number
    },
    onError: (reason: string) => void,
    hitTeigOnly?: boolean
  ) => {
    if (
      !booleanPointInPolygon(
        point([position.lng, position.lat]),
        JSON.parse(kommuneInfo.data.KommuneGeometry) as Polygon
      )
    ) {
      onError("Adressen er utenfor kommunen")
      return
    }

    const res = await getAddress(position)

    //find the teig that overlaps with given coordinates
    const teig = res.data.Teiger.find(teig =>
      booleanPointInPolygon([position.lng, position.lat], JSON.parse(teig.Geometri) as Polygon)
    )

    clear()

    //perform search by adding matrikkel-filter
    if (!teig) {
      planSearchByArea(position)
      return
    }

    const mnr = {
      gnr: teig.Gaardsnummer,
      bnr: teig.Bruksnummer,
      fnr: teig.Festenummer,
      snr: teig.Seksjonsnummer,
      knr: teig.Kommunenummer.toString()
    }
    const teigid = hitTeigOnly ? teig.Id : undefined
    set(mnr, teigid)
  }

  const propertyGeometryIsMissing = searchState.propertyGeometryIsMissing

  const clearSearchInput = () => {
    dispatch(setPropertySearchText(undefined))
    dispatch(setPropertySearchIsAdressSearch(undefined))
    dispatch(setSearchedPropertyGeom(undefined))
  }

  return {
    setMatrikkelId,
    toFormatedMnr,
    getPropertyLabel,
    propertyGeometryIsMissing,
    activatePropertyFilterFromPosition,
    get,
    set,
    clear,
    clearSearchInput,
    searchText: searchState.propertySearchText
  }
}
