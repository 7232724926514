import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography
} from "@mui/material"

type props = {
  /** Destination url */
  url: string
  /** Text to display instead of url */
  linkText?: string
}

function ExternalLink({ url, linkText }: props) {
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    window.open(url)
  }

  const getLinkText = () => {
    if (!url) {
      return "Noe er galt! Url ikke definert"
    } else if (linkText) {
      return linkText
    } else {
      return url
    }
  }

  return (
    <>
      <Button
        style={{
          alignSelf: "center",
          justifySelf: "right",
          justifyContent: "center"
        }}
        variant="contained"
        color="secondary"
        onClick={() => setClicked(true)}>
        {getLinkText()}
      </Button>

      <Dialog fullWidth={true} open={clicked} onClose={() => setClicked(false)}>
        <DialogTitle style={{ textAlign: "center" }}>
          <Typography style={{ fontSize: 22 }}>
            Du forlater nå arealplaner.no
          </Typography>
        </DialogTitle>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20
          }}>
          <Button onClick={() => setClicked(false)}>Avbryt</Button>
          <Button onClick={handleClick} variant="contained" color="secondary">
            Gå videre
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExternalLink
