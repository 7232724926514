import * as React from "react"
import { Arealplan } from "../../../hooks/arealplaner/types"
import PlanNotification from "./PlanNotification"
import WarningIcon from "@mui/icons-material/WarningOutlined"
import {
  Tooltip,
  Typography,
  Grid,
  Button,
  styled,
  TooltipProps,
  tooltipClasses
} from "@mui/material"
import { amber } from "@mui/material/colors"
import { formatDate } from "../../../utils/formatDate"
import { connect } from "react-redux"
import { ConnectedReduxProps } from "../../../store"
import { showDialog } from "../../../store/dialog/actions"
import useMidlertidigForbud from "../../../hooks/midlertidigForbud/useMidlertidigForbud"
import { Behandling } from "../../../hooks/behandlinger/types"
import usePlanNotificationHelpers, {
  Horingsperiode
} from "./usePlanNotificationHelpers"
import SakItem from "../../sak/SakList/SakItem"

export interface PlanNotificationsProps extends ConnectedReduxProps {
  plan: Arealplan
  behandlinger: Behandling[]
  midlertidigForbud?: ReturnType<typeof useMidlertidigForbud>
  variant: "blocks" | "icon"
}

type Notification = {
  title: string | React.ReactElement
  description?: string | React.ReactElement
}

const Bold = styled(Typography)({ fontWeight: 500 })

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none"
  }
})

const PlanNotifications = ({
  plan,
  behandlinger,
  midlertidigForbud,
  variant,
  dispatch
}: PlanNotificationsProps) => {
  const helpers = usePlanNotificationHelpers()
  const [notifications, setNotifications] = React.useState([] as Notification[])
  React.useEffect(() => {
    let notifications = [] as Notification[]
    if (helpers.showKlagefristWithoutDate({ plan }))
      variant === "icon"
        ? notifications.push({
            title: "Klagefrist ikke utløpt, planen har ikke blitt kunngjort."
          })
        : notifications.push({
            title: "Klagefrist ikke utløpt, planen har ikke blitt kunngjort.",
            description: "Klagefristen har ikke begynt å løpe ennå."
          })
    if (helpers.showKlagefristWithDate({ plan }))
      variant === "icon"
        ? notifications.push({
            title: `Klagefrist ikke utløpt. Klagefrist: ${formatDate(
              plan.klagefrist
            )}`
          })
        : notifications.push({
            title: (
              <Grid container={true}>
                <Grid item={true} xs={12}>
                  <Bold>Klagefrist ikke utløpt</Bold>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <Bold>
                    {`Annonseringsdato: ${helpers.getAnnouncementDate({
                      behandlinger
                    })}`}
                  </Bold>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <Bold>{`Klagefrist: ${formatDate(plan.klagefrist)}`}</Bold>
                </Grid>
              </Grid>
            )
          })
    if (helpers.showKlagefristExpiredLessThanaWeek({ plan }))
      notifications.push({
        title:
          "Klagefristen er nettopp utgått. Klagefrist: " +
          formatDate(plan.klagefrist),
        description:
          "Vær oppmerksom på at det kan komme inn klager som er sendt via ordinær postgang."
      })
    if (helpers.showKlageUtsatt({ plan }))
      notifications.push({
        title: "Klage med oppsettende(utsatt) virkning",
        description: "Planen får først virkning når klagen er avgjort."
      })
    if (helpers.showPlandokumentasjonIkkeOppdatert({ plan }))
      notifications.push({
        title: "Plandokumentasjonen ikke oppdatert i henhold til vedtak",
        description:
          "Endelig planvedtak forutsetter endringer i plandokumentene som ikke er på plass ennå. Disse må oppdateres før planvedtaket kan kunngjøres."
      })
    if (helpers.showMottattKlage({ plan }))
      notifications.push({
        title: "Klage mottatt",
        description:
          "Planen er under klagebehandling. Planens virkninger trer likevel i kraft straks den er vedtatt, selv om det foreligger klage. Tiltak utføres på egen risiko."
      })
    if (helpers.showUavklartInnsigelse({ plan }))
      notifications.push({
        title: "Uavklart innsigelse",
        description:
          "Planen har blitt vedtatt med uløst innsigelse. Innsigelsen er gitt fra myndighet med innsigelseskompetanse og har blitt fremmet mot hele eller deler av planen. Dette innebærer at departementet må avgjøre om innsigelsen skal tas til følge, eller at innsigelsesmyndighet trekker innsigelsen, før planens status er endelig avklart. Hele eller deler av planen har derfor ikke rettsvirkning. Mer informasjon finner du under Planbehandlinger."
      })
    if (midlertidigForbud?.isFetched) {
      midlertidigForbud.data.forEach(mf => {
        notifications.push({
          title:
            "Hele eller deler av planområdet overlapper et område med midlertidig forbud mot tiltak.",
          description: (
            <Typography>
              Aktuell eiendom eller område er ikke nødvendigvis berørt. Se kart
              for forbudets utstrekning (skravert grått område). Dersom du
              trykker i kartet vil du få beskjed i sidemenyen om punktet er
              berørt eller ikke. Vedtaksdato: {mf.datoForVedtak}. Gyldig til:{" "}
              {mf.gyldigTil}.
              {mf.url && (
                <>
                  {" "}
                  Saksnummer: <SakItem sak={mf} />
                </>
              )}
            </Typography>
          )
        })
      })
    }
    const hps = helpers.getHoringsperioder({ behandlinger })
    if (hps) {
      hps.map(hp => {
        notifications.push({
          title: renderHoringsperiodeNotification(hp)
        })
      })
    }
    if (helpers.shouldShowHoringsperiode({ plan }) && variant === "icon") {
      notifications.push({
        title: `Planen er i høringsperiode med frist ${formatDate(
          plan.horingsfrist
        )}`
      })
    }
    setNotifications(notifications)
  }, [plan, midlertidigForbud])

  function renderHoringsperiodeNotification(hp: Horingsperiode) {
    const horingsdokumenter = hp.behandling.dokumenter.filter(
      b => b.horingsdokument
    )
    return (
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Bold>Planen er i høringsperiode</Bold>
        </Grid>
        <Grid container={true} justifyContent="flex-start">
          <Grid item={true} xs={12}>
            <Typography>
              {"Plan lagt ut til "}
              <em>{hp.behandlingsType}</em>
              {" med høringsfrist "}
              {hp.tilDato}.
            </Typography>
          </Grid>
          {horingsdokumenter.length > 0 && (
            <Grid item={true} xs={12}>
              <Bold>
                <Button
                  variant="text"
                  onClick={() =>
                    dispatch(
                      showDialog({
                        dialogType: "DOKUMENT_LIST",
                        dialogProps: {
                          documents: horingsdokumenter,
                          title: "Høringsdokumenter"
                        }
                      })
                    )
                  }>
                  Vis dokumenter
                </Button>
              </Bold>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }

  function renderTooltipTitle() {
    return notifications.map((notification, index) => (
      <Typography key={index} style={{ color: "white" }}>
        {notification.title}
      </Typography>
    ))
  }
  if (notifications.length === 0) return null
  if (variant === "blocks")
    return (
      <div>
        {notifications.map((notification, index) => (
          <PlanNotification
            key={index}
            title={notification.title}
            description={notification.description}
          />
        ))}
      </div>
    )
  if (variant === "icon")
    return (
      <NoMaxWidthTooltip title={renderTooltipTitle()}>
        <WarningIcon
          fontSize="small"
          sx={{
            color: amber[700],
            lineHeight: 1,
            display: "flex",
            alignItems: "center"
          }}
        />
      </NoMaxWidthTooltip>
    )
  return null
}

export default connect()(PlanNotifications)
