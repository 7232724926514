import { PostFeed } from "@norkart/releasenotes"
import React, { useEffect, useState } from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import "./test.css"
import config from "../../config/config"
import { norkartIdAuth, norkartIdAuthData } from "../../utils/auth"
import { ToiThemeProvider } from "@norkart/toi-theme"

export const nkColors = {
  dark_grey: "#4d4d4d",
  light_grey_disabled: "#948f8f",
  black: "#252527",
  green: " #51a026",
  uu_green: "#1e7400",
  bla: "#87a8e0",
  orange: "#f5ad4a",
  hvit: "#f6f6f6",
  red: "#e25653",
  red_uu: "#c43835", // UU ok on white background
  warning_red: "#e32",
  lighter_green: "#d3e5ca",
  background_green: "#eff5f1",
  background_grey: "#fafafa"
}

export const theme = createTheme({
  palette: {
    primary: {
      main: nkColors.uu_green
    },
    secondary: {
      main: "#fff",
      contrastText: nkColors.uu_green
    }
  },
  typography: {
    fontFamily: "Roboto, arial, sans-serif"
  },
  shape: {
    borderRadius: 0
  },
  transitions: {
    // So we have `transition: none;` everywherecreate: () => 'none',
  }
})

/** Wrapper component for releasenotes npm package: https://dev.azure.com/norkart-tfs/CommonReleaseNotes  */
export default function Nyheter() {
  const [token, setToken] = useState<string | undefined>()

  useEffect(() => {
    norkartIdAuth.getToken().then(t => setToken(t))
  }, [])

  return (
    <ToiThemeProvider>
      <Box maxWidth="2000px">
        <PostFeed
          clientId={config.planregister.profile}
          applicationProfile={config.planregister.profile}
          bearerToken={token}
        />
      </Box>
    </ToiThemeProvider>
  )
}
