import { IconButton, styled, TextField, TextFieldProps } from "@mui/material"
import StyledTextfield from "../../TextField/StyledTextField"
import { forwardRef } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
import PropTypes from "prop-types"
import * as React from "react"

export interface MuiTextFieldSearchProps
  extends Pick<
    TextFieldProps,
    | "children"
    | "color"
    | "autoComplete"
    | "autoFocus"
    | "defaultValue"
    | "disabled"
    | "error"
    | "FormHelperTextProps"
    | "focused"
    | "fullWidth"
    | "helperText"
    | "id"
    | "InputLabelProps"
    | "InputProps"
    | "inputRef"
    | "label"
    | "maxRows"
    | "minRows"
    | "multiline"
    | "name"
    | "onChange"
    | "onClick"
    | "onBlur"
    | "onKeyUp"
    | "placeholder"
    | "required"
    | "rows"
    | "size"
    | "sx"
    | "type"
    | "value"
  > {
  handleClearSearch: ((any) => any | undefined | null) | undefined
  active?: boolean
  variant?: TextFieldProps["variant"]
}

export const MuiTextFieldSearch = forwardRef<
  HTMLDivElement,
  MuiTextFieldSearchProps
>(({ handleClearSearch, active, ...props }, ref) => {
  return (
    <TextField
      ref={ref}
      fullWidth={true}
      InputProps={{
        endAdornment: (
          <div style={{ alignItems: "center", display: "flex" }}>
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{
                visibility: active ? "visible" : "hidden"
              }}
              onClick={handleClearSearch}>
              <ClearIcon color="primary" fontSize="small" />
            </IconButton>

            <SearchIcon fontSize="medium" />
          </div>
        )
      }}
      {...props}
    />
  )
})

MuiTextFieldSearch.propTypes = {
  handleClearSearch: PropTypes.func,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(["standard", "filled", "outlined", null, undefined])
}
