import { useQuery } from "react-query"
import { fetchFaserForSak } from "../../services/api"
import { Sak } from "../arealplaner/types"
import useSelectedKundeId from "../kunder/useSelectedKundeId"

export default function useFaserForSak(sakId?: number) {
  const kundeId = useSelectedKundeId()
  return useQuery(
    ["faser", { kundeId, sakId }],
    () => fetchFaserForSak(sakId!).then(res => res.data),
    { enabled: !!sakId, retry: 0 }
  )
}
