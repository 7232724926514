
import * as React from 'react'
import { useMemo } from "react"
import { InfiniteData, useInfiniteQuery } from "react-query"
import { PlanforholdQueryVariables } from '../../features/reports/Reports/Planforhold/Planforhold'
import { GraphQLPagedResponse } from "../../graphql/types"
import { graphQLRequest } from "../../services/api"
import { PlanforholdReport } from "./types"

const MapPagedPlanforhold = (data: InfiniteData<GraphQLPagedResponse>) => {
  const mappedData = data?.pages.flatMap(b =>  {
    if(b.planforholdPaged?.edges)
      return b.planforholdPaged.edges.map(e => e.node)
    else return undefined
  }) as PlanforholdReport[] 
  return mappedData 
} 

export default function usePlanforholdGraphQL(graphqlQuery: string, variables:PlanforholdQueryVariables) {
  
  const query = useInfiniteQuery<GraphQLPagedResponse>(
    ["planforholdReportGraphQL", variables],
    ({pageParam=variables}) => graphQLRequest(graphqlQuery, pageParam),
    {
        getNextPageParam: response => {
          if(response?.planforholdPaged.pageInfo?.hasNextPage) {
            const test = {...variables, after: response.planforholdPaged.pageInfo.endCursor}
            return test
          } else return undefined
          },
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
    },)

    const planforhold: PlanforholdReport[] = useMemo(() => {
      if(query.data) return MapPagedPlanforhold(query.data) 
      else return [] as PlanforholdReport[]
      }, [query.data?.pages])
    
      return { ...query,planforhold: planforhold|| [], totalCount: query.data ? query.data.pages[0]?.planforholdPaged.totalCount : undefined}

  }