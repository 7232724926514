import * as React from "react"
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material"
import { Behandling } from "../../../hooks/behandlinger/types"
import { hideDialog, showDialog } from "../../../store/dialog/actions"
import { ConnectedReduxProps } from "../../../store"
import { Arealplan } from "../../../hooks/arealplaner/types"
import useDeleteBehandling from "../../../hooks/behandlinger/useDeleteBehandling"

export interface DeleteBehandlingDialogProps extends ConnectedReduxProps {
  behandling: Behandling
  plan: Arealplan
  open: boolean
}

const DeleteBehandlingDialog = ({
  behandling,
  plan,
  dispatch,
  open
}: DeleteBehandlingDialogProps) => {
  const { mutate: deleteBehandling } = useDeleteBehandling()
  const handleClose = () => {
    hideDialog(dispatch).then(() =>
      dispatch(
        showDialog({
          dialogType: "EDIT_BEHANDLING",
          dialogProps: { behandling, plan }
        })
      )
    )
  }

  const handleOk = () => {
    deleteBehandling(behandling.id)
    hideDialog(dispatch)
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="confirm-dialog-title"
      disableScrollLock={true}>
      <DialogTitle id="confirm-dialog-title">
        Vil du virkelig slette behandlingen?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Nei</Button>
        <Button onClick={handleOk}>Ja</Button>
      </DialogActions>
    </Dialog>
  )
}
export default DeleteBehandlingDialog
