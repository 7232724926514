import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SearchIcon from "@mui/icons-material/Search"
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  InputAdornment,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import arrayMutators from "final-form-arrays"
import * as React from "react"
import { Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import LoadingSpinner from "../../../components/LoadingSpinner"
import StyledSelect from "../../../components/StyledSelect/StyledSelect"
import TabbableTableLink from "../../../components/TabbableTableLink"
import { Arealplan } from "../../../hooks/arealplaner/types"
import useUpdateArealplan from "../../../hooks/arealplaner/useUpdateArealplan"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import useMeta from "../../../hooks/meta/useMeta"
import { searchArealplan } from "../../../services/api"
import { showDialog } from "../../../store/dialog/actions"

interface PlanRelationshipFormProps {
  plan: Arealplan
  formId?: string
  setSubmitting: (boolean) => void
  onClose: () => any
}

const SearchResultsWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 10,
  height: "200px",
  overflowY: "auto",
  backgroundColor: "white",
  maxWidth: "60%",
  boxShadow: "1px 1px grey",
  "& table": { tableLayout: "fixed", width: "100%" },
  "& tr": { cursor: "pointer" }
}))

const PlanRelationshipForm: React.FC<PlanRelationshipFormProps> = ({
  plan,
  formId,
  setSubmitting,
  onClose
}: PlanRelationshipFormProps) => {
  const meta = useMeta()
  const kundeId = useSelectedKundeId()
  const { mutateAsync: updateArealplan } = useUpdateArealplan()
  const queryClient = useQueryClient()

  const dispatch = useDispatch()

  const defaultPlanforholdTypeId = 5
  const [expanded, setExpanded] = React.useState(true)
  const [searching, setSearching] = React.useState(false)
  const [term, setTerm] = React.useState("")
  const [selectedPlanforholdTypeId, setSelectedPlanforholdtypeId] =
    React.useState(defaultPlanforholdTypeId)

  const [searchResults, setSearchResults] = React.useState([] as Arealplan[])

  const [pristine, setPristine] = React.useState(true)

  const handleSearch = () => {
    setSearching(true)
    searchArealplan(`term=${term}`).then(res => {
      setSearchResults(res.data)
      setPristine(false)
      setSearching(false)
    })
  }

  const handleClear = () => {
    setTerm("")
    clearSearchResult()
  }

  const clearSearchResult = () => {
    setSearchResults([])
    const inputField = document.getElementById("plan-relationship-search")
    inputField && inputField.focus()
  }

  const getPlanforholdType = type => {
    const planforholdtype = meta.data.planforholdtyper.find(p => p.id == type)
    return planforholdtype ? planforholdtype.beskrivelseForloper : ""
  }

  const handleSubmit = async values => {
    setSubmitting(true)
    const data = {
      ...plan,
      planforholdForlopere: values.planforhold
    } as Arealplan
    const res = await updateArealplan(data, {
      onSuccess: () =>
        queryClient.invalidateQueries([
          "dokumenter",
          { kundeId, arealplanId: plan.id }
        ])
    })
    setSubmitting(false)

    const planforholdChangeTriggers = values.planforhold?.filter(
      pf => pf.source == "Ny" && [2, 4].includes(pf.id)
    )

    onClose().then(() => {
      if (planforholdChangeTriggers?.length > 0) {
        dispatch(
          showDialog({
            dialogType: "CONFIRM_PLAN_STATUS_CHANGE",
            dialogProps: {
              planforhold: planforholdChangeTriggers,
              plan
            }
          })
        )
      }
    })
  }

  if (meta.isLoading) return <LoadingSpinner />
  return (
    <>
      <Form
        initialValues={{ planforhold: plan.planforholdForlopere }}
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit,
          pristine,
          invalid,
          values,
          form: {
            mutators: { unshift }
          }
        }) => (
          <Box
            sx={{
              "& td": { padding: "5px", wordBreak: "keep-all" },
              height: "100%"
            }}>
            <form onSubmit={handleSubmit} id={formId}>
              <Button
                variant="text"
                onClick={() => setExpanded(expanded => !expanded)}
                sx={theme => ({
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  textDecoration: "underline",
                  textDecorationColor: theme.palette.secondary.main
                })}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} Nytt
                planforhold
              </Button>
              <Collapse in={expanded} timeout="auto">
                <Box
                  sx={theme => ({
                    backgroundColor: theme.palette.grey[200],
                    marginBottom: "30px",
                    padding: "10px"
                  })}>
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          fontWeight: 500,
                          marginBottom: "5px"
                        }}>
                        Type planforhold
                      </div>
                      <StyledSelect
                        style={{
                          minWidth: "360px"
                        }}
                        value={selectedPlanforholdTypeId}
                        onChange={e => {
                          setSelectedPlanforholdtypeId(Number(e.target.value))
                        }}
                        options={meta.data.planforholdtyper}
                        keyCol="id"
                        valueCol="beskrivelseForloper"
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          fontWeight: 500,
                          marginBottom: "5px"
                        }}>
                        Planid
                      </div>
                      <TextField
                        variant="outlined"
                        id="plan-relationship-search"
                        type="text"
                        onChange={e => setTerm(e.target.value)}
                        onKeyDown={e => {
                          if (e.code === "Enter" || e.keyCode === 13) {
                            e.preventDefault()
                            handleSearch()
                          }
                        }}
                        sx={{
                          width: "360px",
                          backgroundColor: "white",
                          "& fieldset": { borderRadius: 0 },
                          "& input": {
                            padding: "5px",
                            "&::-ms-clear": {
                              display: "none"
                            }
                          }
                        }}
                        value={term}
                        placeholder="Søk på plannavn eller planid..."
                        margin="none"
                        InputProps={{
                          "aria-label": "søkefelt",
                          endAdornment: (
                            <InputAdornment position="end">
                              {term !== "" && (
                                <IconButton
                                  onClick={handleClear}
                                  aria-label="Fjern søkeord"
                                  style={{ padding: 0 }}
                                  onKeyDown={e => {
                                    if (
                                      e.code === "Enter" ||
                                      e.keyCode === 13
                                    ) {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      handleClear()
                                    }
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              )}
                              {searching ? (
                                <IconButton style={{ padding: 0 }}>
                                  <CircularProgress size={27.5} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={handleSearch}
                                  aria-label="Utfør søk"
                                  style={{ padding: 0 }}>
                                  <SearchIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>
                  {searchResults.length > 0 && (
                    <SearchResultsWrapper>
                      <div className="tabbable-table-wrapper">
                        <Table padding="none">
                          <colgroup>
                            <col style={{ width: "200px" }} />
                            <col style={{ width: "auto" }} />
                          </colgroup>
                          <TableBody>
                            {searchResults
                              .filter((res: Arealplan) => res.id != plan.id)
                              .filter(
                                res =>
                                  !values.planforhold
                                    .filter(
                                      pf => pf.id == selectedPlanforholdTypeId
                                    )
                                    .map(pf => pf.arealplanId)
                                    .includes(res.id)
                              )
                              .map(searchResult => (
                                <TableRow
                                  onClick={() => {
                                    const { id: arealplanId, ...arealplan } =
                                      searchResult
                                    unshift("planforhold", {
                                      id: selectedPlanforholdTypeId,
                                      arealplanId,
                                      ...arealplan,
                                      source: "Ny"
                                    })
                                    clearSearchResult()
                                  }}
                                  key={searchResult.id}>
                                  <TableCell>{searchResult.planId}</TableCell>
                                  <TableCell>
                                    <TabbableTableLink
                                      text={searchResult.planNavn}
                                      action={e => {
                                        const {
                                          id: arealplanId,
                                          ...arealplan
                                        } = searchResult
                                        unshift("planforhold", {
                                          id: selectedPlanforholdTypeId,
                                          arealplanId,
                                          ...arealplan,
                                          source: "Ny"
                                        })
                                        clearSearchResult()
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    </SearchResultsWrapper>
                  )}
                </Box>
              </Collapse>
              <div style={{ paddingLeft: "10px" }}>
                <Typography variant="h3">Registrerte planforhold</Typography>
                {values.planforhold?.length > 0 && (
                  <>
                    <div>
                      <Table padding="none">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>Type forhold</TableCell>
                            <TableCell>Planid</TableCell>
                            <TableCell>Plannavn</TableCell>
                            <TableCell>{}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.planforhold.map((planforhold, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {planforhold.source === "Ny" ? (
                                  <p style={{ color: "red" }}>Ny</p>
                                ) : null}
                              </TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                <Typography>
                                  {getPlanforholdType(planforhold.id)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{planforhold.planId}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{planforhold.planNavn}</Typography>
                              </TableCell>
                              <TableCell>
                                <FieldArray name="planforhold">
                                  {({ fields }) => {
                                    return fields.map((name, fieldIndex) => {
                                      if (index === fieldIndex) {
                                        //Looping through the fields to be able to remove correctly, but cannot display values from here because they are not up to date for som reason
                                        return (
                                          <IconButton
                                            key={fieldIndex + name}
                                            onClick={() => fields.remove(index)}
                                            size="small">
                                            <DeleteIcon fontSize="small" />
                                          </IconButton>
                                        )
                                      }
                                    })
                                  }}
                                </FieldArray>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </>
                )}
                {values.planforhold?.length === 0 && (
                  <Typography>Ingen planforhold</Typography>
                )}
              </div>
              {/* <pre>Values: {JSON.stringify(values, null, 2)}</pre> */}
            </form>
          </Box>
        )}
      />
    </>
  )
}

export default PlanRelationshipForm
