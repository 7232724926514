import * as React from "react"
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material"
import { ConnectedReduxProps } from "../../../store"
import { Arealplan } from "../../../hooks/arealplaner/types"
import { hideDialog, showDialog } from "../../../store/dialog/actions"
import useDeleteArealplan from "../../../hooks/arealplaner/useDeleteArealplan"

export interface DeletePlanDialogProps extends ConnectedReduxProps {
  open: boolean
  plan: Arealplan
}

const DeletePlanDialog = ({ dispatch, open, plan }: DeletePlanDialogProps) => {
  const { mutate: deleteArealplan } = useDeleteArealplan()
  const handleClose = () => {
    hideDialog(dispatch).then(() =>
      dispatch(showDialog({ dialogType: "EDIT_PLAN", dialogProps: { plan } }))
    )
  }
  const handleOk = () => {
    hideDialog(dispatch).then(() => deleteArealplan(plan.id))
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title">
      <DialogTitle id="delete-dialog-title">
        Vil du virkelig slette arealplanen?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Nei</Button>
        <Button onClick={handleOk}>Ja</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePlanDialog
