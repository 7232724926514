import React from "react"
import norkart_logo from "../../assets/images/logo_norkart.png"
import { Grid, Typography } from "@mui/material"
import useKommuneInfo from "../../hooks/kommuneinfo/useKommuneInfo"
import { municipalitiesWithHeradEnding } from "../../config/const"
import useIsDemoVersion from "../../hooks/useIsDemoVersion"

type Props = React.HTMLProps<HTMLDivElement> & {
  hideLabel?: boolean
}

function Crest({ hideLabel = false, ...props }: Props) {
  const { data: kommuneinfo, isFetched, ...rest } = useKommuneInfo()
  const isDemoVersion = useIsDemoVersion()

  const shouldDisplayNK = isDemoVersion || Object.keys(kommuneinfo).length === 0

  const getMunicipalityDisplayValue = () => {
    if (
      municipalitiesWithHeradEnding.includes(
        kommuneinfo?.KommuneName?.nor || ""
      )
    ) {
      return "HERAD"
    }
    return shouldDisplayNK ? "PLANREGISTER" : "KOMMUNE"
  }

  const renderLogo = () => (
    <Grid item={true}>
      <img
        src={shouldDisplayNK ? norkart_logo : kommuneinfo.KommuneCrest}
        alt={isDemoVersion ? "norkart logo" : "kommune logo"}
        style={{ height: "50px", display: "flex" }}
      />
    </Grid>
  )

  const labelStyle = { fontSize: "12px", lineHeight: "1" }
  const renderLabel = () => (
    <Grid item={true} style={{ alignSelf: "center", paddingLeft: "8px" }}>
      <Typography style={labelStyle}>
        {shouldDisplayNK
          ? "NORKART"
          : kommuneinfo.KommuneName.nor.toUpperCase()}
      </Typography>
      <Typography style={labelStyle}>
        {getMunicipalityDisplayValue()}
      </Typography>
    </Grid>
  )

  if (!isFetched) return null
  return (
    <div {...props}>
      <Grid container={true} wrap="nowrap" direction="row">
        {renderLogo()}
        {!hideLabel && renderLabel()}
      </Grid>
    </div>
  )
}

export default Crest
