import { Grid } from "@mui/material"
import React, { useState } from "react"
import { sortBehandlingList } from "./helpers"
import ExpansionPanel from "../../../components/ExpansionPanel"
import HelpButton from "../../../components/HelpButton/HelpButton"
import HelpText from "../../../components/HelpText/HelpText"
import DesktopContainer from "../../../containers/DesktopContainer"
import MobileContainer from "../../../containers/MobileContainer"
import { helpObject } from "../../../help/helpObject"
import useArealplan from "../../../hooks/arealplaner/useArealplan"
import { Behandling } from "../../../hooks/behandlinger/types"
import useBehandlinger from "../../../hooks/behandlinger/useBehandlinger"
import DisplayPosInMap from "../../map/DisplayPosInMap"
import BehandlingListItem from "./BehandlingListItem"
import { BehandlingTable } from "./BehandlingTable"

function BehandlingList() {
  const { data: plan } = useArealplan()
  const { data: behandlinger } = useBehandlinger()

  const [expandedId, setExpandedId] = useState<number>()
  const [
    behandlingToDisplayPosFor,
    setBehandlingToDisplayPosFor
  ] = useState<Behandling>()
  const [displayPosInMap, setDisplayPosInMap] = useState<boolean>(false)

  const onChange = (isExpanded: boolean, behandlingId: number) => {
    if (isExpanded) {
      setExpandedId(behandlingId)
    } else if (expandedId && !isExpanded) {
      setExpandedId(undefined)
    }
  }

  const onCloseMap = () => {
    setBehandlingToDisplayPosFor(undefined)
    setDisplayPosInMap(false)
  }

  return (
    <>
      {displayPosInMap && behandlingToDisplayPosFor?.posisjon && (
        <DisplayPosInMap
          plan={plan}
          title="Posisjonen til behandlingen"
          onCloseMap={onCloseMap}
          position={behandlingToDisplayPosFor.posisjon}
        />
      )}
      <MobileContainer>
        <ExpansionPanel
          helpButtonText={
            helpObject.Faner.Planbehandlinger.MeromPlanbehandlinger
          }
          title={`Planbehandlinger (${behandlinger && behandlinger.length})`}>
          {behandlinger &&
            sortBehandlingList(behandlinger).map(behandling => {
              return (
                <BehandlingListItem
                  key={behandling.id}
                  behandling={behandling}
                  expandedOptions={{
                    expandedId: expandedId,
                    onChange: isExpanded => onChange(isExpanded, behandling.id),
                    allowMultipleExpanded: false,
                    rowId: behandling.id
                  }}
                />
              )
            })}
        </ExpansionPanel>
      </MobileContainer>
      <DesktopContainer>
        <Grid
          container={true}
          direction={"column"}
          style={{ display: "flex", marginBottom: 40 }}>
          <HelpText
            style={{ marginRight: 50 }}
            helpText={helpObject.Faner.Planbehandlinger.hovedtekst}
          />
          <HelpButton
            helpText={helpObject.Faner.Planbehandlinger.MeromPlanbehandlinger}
            type="MeromPlanbehandlinger"
            buttonVariant="tekst"
          />
        </Grid>
        <BehandlingTable />
      </DesktopContainer>
    </>
  )
}

export default BehandlingList
