import { useMutation, useQueryClient } from "react-query"
import { updateInnstillingTyped } from "../../services/api"
import useErrorHandler from "../errors/useErrorHandler"
import { Innstilling } from "../meta/types"
import useFrontendSettings from "./useFrontendSettings"

  export default function useUpdateFrontendSetting() {
    const { notify } = useErrorHandler()
    const queryClient = useQueryClient()
    const frontend = useFrontendSettings()

    return useMutation(
      ["update_innstilling"],
      async (data: Innstilling | undefined) => updateInnstillingTyped(data).then((res) => res.data),
      {
        onError: notify,
        onSuccess: () => {
          frontend.refetch()
          queryClient.invalidateQueries(["update_innstilling"])
        }
      }
    )
  }