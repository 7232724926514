import * as React from "react"
import { Dokumenttype, Tilgang } from "../../../hooks/meta/types"
import { TableRow, TableCell } from "@mui/material"
import StyledCheckbox from "../../../components/Checkbox/StyledCheckbox"
import StyledSelect from "../../../components/StyledSelect/StyledSelect"
import useDocumentHelpers from "../useDocumentHelpers"
import { formatDate } from "../../../utils/formatDate"
import { sortBy } from "lodash-es"

export type MovableDocument = {
  id: number
  dokumenttypeId: number | undefined
  dokumentnavn: string
  dokumentdato: Date | undefined
  visIGjeldendeBestemmelser: boolean
  visIPlandokumenter: boolean
  tilgangId: number
}
export interface ImportDocumentFromCatalogItemProps {
  data: MovableDocument | undefined
  dokumenttyper: Dokumenttype[]
  tilganger: Tilgang[]
  onChange: (updatedDocument: MovableDocument) => void
  error?: string
}

export const MoveDocumentFormItem = ({
  data,
  onChange,
  dokumenttyper,
  tilganger,
  error
}: ImportDocumentFromCatalogItemProps) => {
  const [, setDataChanged] = React.useState(0)
  const helpers = useDocumentHelpers()

  React.useEffect(() => setDataChanged(prev => prev + 1), [data, error])

  if (data) {
    return (
      <>
        <TableRow>
          <TableCell>
            <StyledSelect
              style={{ width: 200 }}
              name="dokumentTypeId"
              value={data.dokumenttypeId || ""}
              onChange={e => {
                const value = parseInt(e.target.value as any)
                onChange({
                  ...data,
                  dokumenttypeId: value || undefined,
                  visIGjeldendeBestemmelser: helpers.shouldBestemmelserBeActive(
                    value
                  )
                    ? data.visIGjeldendeBestemmelser
                    : false
                })
              }}
              keyCol="id"
              valueCol="beskrivelse"
              options={sortBy(
                dokumenttyper.filter(dokumenttype => dokumenttype.id != 22),
                dokumenttype => dokumenttype.beskrivelse
              )}
            />
          </TableCell>
          <TableCell>{data.dokumentnavn}</TableCell>
          <TableCell>{formatDate(data.dokumentdato)}</TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <StyledCheckbox
              name="bestemmelser"
              checked={data.visIGjeldendeBestemmelser}
              onChange={(e, checked) =>
                onChange({
                  ...data,
                  visIGjeldendeBestemmelser: checked
                })
              }
              disabled={
                !helpers.shouldBestemmelserBeActive(data.dokumenttypeId)
              }
            />
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <StyledCheckbox
              name="dokumenter"
              checked={data.visIPlandokumenter}
              onChange={(e, checked) =>
                onChange({
                  ...data,
                  visIPlandokumenter: checked
                })
              }
            />
          </TableCell>
          <TableCell>
            <StyledSelect
              name="tilgangId"
              value={data.tilgangId || ""}
              onChange={e => {
                const value = parseInt(e.target.value as any)
                onChange({
                  ...data,
                  tilgangId: value
                })
              }}
              options={tilganger}
              valueCol="navn"
              keyCol="id"
            />
          </TableCell>
        </TableRow>
        {error && (
          <TableRow>
            <TableCell colSpan={6} style={{ color: "red" }}>
              {error}
            </TableCell>
          </TableRow>
        )}
      </>
    )
  } else {
    return null
  }
}

export default MoveDocumentFormItem
