import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
  Box
} from "@mui/material"
import React, { useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import ArticleIcon from "@mui/icons-material/Article"
import SettingsIcon from "@mui/icons-material/Settings"
import LogOutIcon from "@mui/icons-material/ExitToApp"
import AddIcon from "@mui/icons-material/Add"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import { useDispatch } from "react-redux"
import { showDialog } from "../../../store/dialog/actions"
import useReportTypes from "../../../features/reports/Reports/useReportTypes"
import { useNavigate } from "react-router-dom"
import useMeta from "../../../hooks/meta/useMeta"
import useCan from "../../../utils/auth/useCan"
import useIsDemoVersion from "../../../hooks/useIsDemoVersion"

type Props = {
  handleSignOut: () => void
}

function TopbarMenu({ handleSignOut }: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const navigate = useNavigate()
  const kundeId = useSelectedKundeId()
  const reportTypes = useReportTypes()
  const dispatch = useDispatch()
  const meta = useMeta()
  const can = useCan()
  const isDemoVersion = useIsDemoVersion()

  const handleMenuOpen = event => {
    const t = event.currentTarget
    event.stopPropagation()
    setAnchorEl(event.currentTarget)

    t.setAttribute("aria-owns", event.currentTarget ? "topbar-menu" : undefined)
    t.setAttribute("aria-haspopup", true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleAddPlanClicked = () => {
    handleCloseMenu()
    dispatch(
      showDialog({
        dialogType: "EDIT_PLAN",
        dialogProps: { plan: {} }
      })
    )
  }

  const handleInnstillingerClicked = () => {
    handleCloseMenu()
    navigate(`/${kundeId}/innstillinger/tilganger`)
  }

  const handleNyheterClicked = () => {
    handleCloseMenu()
    navigate("/nyheter")
  }

  const handleRapporterClicked = () => {
    handleCloseMenu()
    const firstReport = reportTypes.filter(rt => rt.tilgang)

    firstReport.length > 0
      ? navigate(`/${kundeId}/rapporter/${firstReport[0].Navn.toLowerCase()}`)
      : navigate(`/${kundeId}/rapporter`)
  }

  if (!meta.isFetched) return null

  return (
    <>
      <Button
        variant="text"
        aria-controls="topbar-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        style={isDemoVersion ? { color: "#ffffff" } : {}}>
        Meny <MenuIcon style={{ marginLeft: "4px" }} />
      </Button>
      <Menu
        id="topbar-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}>
        {can.edit && (
          <Box>
            <MenuItem onClick={handleAddPlanClicked} aria-label="Ny plan">
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Ny plan</ListItemText>
            </MenuItem>
            <Divider />
          </Box>
        )}
        {can.edit && (
          <Box>
            <MenuItem onClick={handleNyheterClicked} aria-label="Nyheter">
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText>Nyheter</ListItemText>
            </MenuItem>
            <Divider />
          </Box>
        )}
        <MenuItem onClick={handleRapporterClicked} aria-label="Rapporter">
          <ListItemIcon>
            <FactCheckIcon />
          </ListItemIcon>
          <ListItemText>Rapporter</ListItemText>
        </MenuItem>
        <Divider />
        {can.edit && (
          <Box>
            <MenuItem
              onClick={handleInnstillingerClicked}
              aria-label="Innstillinger">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Innstillinger</ListItemText>
            </MenuItem>
            <Divider />
          </Box>
        )}
        <MenuItem onClick={handleSignOut} aria-label="Logg ut">
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText>Logg ut</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default TopbarMenu
