import * as React from "react"
import { Behandling } from "../../../hooks/behandlinger/types"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import theme from "../../../theme"
import useCan from "../../../utils/auth/useCan"

export interface JournalpostItemProps {
  journalpostData: Partial<Behandling> | Partial<Dispensasjon>
}

const JournalpostItem: React.FC<JournalpostItemProps> = ({
  journalpostData
}: JournalpostItemProps) => {
  const can = useCan()
  var url = can.view
    ? journalpostData.journalpostUrlIntern
    : journalpostData.journalpostUrl
  if (url)
    return (
      <a
        href={`${url}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: theme.palette.secondary.dark }}>
        {journalpostData.journalpostnummer}
      </a>
    )
  return <span>{journalpostData.journalpostnummer}</span>
}

export default JournalpostItem
