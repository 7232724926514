import * as React from "react"
import CustomerSelector from "../CustomerSelector"
import { Typography, Button } from "@mui/material"
import Header from "../../home/header"
import { useNavigate } from "react-router-dom"

export interface MunicipalitySelectorProps {}

function MunicipalitySelector(props: MunicipalitySelectorProps) {
  const navigate = useNavigate();
  
  const onReadMore = () => {
    navigate(`om-arealplaner`);
  }
  return (
    <>
      <Header />
      <img
        src="https://www.norkart.no/wp-content/uploads/2020/01/Copy_me_header-2048x164.png"
        alt=""
        style={{ width: "100%", maxWidth: "100%" }}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ margin: "auto", minWidth: 250, marginTop: 50, fontSize: 20 }}
          variant="contained"
          color={"secondary"}
          onClick={onReadMore}>
          Om arealplaner.no
        </Button>
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "60px auto"
        }}>
        <Typography
          variant="h1"
          style={{ marginBottom: "30px", fontWeight: 300 }}
          align="center">
          Velg kommune:
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}>
          <CustomerSelector />
        </div>
      </div>
    </>
  )
}

export default MunicipalitySelector
