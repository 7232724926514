import * as React from "react"
import GenericTable, {
  GTableColumn
} from "../../../components/GenericTable/GenericTable"
import LoadingSpinner from "../../../components/LoadingSpinner"
import DesktopContainer from "../../../containers/DesktopContainer"
import MobileContainer from "../../../containers/MobileContainer"
import { Journal } from "../../../hooks/journaler/types"
import useJournalerDokumenter from "../../../hooks/journaler/useJournalDokumenter"
import JournalDocumentListItem from "./JournalDocumentListItem"

type Props = {
  journal: Journal
}

function JournalDocumentList({ journal, ...props }: Props) {
  const { data: dokumenter, isError, isLoading } = useJournalerDokumenter(
    journal
  )

  const cellPadding = "4px 16px"
  const columns = [
    {
      field: "dokumentTittel",
      cellStyle: {
        padding: cellPadding,
        whiteSpace: "nowrap"
      } as React.CSSProperties,
      render: document => <JournalDocumentListItem document={document} />
    }
  ] as GTableColumn[]

  if (isError) return <div>Ingen dokumenter funnet</div>
  if (isLoading) return <LoadingSpinner />

  return (
    <>
      <DesktopContainer>
        <div className="tabbable-table-wrapper">
          <GenericTable
            columns={columns}
            idColumn="systemId"
            data={dokumenter}
            localization={{
              body: {
                emptyDataSourceMessage: "Ingen dokumenter"
              }
            }}
          />
        </div>
      </DesktopContainer>
      <MobileContainer>
        {dokumenter.map(document => (
          <JournalDocumentListItem
            key={document.systemId}
            document={document}
          />
        ))}
      </MobileContainer>
    </>
  )
}

export default JournalDocumentList
