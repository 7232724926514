import { CircularProgress, Grid, Link, Paper, Typography } from "@mui/material"
import FileSaver from "file-saver"
import * as React from "react"
import FileIcon from "../../../components/FileIcon"
import { openSnackbarMessage } from "../../../components/SnackbarMessage"
import TabbableTableLink from "../../../components/TabbableTableLink"
import DesktopContainer from "../../../containers/DesktopContainer"
import MobileContainer from "../../../containers/MobileContainer"
import { JournalDocument } from "../../../hooks/journaler/types"
import { fetchJournalDocument } from "../../../services/api"
import { getFileExtension } from "../../../utils/getFileExtension"
import getFilenameFromResponse from "../../../utils/getFilenameFromResponse"

type Props = {
  document: JournalDocument
}

function JournalDocumentListItem({ document }: Props) {
  const [isDownloading, setIsDownloading] = React.useState(false)

  async function handleDownload(doc: JournalDocument) {
    if (!isDownloading) {
      setIsDownloading(true)
      try {
        const res = await fetchJournalDocument(doc.sakId, doc.systemId)
        if (res.data) {
          const filename = getFilenameFromResponse(res)
          FileSaver.saveAs(res.data, filename)
        }
      } catch (error) {
        openSnackbarMessage({
          message: "Noe gikk galt under nedlastingen",
          variant: "error",
          verticalAnchor: "top"
        })
      }
      setIsDownloading(false)
    }
  }

  const renderMobile = () => {
    return (
      <Paper square={true} style={{ marginBottom: "10px" }}>
        <Grid
          container={true}
          justifyContent="space-between"
          alignItems="stretch"
          wrap="nowrap"
          style={{ width: "100%", padding: "8px 12px" }}>
          <Grid
            item={true}
            style={{ flexGrow: 1, cursor: "pointer" }}
            onClick={() => handleDownload(document)}>
            <Typography
              component="div"
              style={{
                wordBreak: "keep-all",
                display: "flex",
                alignItems: "start"
              }}>
              {isDownloading ? (
                <CircularProgress size={27.5} />
              ) : (
                <FileIcon
                  extension={getFileExtension(
                    document.dokumentTittel,
                    document.dokumentFormat
                  )}
                />
              )}
              <Link underline="always">{document.dokumentTittel}</Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <>
      <DesktopContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isDownloading ? (
            <CircularProgress size={27.5} />
          ) : (
            <FileIcon
              extension={getFileExtension(
                document.dokumentTittel,
                document.dokumentFormat
              )}
            />
          )}
          <TabbableTableLink
            underline={true}
            text={document.dokumentTittel}
            action={() => handleDownload(document)}
            externalUrl={true}
          />
        </div>
      </DesktopContainer>
      <MobileContainer>{renderMobile()}</MobileContainer>
    </>
  )
}

export default JournalDocumentListItem
