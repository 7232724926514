import { getSelectedCustomer, searchIsActive } from "../../store"

import { ApplicationState } from "../../store"
import Topbar from "./Topbar"
import { connect } from "react-redux"

const mapStateToProps = (state: ApplicationState) => ({
  selectedCustomer: getSelectedCustomer(state),
  displayTopbar: !searchIsActive(state)
})

export default connect(mapStateToProps)(Topbar)
