import * as React from "react"
import { useQuery } from "react-query"
import { format } from "date-fns"
import { graphQLRequest } from "../../services/api"
import { ArealplanReport } from "./types"
import { PlanforholdQueryVariables } from "../../features/reports/Reports/Planforhold/Planforhold"
import { arealplanerReportCsvQuery } from "./arealplanerQueries"

export function useArealplanQLCsv(variables: PlanforholdQueryVariables) {
  let headers = [
    "PlanId",
    "Plannavn",
    "Plantype",
    "PlantypeId",
    "Planstatus",
    "PlanstatusId",
    "IKraft",
    "Lovreferanse",
    "LovreferanseId"
  ]

  const reactQuery = useQuery<any>(
    ["arealplanReportCsv"],
    () => graphQLRequest(arealplanerReportCsvQuery, variables),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
      keepPreviousData: false
    }
  )

  const csvArray = React.useMemo(() => {
    if (reactQuery.data) {
      const arealplaner: ArealplanReport[] = reactQuery.data.arealplaner

      const newLines = arealplaner.reduce((acc: any, d) => {
        acc.push(
          [
            d.planId,
            d.planNavn,
            d.planType,
            d.planTypeId,
            d.planStatus,
            d.planStatusId,
            d.iKraft,
            d.lovreferanse,
            d.lovreferanseId
          ].join(";")
        )
        return acc
      }, [])

      return newLines
    }
  }, [reactQuery.data])

  return { ...reactQuery, csvData: csvArray, headers: headers }
}
