import * as React from "react"
import { Select, MenuItem, SelectProps, Input, styled } from "@mui/material"

export type SelectedOption =
  | {
      label: string | undefined
      value: string | number | undefined
    }
  | undefined

type NkaDropdownProps = {
  options: SelectedOption[]
  displayId?: boolean
  handleChange: (option: SelectedOption) => any
  value: string
  nullOption?: boolean
} & SelectProps

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minHeight: "36px!important",
  width: "99%",
  margin: "auto",
  "&.MuiListItem-root.Mui-selected": {
    backgroundColor: "#d5d5d5"
  },
  "&:hover": {
    backgroundColor: "#dfe3e5"
  },
  "&:selected": {
    backgroundColor: "d5d5d5"
  },
  "&:focus": {
    backgroundColor: "d5d5d5",
    color: theme.palette.secondary.dark,
    "& .MuiListItemIcon-root, & .MuiListItemText-selected": {
      color: theme.palette.common.white
    }
  }
}))

const NkaDropdown = ({
  handleChange,
  options,
  displayId,
  nullOption,
  value,
  ...props
}: NkaDropdownProps) => {
  return (
    <Select
      variant="outlined"
      style={props.style}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        }
      }}
      placeholder={props.placeholder || "Velg.."}
      value={value || ""}
      onChange={e => {
        const selected = options.find(o => o?.value === e.target.value)
        handleChange(selected)
      }}
      {...props}>
      {nullOption && <StyledMenuItem value="" />}
      {options &&
        options.map(option => {
          return (
            <StyledMenuItem key={option?.value} value={option?.value}>
              {displayId
                ? `${option?.value} - ${option?.label}`
                : option?.label}
            </StyledMenuItem>
          )
        })}
    </Select>
  )
}

export default NkaDropdown
