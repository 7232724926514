import { useMutation, useQueryClient } from "react-query"
import { createBehandling } from "../../services/api"
import { Behandling } from "./types"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"

const addBehandling = async (behandling: Behandling): Promise<Behandling> => {
  const { data } = await createBehandling(behandling)
  return data
}

export default function useCreateBehandling() {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()
  const { notify } = useErrorHandler()

  return useMutation(addBehandling, {
    onError: notify,
    onSuccess: behandling => {
      queryClient.invalidateQueries([
        "behandlinger",
        { kundeId, arealplanId: behandling.arealplanId }
      ])
    }
  })
}
