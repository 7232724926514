import { Coords, MapboxLayer, Popup } from "@norkart/nkm-mapbox-map"
import React, { useEffect, useState } from "react"
import { WmsFeature, gfiAttId } from "../../services/apiTjenestekatalogen"
import {
  addFitToBounds,
  fetchGfiSuccess,
  resetGFIState
} from "../../store/map/actions"
import { createInfoPopup, popupStyle } from "./helpers/createPopup"
import { getMapState, getSearchState } from "../../store"
import {
  createFitBoundsForFeature,
  isWithinLayer
} from "./helpers/layerHelpers"
import { useDispatch, useSelector } from "react-redux"
import { PlanLayerState } from "./types"
import BaseMap from "./BaseMap"
import { GFIAttrState } from "../../store/map/types"

import PlanensKartlag from "./Components/Menus/Kartlag/PlanensKartlag"
import ZoomButton from "./Components/Menus/ZoomButton"
import { Box, styled, Typography } from "@mui/material"
import useMeta from "../../hooks/meta/useMeta"

import { usePropertySearch } from "../search/Search/filters/PropertySearchFilter/usePropertySearch"
import { fetchGFI } from "./helpers/gfiHelpers"
import useArealplanById from "../../hooks/arealplaner/useArealplanById"
import AndreKartlag from "./Components/Menus/Kartlag/AndreKartlag"
import useSelectedArealplanId from "../../hooks/arealplaner/useSelectedArealplanId"
import DesktopContainer from "../../containers/DesktopContainer"

const navHeight = "64px"

const MapContainer = styled("div")({
  height: "calc(100% - " + navHeight + ")",
  width: "100%",
  position: "relative",
  margin: 0,
  padding: 0,
  top: 0,
  left: 0,
  "& .nkm-mapbox-map, .mapboxgl-map": {
    width: "100%",
    height: "100%"
  },
  ...popupStyle
})

const MapMenu = styled("div", {
  shouldForwardProp: prop => prop != "alignment"
})<{ alignment: "left" | "right" }>(({ alignment }) => ({
  maxHeight: "95vh",
  minWidth: "252px",
  position: "absolute",
  zIndex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column" as any,
  ...(alignment === "left" && {
    left: "10px",
    top: "15px",
    "& > div": {
      marginTop: "8px"
    }
  }),
  ...(alignment === "right" && {
    height: "auto",
    right: "15px",
    top: "15px",
    "& > .section": {
      marginTop: "10px"
    }
  })
}))

type props = {
  displaySurroundingPlanWms?: boolean
}

const SearchMap = ({ displaySurroundingPlanWms = false }: props) => {
  const meta = useMeta()
  const dispatch = useDispatch()
  const mapState = useSelector(getMapState)
  const searchState = useSelector(getSearchState)
  const propertySearch = usePropertySearch()
  const selectedArealplanId = useSelectedArealplanId()
  const plan = useArealplanById(selectedArealplanId)

  const [mapPopup, setMapPopup] = useState<Popup | undefined>(undefined)
  const [GFIMarker, setGFIMarker] = useState<Coords | undefined>(undefined)
  const [extraLayers, setExtraLayers] = useState<MapboxLayer[]>([])
  const [visibleGfiFeature, setVisibleGfiFeature] = useState<
    WmsFeature | undefined
  >(getVisibleGfiFeature(mapState.drawnAttInMap, mapState.gfiState))
  const [displaySearchedPropertyGeom, setDisplaySearchedPropertyGeom] =
    useState(true)

  useEffect(() => {
    setGFIMarker(undefined)
    dispatch(resetGFIState())
  }, [displaySurroundingPlanWms])

  useEffect(() => {
    if (!selectedArealplanId) {
      // reset gfi if going back to search
      setGFIMarker(undefined)
      dispatch(resetGFIState())
    }
  }, [selectedArealplanId])

  useEffect(() => {
    //clear selected plan outline if the search changes
    setExtraLayers([])
  }, [searchState])

  useEffect(() => {
    const newVisibleGfiFeature = getVisibleGfiFeature(
      mapState.drawnAttInMap,
      mapState.gfiState
    )
    if (newVisibleGfiFeature !== visibleGfiFeature) {
      setVisibleGfiFeature(newVisibleGfiFeature)
    }
  }, [mapState.gfiState, mapState.drawnAttInMap])

  function getVisibleGfiFeature(
    drawnAttInMap: gfiAttId | undefined,
    gfiState: GFIAttrState | undefined
  ): WmsFeature | undefined {
    let allAttsLists: WmsFeature[] = []
    gfiState &&
      gfiState.planAttributes &&
      gfiState.planAttributes.forEach(plan => {
        plan.wmsLayers.forEach(layer => {
          allAttsLists = allAttsLists.concat(layer.attrList)
        })
      })
    const gfiFeature = allAttsLists.filter(att => att.id === drawnAttInMap)
    return gfiFeature.length ? gfiFeature[0] : undefined
  }

  const onMapClick = (event: { lngLat: Coords; originalEvent: any }) => {
    if (event.originalEvent.defaultPrevented) return

    if (selectedArealplanId) {
      const clickWithinPlanarea = !isWithinLayer(
        event.lngLat,
        mapState.differenceLayer?.[0]
      )

      if (clickWithinPlanarea || displaySurroundingPlanWms) {
        setGFIMarker(event.lngLat)
        fetchGFI(
          mapState.wmsLayerState,
          event.lngLat,
          plan?.data?.planId,
          data => dispatch(fetchGfiSuccess(data)),
          meta.komnr
        )
      }

      if (!clickWithinPlanarea && !displaySurroundingPlanWms) {
        let popupText =
          "Trykk innenfor den heltrukne røde grensen for informasjon om planen."

        const popup: Popup = createInfoPopup(event.lngLat, popupText)
        setMapPopup(popup)
        setGFIMarker(undefined)
        dispatch(resetGFIState())
      }
    } else {
      propertySearch.activatePropertyFilterFromPosition(
        event.lngLat,
        error => setMapPopup(createInfoPopup(event.lngLat, error)),
        true
      )
    }
  }

  return (
    <MapContainer>
      <MapMenu alignment="left" style={{ justifyContent: "flex-start" }}>
        {searchState.searchedPropertyGeom && (
          <ZoomButton
            style={{ marginTop: 10, height: 75, width: 75, fontSize: 16 }}
            type={"property"}
          />
        )}
      </MapMenu>
      <DesktopContainer>
        <Box
          sx={{
            position: "absolute",
            left: "45%",
            top: "15px",
            transform: "translate(-50%, 0%)",
            backgroundColor: "#ffffff",
            padding: 5,
            border: "1px solid black",
            zIndex: 1000
          }}>
          {selectedArealplanId
            ? "Trykk i kartet for informasjon om hva som finnes på punktet"
            : "Trykk i kartet for å filtrere på valgt adresse"}
        </Box>
      </DesktopContainer>

      <MapMenu alignment="right">
        <PlanensKartlag
          label={
            selectedArealplanId ? "Kartlag for valgt plan" : "Kommunens kartlag"
          }
        />
        {!!selectedArealplanId && (
          <AndreKartlag
            setDisplaySearchedPropertyGeom={setDisplaySearchedPropertyGeom}
            displaySearchedPropertyGeom={displaySearchedPropertyGeom}
            showAllLayers={displaySurroundingPlanWms}
          />
        )}
      </MapMenu>
      <BaseMap
        displaySearchedPropertyGeom={displaySearchedPropertyGeom}
        displaySurroundingPlanWms={displaySurroundingPlanWms}
        onMapClick={onMapClick}
        mapboxMarker={GFIMarker}
        visibleGfiFeature={visibleGfiFeature}
        extraLayers={extraLayers}
        mapPopup={mapPopup}
        plan={plan?.data}
      />
    </MapContainer>
  )
}

export default SearchMap
