import * as React from "react"
import ExpansionPanel from "../../../components/ExpansionPanel"
import { Behandling } from "../../../hooks/behandlinger/types"
import { Typography, styled, Box } from "@mui/material"
import { formatDate } from "../../..//utils/formatDate"
import DocumentList from "../../../components/DocumentList"
import SakItem from "../../sak/SakList/SakItem"
import JournalpostItem from "../../sak/Journalpost/JournalpostItem"

const PropertyWrapper = styled("div")({
  padding: "3px 0"
})

const PropertyHeader = styled(Typography)({ fontWeight: 500 })

export type PlanBehandlingListItemProps = {
  behandling: Behandling
  expandedOptions?: {
    onChange?: (isExpanded: boolean) => void
    expandedId?: number
    allowMultipleExpanded?: boolean
    rowId?: number
  }
}

const PlanBehandlingListItem = ({
  behandling,
  expandedOptions
}: PlanBehandlingListItemProps) => {
  const date =
    formatDate(behandling.fraDato) +
    (behandling.tilDato ? " - " + formatDate(behandling.tilDato) : "")

  const documentCountToString = () => {
    var documentCount = behandling.dokumenter.length
    if (documentCount > 1) {
      return documentCount + " dokumenter"
    } else if (documentCount === 1) {
      return documentCount + " dokument"
    } else {
      return ""
    }
  }
  return (
    <ExpansionPanel
      extraInfo={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
          <span>{date}</span>
          <span>{documentCountToString()}</span>
        </Box>
      }
      title={behandling.behandlingstype}
      bgColor="white"
      showBorderWhenOpen={true}
      expandedOptions={expandedOptions}>
      <>
        <div style={{ paddingLeft: "15px" }}>
          {behandling.beskrivelse && (
            <PropertyWrapper>
              <PropertyHeader>Beskrivelse:</PropertyHeader>
              <Typography
                sx={{
                  overflowWrap: "break-word"
                }}>
                {behandling.beskrivelse}
              </Typography>
            </PropertyWrapper>
          )}
          {behandling.sak && (
            <PropertyWrapper>
              <PropertyHeader>Saksnr:</PropertyHeader>
              <Typography>
                <SakItem sak={behandling.sak} />
              </Typography>
            </PropertyWrapper>
          )}

          {behandling.endringstype && (
            <PropertyWrapper>
              <PropertyHeader>Endringstype:</PropertyHeader>
              <Typography>{behandling.endringstype}</Typography>
            </PropertyWrapper>
          )}
          {behandling.myndighet && (
            <PropertyWrapper>
              <PropertyHeader>Myndighet:</PropertyHeader>
              <Typography>{behandling.myndighet}</Typography>
            </PropertyWrapper>
          )}
          {behandling.journalpostnummer && (
            <PropertyWrapper>
              <PropertyHeader>Journalpostnummer:</PropertyHeader>
              <Typography>
                <JournalpostItem
                  journalpostData={
                    {
                      journalpostnummer: behandling.journalpostnummer,
                      journalpostUrl: behandling.journalpostUrl,
                      journalpostUrlIntern: behandling.journalpostUrlIntern
                    } as Partial<Behandling>
                  }
                />
              </Typography>
            </PropertyWrapper>
          )}
          {behandling.dokumenter.length > 0 && (
            <PropertyWrapper>
              <PropertyHeader style={{ borderBottom: "0px" }}>
                Dokumenter:
              </PropertyHeader>
            </PropertyWrapper>
          )}
        </div>
        <div style={{ marginLeft: "20px", marginTop: "-5px" }}>
          <DocumentList documents={behandling.dokumenter} />
        </div>
      </>
    </ExpansionPanel>
  )
}
export default PlanBehandlingListItem
