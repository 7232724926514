import { Feature, MultiPolygon, Polygon } from "geojson"
import { action } from "typesafe-actions"
import {
  SearchActionTypes,
  UrlSearchParams,
  SortFilter,
  SearchTabType
} from "./types"

export const searchIsActive = (isActive: boolean) =>
  action(SearchActionTypes.SEARCH_IS_ACTIVE, isActive)
export const addSortFilter = (selectedSort: SortFilter | null) =>
  action(SearchActionTypes.ADD_SORT_FILTER, selectedSort)

export const addActiveSearchTab = (
  tabId: number,
  activeSearch: SearchTabType
) => action(SearchActionTypes.ADD_ACTIVE_SEARCH_TAB, { tabId, activeSearch })
export const setSearchedPropertyGeom = (
  geom:
    | undefined
    | Feature<MultiPolygon>
    | Feature<Polygon>
) => action(SearchActionTypes.SET_SEARCHED_PROPERTY_GEOM, geom)
export const setUrlSearchParams = (urlParams: UrlSearchParams) =>
  action(SearchActionTypes.SET_URL_SEARCH_PARAMS, urlParams)

export const setPropertyIsMissing = (isMissing?: boolean) =>
  action(SearchActionTypes.SET_PROPERTY_IS_MISSING, isMissing)

export const setPropertySearchText = (text?: string) =>
  action(SearchActionTypes.SET_PROPERTY_SEARCH_TEXT, text)

export const setPropertySearchIsAdressSearch = (isAdressSearch?: boolean) =>
  action(SearchActionTypes.SET_PROPERTY_SEARCH_IS_ADRESS_SEARCH, isAdressSearch)
