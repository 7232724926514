import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import { addWmsLayer, removeWmsLayer } from "../../../../../store/map/actions"
import { handleLayerClick, mapLayerMenuStyle } from "./helper"
import { useDispatch, useSelector } from "react-redux"

import { AvailableWmsLayer } from "../../../types"
import {MapLayersIcon} from '@norkart/toi-icons';
import MenuButton from "../MenuButton"
import { getMapState } from "../../../../../store"
import KartlagMenuItem from "./KartlagMenuItem"

type Props = {
  label?: string
  defaultClosed?: boolean
}

const PlanensKartlag = ({ label, defaultClosed }: Props) => {
  const mapState = useSelector(getMapState)
  const dispatch = useDispatch()
  const [mapLayersMenuVisible, setMapLayersMenuVisible] = useState<boolean>(
    defaultClosed ? false : true
  )
  const [drawnWmsLayers, setDrawnWmsLayers] = useState<AvailableWmsLayer[]>([])

  const availableWmsLayers = mapState.wmsLayerState.availableWmsLayers

  useEffect(() => {
    if (mapState.wmsLayerState.availableWmsLayers) {
      setDrawnWmsLayers(
        mapState.wmsLayerState.availableWmsLayers.filter(lay => lay.isVisible)
      )
    }
  }, [mapState.wmsLayerState.availableWmsLayers])

  return (
    <div className="Kartlag section">
      <MenuButton
        name={`${label ? label : "Planens kartlag"} ${
          mapState.wmsLayerState.availableWmsLayers?.length
            ? "(" + mapState.wmsLayerState.availableWmsLayers.length + ")"
            : ""
        } `}
        Icon={<MapLayersIcon />}
        onClick={() => setMapLayersMenuVisible(!mapLayersMenuVisible)}
        isOpen={mapLayersMenuVisible}
      />
      {mapLayersMenuVisible && (
        <Box sx={theme => mapLayerMenuStyle(theme)}>
          {!!mapState.wmsLayerState.availableWmsLayers?.length &&
            mapState.wmsLayerState.availableWmsLayers.map((layer, index) => (
              <KartlagMenuItem
                visible={!!drawnWmsLayers?.includes(layer)}
                text={layer.displayName}
                handleToggle={() =>
                  handleLayerClick(
                    layer,
                    availableWmsLayers,
                    (layer: AvailableWmsLayer) => dispatch(addWmsLayer(layer)),
                    (layer: AvailableWmsLayer) =>
                      dispatch(removeWmsLayer(layer))
                  )
                }
                key={index}
              />
            ))}
        </Box>
      )}
    </div>
  )
}
export default PlanensKartlag
