import { useQuery } from "react-query"
import { getMunicipalityGeometry } from "../../services/apiKommuneinfo"
import useMeta from "../meta/useMeta"
import { KommuneInfo } from "./types"

const getKommuneInfo = async (komnr: string) => {
  const res = await getMunicipalityGeometry(komnr)
  return res ? res.KommuneObject : res
}

export default function useKommuneInfo() {
  const meta = useMeta()
  const komnr = meta.getInnstilling("komnr")

  const query = useQuery(
    ["kommuneinfo", { komnr }],
    () => getKommuneInfo(komnr!),
    {
      enabled: !!komnr
    }
  )
  return { ...query, data: query?.data || ({} as KommuneInfo) }
}
