import { Button, CircularProgress } from "@mui/material"
import { ButtonProps } from "@mui/material/Button"
import * as React from "react"

export interface SpinnerButtonProps extends ButtonProps {
  label: string
  disabled?: boolean
  loading?: boolean
}

const SpinnerButton = ({ label, loading, ...props }: SpinnerButtonProps) => {
  return (
    <Button {...props} variant="contained" color="secondary">
      {label}
      {loading && (
        <CircularProgress
          size={24}
          sx={theme => ({
            color: theme.palette.secondary.main,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px"
          })}
        />
      )}
    </Button>
  )
}

export default SpinnerButton
