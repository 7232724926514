import * as React from "react"
import PlanView from "../../features/plan/PlanView"
import { useDispatch, useSelector } from "react-redux"
import Faq from "../../features/faq/faq"
import Home from "../../features/home/home"
import AlternativeHome from "../../features/NewFrontpage/AlternativeHome"
import RelevantLinks from "../../features/relevantLinks"
import Reports from "../../features/reports/Reports/Reports"
import Settings from "../../features/settings/Settings"
import GI from "../../gi"
import { NKI } from "../../nki"
import { getUseAltFrontpage } from "../../store"
import {
  Routes,
  Route,
  useParams,
  Navigate,
  useSearchParams
} from "react-router-dom"
import KundestatusTopbar from "../../components/KundestatusTopbar/KundestatusTopbar"
import { usePropertySearch } from "../../features/search/Search/filters/PropertySearchFilter/usePropertySearch"
import qs from "qs"
import { setUrlSearchParams } from "../../store/search/actions"
import { UrlSearchParams } from "../../store/search/types"

export interface CustomerRoutesProps {
  selectCustomer: (id: string) => void
  selectedCustomer: string
}

function CustomerRoutes({
  selectCustomer,
  selectedCustomer
}: CustomerRoutesProps) {
  const useAltFrontpage = useSelector(getUseAltFrontpage)
  const { customerId } = useParams()
  const propertySearch = usePropertySearch()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    if (selectedCustomer != customerId && customerId) {
      selectCustomer(customerId)
    }
  }, [])

  React.useEffect(() => {
    const urlSearchParams = qs.parse(searchParams.toString(), {
      ignoreQueryPrefix: true
    }) as Partial<UrlSearchParams>
    dispatch(setUrlSearchParams(urlSearchParams))
    propertySearch.setMatrikkelId()
  }, [searchParams])

  const renderHome = () => {
    return useAltFrontpage ? <AlternativeHome /> : <Home />
  }

  return (
    <>
      <KundestatusTopbar />
      <Routes>
        <Route path="faq" element={<Faq />} />
        <Route path="relevanteLenker" element={<RelevantLinks />} />
        <Route path="rapporter/*" element={<Reports />} />
        <Route path="innstillinger/*" element={<Settings />} />
        <Route path="gi" element={<GI />} />
        <Route path="nki" element={<NKI />} />
        <Route
          path="arealplaner/:id/fullskjerm"
          element={<AlternativeHome />}
        />
        <Route path="arealplaner/:id/*" element={<PlanView />} />
        <Route path="arealplaner/search" element={renderHome()} />
        <Route path="arealplaner/" element={<Navigate to="search" />} />
        <Route index={true} element={<Navigate to="arealplaner/search" />} />
      </Routes>
    </>
  )
}

export default CustomerRoutes
