import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { connect, ConnectedProps, useSelector } from "react-redux"
import { Arealplan, Sak } from "../../../hooks/arealplaner/types"
import { Behandling } from "../../../hooks/behandlinger/types"
import { Dispensasjon } from "../../../hooks/dispensasjoner/types"
import useJournaler from "../../../hooks/journaler/useJournaler"
import {
  ApplicationState,
  getActiveSakId,
  getJournalDocumentSelection
} from "../../../store"
import { hideDialog, showDialog } from "../../../store/dialog/actions"
import { addFile, reset } from "../../../store/fileImport/actions"
import SakSelector from "../../sak/SakSelector/SakSelector"
import ImportDocumentFromArchive from "."
import { FileData } from "../../../store/fileImport/types"
import { Journal, JournalDocument } from "../../../hooks/journaler/types"
import { fetchJournalDocument } from "../../../services/api"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { openSnackbarMessage } from "../../../components/SnackbarMessage"
import { setActiveSakId } from "../../../store/session/actions"
import getFilenameFromResponse from "../../../utils/getFilenameFromResponse"
import useDocumentHelpers from "../useDocumentHelpers"

export type Props = ConnectedProps<typeof connector> & {
  open: boolean
  behandling?: Behandling
  dispensasjon?: Dispensasjon
  plan: Arealplan
  showRowDetails: (id: string) => void
}

function ImportDocumentFromArchiveDialog({
  open,
  plan,
  behandling,
  dispensasjon,
  ...props
}: Props) {
  const selectedSakId = useSelector(getActiveSakId)
  const journaler = useJournaler(selectedSakId)
  const [isLoading, setIsLoading] = useState(false)
  const documentHelpers = useDocumentHelpers()

  useEffect(() => {
    // Set sak to undefined if multiple to show SakSelector
    // Plan and Behandling without sak do not show option to import from archive
    let sak: Partial<Sak> | undefined
    if (behandling) sak = behandling.sak
    else if (dispensasjon) sak = dispensasjon.sak
    else if (plan.saker.length === 1) sak = plan.saker[0]
    else sak = undefined
    if (sak) props.dispatch(setActiveSakId(sak.id))
  }, [plan, behandling, dispensasjon])

  function handleClose() {
    setIsLoading(false)
    hideDialog(props.dispatch).then(() => {
      props.resetFileImport()
    })
  }

  const renderSelection = () => (
    <>
      {!behandling && !dispensasjon && (
        <div style={{ width: "250px" }}>
          <SakSelector />
        </div>
      )}
      <ImportDocumentFromArchive journaler={journaler.data} />
    </>
  )

  const renderLoading = () => (
    <LoadingSpinner text="Henter valgte dokumenter fra arkiv" />
  )

  const downloadAndMapFile = async (document: JournalDocument) => {
    const response = await fetchJournalDocument(
      document.sakId,
      document.systemId
    )
    const filename = getFilenameFromResponse(response)

    let newDoc = {
      id: document.systemId,
      selected: true,
      dokumentNavn: filename,
      dokumentTittel: document.dokumentTittel,
      dokumentTypeId: 0,
      dokumentDato: new Date(document.journal.journalDato),
      beskrivelse: document.journal.id,
      horingsdokument: false,
      bestemmelser: false,
      plandokument: false,
      overwrite: false,
      tilgangId: documentHelpers.getDefaultTilgangId(behandling),
      url: URL.createObjectURL(response.data),
      filetype: response.data.type
    } as FileData
    return newDoc
  }

  const downloadFiles = async () => {
    const fileData = await Promise.all(
      props.selectedDocuments.map(doc => downloadAndMapFile(doc))
    ).catch(() => {
      openSnackbarMessage({
        message: "Noe gikk galt under nedlastingen",
        variant: "error"
      })
      setIsLoading(false)
      return Promise.reject()
    })
    if (fileData)
      fileData.map(file => {
        props.addFile(file)
      })
  }

  const handleNextClicked = async () => {
    setIsLoading(true)
    try {
      await downloadFiles()
      hideDialog(props.dispatch).then(() => {
        props.dispatch(
          showDialog({
            dialogType: "IMPORT_DOCUMENTS",
            dialogProps: {
              plan,
              behandling,
              dispensasjon,
              showRowDetails: props.showRowDetails
            }
          })
        )
      })
    } catch {
      props.resetFileImport()
    }
  }

  const renderSelectedDocumentsCount = () => (
    <Typography style={{ fontWeight: 500, width: "100%", marginLeft: "20px" }}>
      {props.selectedDocuments.length > 0
        ? `${props.selectedDocuments.length} dokument${
            props.selectedDocuments.length > 1 ? "er" : ""
          } valgt`
        : "Ingen dokumenter valgt"}
    </Typography>
  )
  const renderActions = () => (
    <>
      <Button variant="contained" color="grey" onClick={handleClose}>
        Avbryt
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={isLoading || !props.selectedDocuments.length}
        onClick={handleNextClicked}>
        Neste
      </Button>
    </>
  )

  return (
    <Dialog
      open={open}
      aria-labelledby="import-document-title"
      maxWidth="xl"
      fullWidth={true}
      disableScrollLock={true}
      scroll="paper"
      PaperProps={{ style: { overflowY: "visible" } }}>
      <DialogTitle id="import-document-title">
        Velg dokumenter fra sak
      </DialogTitle>
      <DialogContent>
        {isLoading ? renderLoading() : renderSelection()}
      </DialogContent>
      <DialogActions>
        {journaler.isFetched && renderSelectedDocumentsCount()}
        {renderActions()}
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedDocuments: getJournalDocumentSelection(state)
})
const mapDispatchToProps = dispatch => ({
  resetFileImport: () => dispatch(reset()),
  addFile: (file: FileData) => dispatch(addFile(file)),
  dispatch
})
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ImportDocumentFromArchiveDialog)
