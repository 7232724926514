import React from "react"
import { useQuery } from "react-query"
import { fetchArealplan } from "../../services/api"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { Arealplan, ArealplanId } from "./types"
import useSelectedArealplanId from "./useSelectedArealplanId"

export default function useArealplan(id?: ArealplanId) {
  const kundeId = useSelectedKundeId()
  const arealplanId = useSelectedArealplanId()
  const { notify } = useErrorHandler()

  const query = useQuery<Arealplan>(
    ["arealplan", { kundeId, arealplanId: id ? id : arealplanId }],
    () => fetchArealplan({ id: id ? id : arealplanId }),
    {
      enabled: !!arealplanId && !!kundeId,
      onError: notify
    }
  )
  return { ...query, data: query?.data || ({} as Arealplan) }
}
