import { useQuery } from "react-query"
import { fetchTokens } from "../../services/api"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"
import { TokenType, Token } from "./types"

const getTokens = async (tokenType: TokenType) => {
  const { data } = await fetchTokens(tokenType)
  return data
}

export default function useTokens(tokenType: TokenType) {
  const { notify } = useErrorHandler()
  const kundeId = useSelectedKundeId()

  const query = useQuery<Token[]>(
    ["tokens", { kundeId, tokenType }],
    () => getTokens(tokenType),
    {
      onError: notify,
      enabled: !!kundeId,
      staleTime: undefined,
      cacheTime: undefined
    }
  )

  return {
    ...query,
    data: query.data || []
  }
}
