import * as React from "react"
import { Field, FieldProps } from "react-final-form"
import { Typography } from "@mui/material"
import FormError from "../FormError"
import classNames from "classnames"
import Error from "../FormError/Error"
import "./FormInputField.css"

export interface FormInputFieldProps
  extends React.AllHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  optional?: boolean
  disabled?: boolean
  fieldProps?: Partial<FieldProps2>
  nkaDesign?: boolean | undefined
  submitted?: boolean | undefined
}
type FieldProps2 = FieldProps<any, any, HTMLElement>

const FormInputField: React.FC<FormInputFieldProps> = ({
  name,
  label: title,
  type = "text",
  optional = false,
  disabled = false,
  fieldProps = {},
  nkaDesign = false,
  submitted = false,
  ...rest
}: FormInputFieldProps) => (
  <Field
    name={name}
    subscription={{ value: true, touched: true, error: true, initial: true }}
    {...fieldProps}
    render={({ input, meta }) => (
      <label>
        {title && (
          <Typography
            className={classNames({
              importantFormLabel: !(optional || disabled)
            })}>
            {title}
          </Typography>
        )}
        {!nkaDesign && (
          <div id="FormInput" style={{ height: "48px" }}>
            <input
              {...input}
              {...rest}
              disabled={disabled}
              className={classNames({
                formInput: true,
                formInputError: meta.error && meta.touched
              })}
            />
            <FormError name={name} />
          </div>
        )}
        {nkaDesign && (
          <div>
                    <div className="text-input" >
                        <input
                            value={input.value}
                            onChange={e => input.onChange(e.target.value)}
                            placeholder={rest.placeholder}
                        />
                    </div>
                    <div className={'search-border'} />
            {meta.error && submitted && (
              <span>
                <Error error={meta.error} />
              </span>
            )}
          </div>
        )}
      </label>
    )}
  />
)
export default FormInputField
