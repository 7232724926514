import { useMutation, useQueryClient } from "react-query"
import { deleteBehandling } from "../../services/api"
import useSelectedArealplanId from "../arealplaner/useSelectedArealplanId"
import useErrorHandler from "../errors/useErrorHandler"
import useSelectedKundeId from "../kunder/useSelectedKundeId"

export default function useDeleteBehandling() {
  const queryClient = useQueryClient()
  const kundeId = useSelectedKundeId()

  const arealplanId = useSelectedArealplanId()
  const { notify } = useErrorHandler()

  return useMutation((id: number) => deleteBehandling(id), {
    onError: notify,
    onSuccess: () => {
      queryClient.invalidateQueries(["behandlinger", { kundeId, arealplanId }])
    }
  })
}
