
import * as React from 'react'
import { useQuery } from "react-query"
import { SaksbehandlingstidQueryVariables } from '../../features/reports/Reports/Saksbehandlingstid/Saksbehandlingstid'
import { GraphQLUnpagedResponse } from "../../graphql/types"
import { graphQLRequest } from "../../services/api"
import { SaksbehandlingstidReport } from './types'


export default function useSaksbehandlingstidGraphQL(graphqlQuery: string, variables:SaksbehandlingstidQueryVariables) {
  
  const query = useQuery<GraphQLUnpagedResponse>(
    ["saksbehandlingstidReportGraphQL", variables],
    () => graphQLRequest(graphqlQuery,variables),
    {
        cacheTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false
    },)
    
    return { ...query,saksbehandlingstid: query.data?.saksbehandlingstidReport as SaksbehandlingstidReport[] || [] as SaksbehandlingstidReport[]}
}
  