import { Box, styled, Typography } from "@mui/material"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Toolbar from "@mui/material/Toolbar"
import BackIcon from "@mui/icons-material/ArrowBack"
import React from "react"
import { useDispatch } from "react-redux"

import useSelectedKundeId from "../../../hooks/kunder/useSelectedKundeId"
import { resetMapState } from "../../../store/map/actions"
import useCan from "../../../utils/auth/useCan"
import FrontendSettings from "../FrontendSettings"
import Tokens from "../Tokens"
import {
  Link,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useResolvedPath
} from "react-router-dom"
import qs from "query-string"
import useSearch from "../../../hooks/search/useSearch"
import { ArchiveIntegrationSettings } from "../ArchiveIntegrationSettings"

const drawerWidth = 240

const MainContent = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(10),
  scrollBehavior: "auto"
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary
}))

function Settings() {
  const can = useCan()
  const kundeId = useSelectedKundeId()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const url = useResolvedPath("").pathname
  const search = useSearch()

  const handleBackToSearch = () => {
    dispatch(resetMapState())
    navigate({
      pathname: `/${kundeId}/arealplaner/search`,
      search: qs.stringify({ ...search.searchState.urlSearchParams })
    })
  }

  const links = [
    {
      text: "Tilganger",
      to: `${url}/tilganger`,
      path: `tilganger`,
      component: Tokens
    },
    {
      text: "Innstillinger",
      to: `${url}/innstillinger`,
      path: `innstillinger`,
      component: FrontendSettings
    },
    {
      text: "Arkivintegrasjon",
      to: `${url}/arkivintegrasjon`,
      path: `arkivintegrasjon`,
      component: ArchiveIntegrationSettings
    }
  ] as {
    text: string
    to: string
    path: string
    component: React.ComponentType<any>
  }[]

  const activeRoute = (linkPath: string) => {
    return location.pathname === linkPath
  }

  if (can.edit)
    return (
      <Box
        sx={{
          display: "flex"
        }}>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiPaper-root": {
              width: drawerWidth
            }
          }}>
          <Toolbar />
          <Box
            sx={{
              overflow: "auto"
            }}>
            <List>
              <ListItem button={true} onClick={handleBackToSearch}>
                <ListItemIcon>
                  <BackIcon />
                </ListItemIcon>
                <ListItemText primary="Plansøk" />
              </ListItem>
            </List>
            <Divider />
            <List>
              {links.map(link => (
                <StyledLink key={link.text} to={link.to}>
                  <ListItem
                    button={true}
                    selected={activeRoute(link.to)}
                    sx={{ "&.Mui-selected": { fontWeight: 500 } }}>
                    {link.text}
                  </ListItem>
                </StyledLink>
              ))}
            </List>
          </Box>
        </Drawer>
        <MainContent>
          <Routes>
            {links.map(link => (
              <Route
                key={link.text}
                path={link.path}
                element={<link.component />}
              />
            ))}
          </Routes>
        </MainContent>
      </Box>
    )

  return <Typography>Du har ikke rettigheter til å se denne siden.</Typography>
}

export default Settings
