import { action } from "typesafe-actions"
import { DialogState, DialogActionTypes as actionTypes } from "./types"

export const showDialog = (data: DialogState) =>
  action(actionTypes.SHOW_DIALOG, data)

export const hideDialog = dispatch =>
  new Promise((resolve, reject) => {
    dispatch(action(actionTypes.HIDE_DIALOG))
    setTimeout(() => resolve(""), 150)
  })
