import * as React from "react"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography
} from "@mui/material"
import { ToolbarProps } from "./ReactTable"
import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
import DownloadIcon from "@mui/icons-material/Download"

const StyledTextfiled = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root::before": {
    borderBottom: "1px solid transparent",
    "&:hover": {
      borderBottom: "2px solid transparent !important"
    }
  },
  ".MuiInputBase-root::after": {
    borderBottom: `2px solid ${theme.palette.secondary.main}`
  },
  ".MuiInput-root:hover:not(.Mui-disabled):before": {
    borderBottom: "2px solid transparent !important"
  }
}))

export function ReactTableToolbar({
  clearSearchFilter,
  onChange,
  onKeyUp,
  value = "",
  disabled,
  exportCsv,
  enableFilter,
  dataInfo
}: ToolbarProps) {
  if (disabled) return null
  return (
    <Box>
      <Grid sx={{ height: "50px", pt: "16px", pr: "10px" }} container={true}>
        {enableFilter && (
          <Grid xs={6} item={true}>
            <Grid container={true}>
              <StyledTextfiled
                variant="standard"
                onChange={onChange}
                onKeyUp={onKeyUp}
                placeholder="Filter..."
                value={value}
                InputProps={{
                  endAdornment: (
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{
                          visibility: value !== "" ? "visible" : "hidden"
                        }}
                        onClick={clearSearchFilter}>
                        <ClearIcon color="primary" fontSize="small" />
                      </IconButton>
                      <SearchIcon fontSize="small" />
                    </div>
                  )
                }}
              />
            </Grid>
          </Grid>
        )}
        {exportCsv && (
          <Grid sx={{}} xs={12} item={true}>
            <Grid container={true} sx={{ alignItems: "center" }}>
              <Grid xs={6} item={true}>
                {
                  /*Valgfri informasjon kan dyttes inn her, feks total count */
                  dataInfo && (
                    <Typography
                      fontSize={"0.85rem"}
                      variant="caption"
                      justifyContent={"flex-end"}>
                      {dataInfo}
                    </Typography>
                  )
                }
              </Grid>
              <Grid display="flex" justifyContent="flex-end" xs={6} item={true}>
                {exportCsv && (
                  <Tooltip
                    color="primary"
                    title={
                      disabled
                        ? exportCsv?.tooltipDisabled
                          ? exportCsv.tooltipDisabled
                          : ""
                        : "Eksporter data til CSV"
                    }>
                    <span>
                      <Button
                        onClick={exportCsv.handleDownloadClick}
                        color="secondary"
                        variant="contained"
                        size="small"
                        style={{
                          flexDirection: "row-reverse"
                        }}>
                        Eksporter
                        {exportCsv.loadingRequest ? (
                          <CircularProgress
                            sx={{ mr: 2, color: "white" }}
                            size={20}
                          />
                        ) : (
                          <DownloadIcon sx={{ mr: 2 }} fontSize="small" />
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
